import {
    INVALIDATE_CATEGORIAS,
    ERROR_CATEGORIAS,
    RECEIVE_CATEGORIAS,
    REQUEST_CATEGORIAS,
    RESET_CATEGORIAS,
    ERROR_CATEGORIA,
    RECEIVE_CATEGORIA,
    REQUEST_CATEGORIA,
    UPDATE_CATEGORIA,
    REQUEST_UPDATE_CATEGORIA,
    SUCCESS_UPDATE_CATEGORIA,
    ERROR_UPDATE_CATEGORIA,
    RESET_UPDATE_CATEGORIA,
    REQUEST_UPDATE_CATEGORIAS,
    SUCCESS_UPDATE_CATEGORIAS,
    ERROR_UPDATE_CATEGORIAS,
    RESET_UPDATE_CATEGORIAS,
    CREATE_CATEGORIA,
    ERROR_CREATE_CATEGORIA,
    REQUEST_CREATE_CATEGORIA,
    RESET_CREATE_CATEGORIA,
    SUCCESS_CREATE_CATEGORIA,
    REQUEST_CREATE_CATEGORIAS,
    SUCCESS_CREATE_CATEGORIAS,
    ERROR_CREATE_CATEGORIAS,
    RESET_CREATE_CATEGORIAS,
    DELETE_CATEGORIA,
    DELETE_CREATE_CATEGORIA,
    DELETE_UPDATE_CATEGORIA,
    REQUEST_DELETE_CATEGORIA,
    SUCCESS_DELETE_CATEGORIA,
    ERROR_DELETE_CATEGORIA,
    RESET_DELETE_CATEGORIA,
    REQUEST_PRINT_CATEGORIA,
    SUCCESS_PRINT_CATEGORIA,
    ERROR_PRINT_CATEGORIA,
    RESET_PRINT_CATEGORIA
} from '../actions/CategoriaActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        categorias: {},
    }
}

function categoriasById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_CATEGORIAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                categorias: {}
            });
        case RECEIVE_CATEGORIAS:
            let dato = action.categorias.entities.categorias;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                categorias: merge({}, state.categorias, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_CATEGORIA:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_CATEGORIA:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_CATEGORIA:
            let datoCategoria = action.categoria.entities.categorias;
            return Object.assign({}, state, {
                categorias: merge({}, state.categorias, datoCategoria),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_CATEGORIA:
            let datoCategoriaEliminado = action.categoria.entities.categorias;
            return Object.assign({}, state, {
                categorias: mergeWith(clone(datoCategoriaEliminado), state.categorias, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CATEGORIA:
            let datoCategoriaCreado = action.categoria.entities.categorias;
            return Object.assign({}, state, {
                categorias: mergeWith(clone(datoCategoriaCreado), state.categorias, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CATEGORIAS:
            let datosCategoriaCreado = action.categorias.entities.categorias;
                return Object.assign({}, state, {
                    categorias: mergeWith(clone(datosCategoriaCreado), state.categorias, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_CATEGORIA:
            let datoCategoriaActualizado = action.categoria.entities.categorias;
            return Object.assign({}, state, {
                categorias: mergeWith(clone(datoCategoriaActualizado), state.categorias, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_CATEGORIAS:
            let datosCategoriaActualizado = action.categorias.entities.categorias;
                return Object.assign({}, state, {
                    categorias: mergeWith(clone(datosCategoriaActualizado), state.categorias, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                categorias: {}
            });
        default:
            return state
    }
}


function allCategorias(state = [], action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return action.categorias.result && action.categorias.result.categorias ? union(action.categorias.result.categorias, state) : (action.categorias.result ? action.categorias.result : state) ;
        case RECEIVE_CATEGORIA:
                return action.categoria.result ? union([action.categoria.result], state) : state;
        
        case SUCCESS_CREATE_CATEGORIA:
                   let datoCategoriaSCreate = action.categoria.entities.categorias;
                   let idNuevoSCreate = null;
                   if (Object.values(datoCategoriaSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoCategoriaSCreate)[0] && Object.values(datoCategoriaSCreate)[0].id ? Object.values(datoCategoriaSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_CATEGORIAS:
                   let categoriasCreate = action.categorias.entities && action.categorias.entities.categorias ? action.categorias.entities.categorias : null;
                   return categoriasCreate ?
                       union(state, Object.values(categoriasCreate).map((categorias) => {
                           return categorias.id
                       })) : state;
        case RESET_CATEGORIAS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalCategorias(state = null, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return action.categorias && action.categorias.result.total ? action.categorias.result.total : 0;
        case RESET_CATEGORIAS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIA:
            let dato = action.categoria.entities.categorias;
            let categoria = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: categoria ? categoria : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_CATEGORIA:
            let idsUpdate = [];
            Object.values(action.categoria).map((categoriaUpdate) => {
                if (categoriaUpdate && categoriaUpdate.id)
                    idsUpdate.push(categoriaUpdate.id);
            });
            return merge({}, state, {
                activo: action.categoria,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_CATEGORIA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_CATEGORIA:
            let datoCategoriaActualizado = {};
            if (Object.values(action.categoria.entities.categorias).length > 0)
                datoCategoriaActualizado = Object.values(action.categoria.entities.categorias)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoCategoriaActualizado
            });
        case ERROR_UPDATE_CATEGORIA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_CATEGORIAS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_CATEGORIAS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_CATEGORIAS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_CATEGORIA:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_CATEGORIA:
            let datoCategoriaDelete = action.categoria;
            let idsDelete = [];
           Object.values(action.categoria).map((categoriaDelete) => {
               if (categoriaDelete && categoriaDelete.id)
                   idsDelete.push(categoriaDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoCategoriaDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_CATEGORIA:
                       let datoCategoriaDeleteUpdate = action.categoria;
                       let idsDeleteUpdate = [];
                      Object.values(action.categoria).map((categoriaDelete) => {
                          if (categoriaDelete && categoriaDelete.id)
                              idsDeleteUpdate.push(categoriaDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoCategoriaDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_CATEGORIA:
                    let datoCategoriaDeleted = {};
                    if (Object.values(action.categoria.entities.categorias).length > 0)
                        datoCategoriaDeleted = Object.values(action.categoria.entities.categorias)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoCategoriaDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_CATEGORIA:
             let idsCreate = [];
             Object.values(action.categoria).map((categoriaCreate) => {
                 if (categoriaCreate && categoriaCreate.id)
                     idsCreate.push(categoriaCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.categoria,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_CATEGORIA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_CATEGORIA:
            let datoCategoriaNuevo = {};
            if (Object.values(action.categoria.entities.categorias).length > 0)
                datoCategoriaNuevo = Object.values(action.categoria.entities.categorias)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoCategoriaNuevo,
                nuevos: []
            });
        case ERROR_CREATE_CATEGORIA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_CATEGORIAS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_CATEGORIAS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_CATEGORIAS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_CATEGORIA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_CATEGORIA:
           let datoCategoriaDelete = action.categoria;
           let idsDelete = [];
           Object.values(action.categoria).map((categoriaDelete) => {
               if (categoriaDelete && categoriaDelete.id)
                   idsDelete.push(categoriaDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoCategoriaDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_CATEGORIA:
                  let datoCategoriaDeleteCreate = action.categoria;
                  let idsDeleteCreate = [];
                  Object.values(action.categoria).map((categoriaDelete) => {
                      if (categoriaDelete && categoriaDelete.id)
                          idsDeleteCreate.push(categoriaDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoCategoriaDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_CATEGORIA:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.categoria,
                error: null,
            });
        case REQUEST_DELETE_CATEGORIA:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_CATEGORIA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_CATEGORIA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_CATEGORIA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_CATEGORIA:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_CATEGORIA:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_CATEGORIA:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const categorias = combineReducers({
    byId: categoriasById,
    allIds: allCategorias,
    update: update,
    create: create,
    totalCategorias: totalCategorias,
    delete: deleter,
    print: print
});

export default categorias;