import {
    INVALIDATE_TIENDAS,
    ERROR_TIENDAS,
    RECEIVE_TIENDAS,
    REQUEST_TIENDAS,
    RESET_TIENDAS,
    ERROR_TIENDA,
    RECEIVE_TIENDA,
    REQUEST_TIENDA,
    UPDATE_TIENDA,
    REQUEST_UPDATE_TIENDA,
    SUCCESS_UPDATE_TIENDA,
    ERROR_UPDATE_TIENDA,
    RESET_UPDATE_TIENDA,
    REQUEST_UPDATE_TIENDAS,
    SUCCESS_UPDATE_TIENDAS,
    ERROR_UPDATE_TIENDAS,
    RESET_UPDATE_TIENDAS,
    CREATE_TIENDA,
    ERROR_CREATE_TIENDA,
    REQUEST_CREATE_TIENDA,
    RESET_CREATE_TIENDA,
    SUCCESS_CREATE_TIENDA,
    REQUEST_CREATE_TIENDAS,
    SUCCESS_CREATE_TIENDAS,
    ERROR_CREATE_TIENDAS,
    RESET_CREATE_TIENDAS,
    DELETE_TIENDA,
    DELETE_CREATE_TIENDA,
    DELETE_UPDATE_TIENDA,
    REQUEST_DELETE_TIENDA,
    SUCCESS_DELETE_TIENDA,
    ERROR_DELETE_TIENDA,
    RESET_DELETE_TIENDA,
    REQUEST_PRINT_TIENDA,
    SUCCESS_PRINT_TIENDA,
    ERROR_PRINT_TIENDA,
    RESET_PRINT_TIENDA
} from '../actions/TiendaActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        tiendas: {},
    }
}

function tiendasById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_TIENDAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_TIENDAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_TIENDAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_TIENDAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                tiendas: {}
            });
        case RECEIVE_TIENDAS:
            let dato = action.tiendas.entities.tiendas;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                tiendas: merge({}, state.tiendas, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_TIENDA:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_TIENDA:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_TIENDA:
            let datoTienda = action.tienda.entities.tiendas;
            return Object.assign({}, state, {
                tiendas: merge({}, state.tiendas, datoTienda),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_TIENDA:
            let datoTiendaEliminado = action.tienda.entities.tiendas;
            return Object.assign({}, state, {
                tiendas: mergeWith(clone(datoTiendaEliminado), state.tiendas, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_TIENDA:
            let datoTiendaCreado = action.tienda.entities.tiendas;
            return Object.assign({}, state, {
                tiendas: mergeWith(clone(datoTiendaCreado), state.tiendas, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_TIENDAS:
            let datosTiendaCreado = action.tiendas.entities.tiendas;
                return Object.assign({}, state, {
                    tiendas: mergeWith(clone(datosTiendaCreado), state.tiendas, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_TIENDA:
            let datoTiendaActualizado = action.tienda.entities.tiendas;
            return Object.assign({}, state, {
                tiendas: mergeWith(clone(datoTiendaActualizado), state.tiendas, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_TIENDAS:
            let datosTiendaActualizado = action.tiendas.entities.tiendas;
                return Object.assign({}, state, {
                    tiendas: mergeWith(clone(datosTiendaActualizado), state.tiendas, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                tiendas: {}
            });
        default:
            return state
    }
}


function allTiendas(state = [], action) {
    switch (action.type) {
        case RECEIVE_TIENDAS:
            return action.tiendas.result && action.tiendas.result.tiendas ? union(action.tiendas.result.tiendas, state) : (action.tiendas.result ? action.tiendas.result : state) ;
        case RECEIVE_TIENDA:
                return action.tienda.result ? union([action.tienda.result], state) : state;
        
        case SUCCESS_CREATE_TIENDA:
                   let datoTiendaSCreate = action.tienda.entities.tiendas;
                   let idNuevoSCreate = null;
                   if (Object.values(datoTiendaSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoTiendaSCreate)[0] && Object.values(datoTiendaSCreate)[0].id ? Object.values(datoTiendaSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_TIENDAS:
                   let tiendasCreate = action.tiendas.entities && action.tiendas.entities.tiendas ? action.tiendas.entities.tiendas : null;
                   return tiendasCreate ?
                       union(state, Object.values(tiendasCreate).map((tiendas) => {
                           return tiendas.id
                       })) : state;
        case RESET_TIENDAS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalTiendas(state = null, action) {
    switch (action.type) {
        case RECEIVE_TIENDAS:
            return action.tiendas && action.tiendas.result.total ? action.tiendas.result.total : 0;
        case RESET_TIENDAS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_TIENDA:
            let dato = action.tienda.entities.tiendas;
            let tienda = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: tienda ? tienda : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_TIENDA:
            let idsUpdate = [];
            Object.values(action.tienda).map((tiendaUpdate) => {
                if (tiendaUpdate && tiendaUpdate.id)
                    idsUpdate.push(tiendaUpdate.id);
            });
            return merge({}, state, {
                activo: action.tienda,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_TIENDA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_TIENDA:
            let datoTiendaActualizado = {};
            if (Object.values(action.tienda.entities.tiendas).length > 0)
                datoTiendaActualizado = Object.values(action.tienda.entities.tiendas)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoTiendaActualizado
            });
        case ERROR_UPDATE_TIENDA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_TIENDAS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_TIENDAS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_TIENDAS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_TIENDA:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_TIENDA:
            let datoTiendaDelete = action.tienda;
            let idsDelete = [];
           Object.values(action.tienda).map((tiendaDelete) => {
               if (tiendaDelete && tiendaDelete.id)
                   idsDelete.push(tiendaDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoTiendaDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_TIENDA:
                       let datoTiendaDeleteUpdate = action.tienda;
                       let idsDeleteUpdate = [];
                      Object.values(action.tienda).map((tiendaDelete) => {
                          if (tiendaDelete && tiendaDelete.id)
                              idsDeleteUpdate.push(tiendaDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoTiendaDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_TIENDA:
                    let datoTiendaDeleted = {};
                    if (Object.values(action.tienda.entities.tiendas).length > 0)
                        datoTiendaDeleted = Object.values(action.tienda.entities.tiendas)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoTiendaDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_TIENDA:
             let idsCreate = [];
             Object.values(action.tienda).map((tiendaCreate) => {
                 if (tiendaCreate && tiendaCreate.id)
                     idsCreate.push(tiendaCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.tienda,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_TIENDA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_TIENDA:
            let datoTiendaNuevo = {};
            if (Object.values(action.tienda.entities.tiendas).length > 0)
                datoTiendaNuevo = Object.values(action.tienda.entities.tiendas)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoTiendaNuevo,
                nuevos: []
            });
        case ERROR_CREATE_TIENDA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_TIENDAS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_TIENDAS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_TIENDAS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_TIENDA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_TIENDA:
           let datoTiendaDelete = action.tienda;
           let idsDelete = [];
           Object.values(action.tienda).map((tiendaDelete) => {
               if (tiendaDelete && tiendaDelete.id)
                   idsDelete.push(tiendaDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoTiendaDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_TIENDA:
                  let datoTiendaDeleteCreate = action.tienda;
                  let idsDeleteCreate = [];
                  Object.values(action.tienda).map((tiendaDelete) => {
                      if (tiendaDelete && tiendaDelete.id)
                          idsDeleteCreate.push(tiendaDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoTiendaDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_TIENDA:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.tienda,
                error: null,
            });
        case REQUEST_DELETE_TIENDA:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_TIENDA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_TIENDA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_TIENDA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_TIENDA:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_TIENDA:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_TIENDA:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const tiendas = combineReducers({
    byId: tiendasById,
    allIds: allTiendas,
    update: update,
    create: create,
    totalTiendas: totalTiendas,
    delete: deleter,
    print: print
});

export default tiendas;