import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import "./assets/css/Fonts.css";
import "./assets/css/index.css";
// css
// import Swiper styles
import 'swiper/css/swiper.min.css';
//Router
import {Router, Route, Switch} from "react-router-dom";
import history from './history';
//Redux
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import appReducers from './reducers/index';
import {createLogger} from 'redux-logger';
import {Provider} from 'react-redux';
//Components
import LayoutLanding from "./landingComponentes/LayoutLanding/LayoutLanding";
import NotFound from "./landingComponentes/OtrasPaginas/NotFound";


// Creates the Redux reducer with the redux-thunk middleware, which allows us
// to do asynchronous things in the actions
var createStoreWithMiddleware = null;
if (process.env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger();
    createStoreWithMiddleware = applyMiddleware(thunk, loggerMiddleware)(createStore);
    console.log("Modo debug activado")
} else {
    console.log("Modo produccion activado 🚀")
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
}

const store = createStoreWithMiddleware(appReducers);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                {/*No privada*/}
                <Route path="/" component={LayoutLanding}/>
                <Route path={"*"} component={NotFound}/>
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
