var medidasUtil = {
    getDesUnidadMedida(medida, cantidad) {
        switch (medida) {
            case "unidad":
                if (cantidad > 1) {
                    return "unidades";
                } else
                    return "unidad";
            case "pack":
                if (cantidad > 1) {
                    return "packs";
                } else
                    return "pack";
            case "bulto":
                if (cantidad > 1) {
                    return "bultos";
                } else
                    return "bulto";
            case "kilogramo":
                if (cantidad > 1) {
                    return "kilos";
                } else
                    return "kilo";
            case "display":
                if (cantidad > 1) {
                    return "displays";
                } else
                    return "display";
        }
    }
}

export default medidasUtil;