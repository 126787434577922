//api
import productosDestinoPromocionApi from "../api/productosDestinoPromocionApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProductosDestinoPromocion";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PRODUCTOSDESTINOPROMOCION
export const REQUEST_PRODUCTOSDESTINOPROMOCION = 'REQUEST_PRODUCTOSDESTINOPROMOCION';
export const RECEIVE_PRODUCTOSDESTINOPROMOCION = 'RECEIVE_PRODUCTOSDESTINOPROMOCION';
export const INVALIDATE_PRODUCTOSDESTINOPROMOCION = 'INVALIDATE_PRODUCTOSDESTINOPROMOCION';
export const ERROR_PRODUCTOSDESTINOPROMOCION = "ERROR_PRODUCTOSDESTINOPROMOCION";
export const RESET_PRODUCTOSDESTINOPROMOCION = "RESET_PRODUCTOSDESTINOPROMOCION";


export function invalidateProductosDestinoPromocion() {
    return {
        type: INVALIDATE_PRODUCTOSDESTINOPROMOCION
    }
}

function requestProductosDestinoPromocion() {
    return {
        type: REQUEST_PRODUCTOSDESTINOPROMOCION,
    }
}

function receiveProductosDestinoPromocion(json) {
    return {
        type: RECEIVE_PRODUCTOSDESTINOPROMOCION,
    productosDestinoPromocion: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProductosDestinoPromocion(error) {
    return {
        type: ERROR_PRODUCTOSDESTINOPROMOCION,
        error: error,
    }
}

export function resetProductosDestinoPromocion() {
    return {
        type: RESET_PRODUCTOSDESTINOPROMOCION
    }
}

export function fetchProductosDestinoPromocion(filtros) {
    return dispatch => {
        dispatch(requestProductosDestinoPromocion());
        return productosDestinoPromocionApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductosDestinoPromocion(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductosDestinoPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductosDestinoPromocion(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProductosDestinoPromocion(state) {
    const productosDestinoPromocion = state.productosDestinoPromocion.byId;
    if (!productosDestinoPromocion) {
        return true
    } else if (productosDestinoPromocion.isFetching) {
        return false
    } else {
        return productosDestinoPromocion.didInvalidate;
    }
}

export function fetchProductosDestinoPromocionIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProductosDestinoPromocion(getState())) {
            return dispatch(fetchProductosDestinoPromocion(filtros))
        }
    }
}


//MODEL
export const REQUEST_PRODUCTODESTINOPROMOCION = 'REQUEST_PRODUCTODESTINOPROMOCION';
export const RECEIVE_PRODUCTODESTINOPROMOCION = 'RECEIVE_PRODUCTODESTINOPROMOCION';
export const INVALIDATE_PRODUCTODESTINOPROMOCION = 'INVALIDATE_PRODUCTODESTINOPROMOCION';
export const ERROR_PRODUCTODESTINOPROMOCION = "ERROR_PRODUCTODESTINOPROMOCION";
export const RESET_PRODUCTODESTINOPROMOCION = "RESET_PRODUCTODESTINOPROMOCION";


export function invalidateProductoDestinoPromocion() {
    return {
        type: INVALIDATE_PRODUCTODESTINOPROMOCION
    }
}

function requestProductoDestinoPromocion() {
    return {
        type: REQUEST_PRODUCTODESTINOPROMOCION,
    }
}

export function receiveProductoDestinoPromocion(json) {
    return {
        type: RECEIVE_PRODUCTODESTINOPROMOCION,
    productoDestinoPromocion: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProductoDestinoPromocion(error) {
    return {
        type: ERROR_PRODUCTODESTINOPROMOCION,
        error: error,
    }
}

export function fetchProductoDestinoPromocion(idProductoDestinoPromocion) {
    return dispatch => {
        dispatch(requestProductoDestinoPromocion());
        return productosDestinoPromocionApi.getOne(idProductoDestinoPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductoDestinoPromocion(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProductoDestinoPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductoDestinoPromocion(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_PRODUCTODESTINOPROMOCION = 'UPDATE_PRODUCTODESTINOPROMOCION';
export const REQUEST_UPDATE_PRODUCTODESTINOPROMOCION = "REQUEST_UPDATE_PRODUCTODESTINOPROMOCION";
export const SUCCESS_UPDATE_PRODUCTODESTINOPROMOCION = "SUCCESS_UPDATE_PRODUCTODESTINOPROMOCION";
export const ERROR_UPDATE_PRODUCTODESTINOPROMOCION = "ERROR_UPDATE_PRODUCTODESTINOPROMOCION";
export const RESET_UPDATE_PRODUCTODESTINOPROMOCION = "RESET_UPDATE_PRODUCTODESTINOPROMOCION";
export const DELETE_UPDATE_PRODUCTODESTINOPROMOCION = "DELETE_UPDATE_PRODUCTODESTINOPROMOCION";

function requestUpdateProductoDestinoPromocion() {
    return {
        type: REQUEST_UPDATE_PRODUCTODESTINOPROMOCION,
    }
}

function receiveUpdateProductoDestinoPromocion(productoDestinoPromocion) {
    return {
        type: SUCCESS_UPDATE_PRODUCTODESTINOPROMOCION,
        receivedAt: Date.now(),
        productoDestinoPromocion: normalizeDato(productoDestinoPromocion)
    }
}

function errorUpdateProductoDestinoPromocion(error) {
    return {
        type: ERROR_UPDATE_PRODUCTODESTINOPROMOCION,
        error: error,
    }
}

export function resetUpdateProductoDestinoPromocion() {
    return {
        type: RESET_UPDATE_PRODUCTODESTINOPROMOCION,
    }
}

export function updateProductoDestinoPromocion(productoDestinoPromocion) {
    return {
        type: UPDATE_PRODUCTODESTINOPROMOCION,
        productoDestinoPromocion
    }
}

export function saveUpdateProductoDestinoPromocion() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductoDestinoPromocion());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productoDestinoPromocion = denormalizeDato(getState().productosDestinoPromocion.update.activo, store);

        return productosDestinoPromocionApi.saveUpdate(productoDestinoPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductoDestinoPromocion(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProductoDestinoPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateProductosDestinoPromocion(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateProductosDestinoPromocion(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProductoDestinoPromocion(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateProductoDestinoPromocion(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateProductoDestinoPromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateProductoDestinoPromocion(productoDestinoPromocion) {
    return {
        type: DELETE_UPDATE_PRODUCTODESTINOPROMOCION,
        productoDestinoPromocion
    }
}

//UPDATE PRODUCTOSDESTINOPROMOCION
export const REQUEST_UPDATE_PRODUCTOSDESTINOPROMOCION = "REQUEST_UPDATE_PRODUCTOSDESTINOPROMOCION";
export const SUCCESS_UPDATE_PRODUCTOSDESTINOPROMOCION = "SUCCESS_UPDATE_PRODUCTOSDESTINOPROMOCION";
export const ERROR_UPDATE_PRODUCTOSDESTINOPROMOCION = "ERROR_UPDATE_PRODUCTOSDESTINOPROMOCION";
export const RESET_UPDATE_PRODUCTOSDESTINOPROMOCION = "RESET_UPDATE_PRODUCTOSDESTINOPROMOCION";

function requestUpdateProductosDestinoPromocion() {
    return {
        type: REQUEST_UPDATE_PRODUCTOSDESTINOPROMOCION,
}
}

function receiveUpdateProductosDestinoPromocion(productosDestinoPromocion) {
    return {
        type: SUCCESS_UPDATE_PRODUCTOSDESTINOPROMOCION,
    receivedAt: Date.now(),
        productosDestinoPromocion: normalizeDatos(productosDestinoPromocion)
}
}

function errorUpdateProductosDestinoPromocion(error) {
    return {
        type: ERROR_UPDATE_PRODUCTOSDESTINOPROMOCION,
    error: error,
}
}

export function resetUpdateProductosDestinoPromocion() {
    return {
        type: RESET_UPDATE_PRODUCTOSDESTINOPROMOCION,
}
}

export function saveUpdateProductosDestinoPromocion() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductosDestinoPromocion());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productosDestinoPromocion = getState().productosDestinoPromocion.update.activos.map((idProductoDestinoPromocion) => {
            return denormalizeDato(getState().productosDestinoPromocion.update.activo[idProductoDestinoPromocion], store);
        });

        return productosDestinoPromocionApi.saveUpdateProductosDestinoPromocion(productosDestinoPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductosDestinoPromocion(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProductosDestinoPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateProductosDestinoPromocion(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateProductosDestinoPromocion(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateProductosDestinoPromocion(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateProductosDestinoPromocion(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PRODUCTODESTINOPROMOCION
export const CREATE_PRODUCTODESTINOPROMOCION = 'CREATE_PRODUCTODESTINOPROMOCION';
export const REQUEST_CREATE_PRODUCTODESTINOPROMOCION = "REQUEST_CREATE_PRODUCTODESTINOPROMOCION";
export const SUCCESS_CREATE_PRODUCTODESTINOPROMOCION = "SUCCESS_CREATE_PRODUCTODESTINOPROMOCION";
export const ERROR_CREATE_PRODUCTODESTINOPROMOCION = "ERROR_CREATE_PRODUCTODESTINOPROMOCION";
export const RESET_CREATE_PRODUCTODESTINOPROMOCION = "RESET_CREATE_PRODUCTODESTINOPROMOCION";
export const DELETE_CREATE_PRODUCTODESTINOPROMOCION = "DELETE_CREATE_PRODUCTODESTINOPROMOCION";


//ALTA PRODUCTODESTINOPROMOCION
function requestCreateProductoDestinoPromocion() {
    return {
        type: REQUEST_CREATE_PRODUCTODESTINOPROMOCION,
    }
}

function receiveCreateProductoDestinoPromocion(productoDestinoPromocion) {
    return {
        type: SUCCESS_CREATE_PRODUCTODESTINOPROMOCION,
        receivedAt: Date.now(),
        productoDestinoPromocion: normalizeDato(productoDestinoPromocion)
    }
}

export function errorCreateProductoDestinoPromocion(error) {
    return {
        type: ERROR_CREATE_PRODUCTODESTINOPROMOCION,
        error: error,
    }
}

export function resetCreateProductoDestinoPromocion() {
    return {
        type: RESET_CREATE_PRODUCTODESTINOPROMOCION
    }
}

export function createProductoDestinoPromocion(productoDestinoPromocion) {
    return {
        type: CREATE_PRODUCTODESTINOPROMOCION,
        productoDestinoPromocion
    }
}

export function deleteCreateProductoDestinoPromocion(productoDestinoPromocion) {
    return {
        type: DELETE_CREATE_PRODUCTODESTINOPROMOCION,
        productoDestinoPromocion
    }
}

export function saveCreateProductoDestinoPromocion() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductoDestinoPromocion());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productoDestinoPromocion = denormalizeDato(getState().productosDestinoPromocion.create.nuevo, store);

        return productosDestinoPromocionApi.saveCreate(productoDestinoPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductoDestinoPromocion(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProductoDestinoPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateProductosDestinoPromocion(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateProductosDestinoPromocion(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateProductoDestinoPromocion(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateProductoDestinoPromocion(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateProductoDestinoPromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PRODUCTOSDESTINOPROMOCION
export const REQUEST_CREATE_PRODUCTOSDESTINOPROMOCION = "REQUEST_CREATE_PRODUCTOSDESTINOPROMOCION";
export const SUCCESS_CREATE_PRODUCTOSDESTINOPROMOCION = "SUCCESS_CREATE_PRODUCTOSDESTINOPROMOCION";
export const ERROR_CREATE_PRODUCTOSDESTINOPROMOCION = "ERROR_CREATE_PRODUCTOSDESTINOPROMOCION";
export const RESET_CREATE_PRODUCTOSDESTINOPROMOCION = "RESET_CREATE_PRODUCTOSDESTINOPROMOCION";

function requestCreateProductosDestinoPromocion() {
    return {
        type: REQUEST_CREATE_PRODUCTOSDESTINOPROMOCION,
}
}

function receiveCreateProductosDestinoPromocion(productosDestinoPromocion) {
    return {
        type: SUCCESS_CREATE_PRODUCTOSDESTINOPROMOCION,
    receivedAt: Date.now(),
        productosDestinoPromocion: normalizeDatos(productosDestinoPromocion)
}
}

function errorCreateProductosDestinoPromocion(error) {
    return {
        type: ERROR_CREATE_PRODUCTOSDESTINOPROMOCION,
    error: error,
}
}

export function resetCreateProductosDestinoPromocion() {
    return {
        type: RESET_CREATE_PRODUCTOSDESTINOPROMOCION,
}
}

export function saveCreateProductosDestinoPromocion() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductosDestinoPromocion());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productosDestinoPromocion = getState().productosDestinoPromocion.create.nuevos.map((idProductoDestinoPromocion) => {
            return denormalizeDato(getState().productosDestinoPromocion.create.nuevo[idProductoDestinoPromocion], store);
        });

        return productosDestinoPromocionApi.saveCreateProductosDestinoPromocion(productosDestinoPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductosDestinoPromocion(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateProductosDestinoPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateProductosDestinoPromocion(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateProductosDestinoPromocion(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateProductosDestinoPromocion(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateProductosDestinoPromocion(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PRODUCTODESTINOPROMOCION
export const DELETE_PRODUCTODESTINOPROMOCION = 'DELETE_PRODUCTODESTINOPROMOCION';
export const REQUEST_DELETE_PRODUCTODESTINOPROMOCION = "REQUEST_DELETE_PRODUCTODESTINOPROMOCION";
export const SUCCESS_DELETE_PRODUCTODESTINOPROMOCION = "SUCCESS_DELETE_PRODUCTODESTINOPROMOCION";
export const ERROR_DELETE_PRODUCTODESTINOPROMOCION = "ERROR_DELETE_PRODUCTODESTINOPROMOCION";
export const RESET_DELETE_PRODUCTODESTINOPROMOCION = "RESET_DELETE_PRODUCTODESTINOPROMOCION";

function requestDeleteProductoDestinoPromocion() {
    return {
        type: REQUEST_DELETE_PRODUCTODESTINOPROMOCION,
    }
}

function receiveDeleteProductoDestinoPromocion(productoDestinoPromocion) {
    return {
        type: SUCCESS_DELETE_PRODUCTODESTINOPROMOCION,
        receivedAt: Date.now(),
        productoDestinoPromocion: normalizeDato(productoDestinoPromocion)
    }
}

function errorDeleteProductoDestinoPromocion(error) {
    return {
        type: ERROR_DELETE_PRODUCTODESTINOPROMOCION,
        error: error,
    }
}

export function resetDeleteProductoDestinoPromocion(error) {
    return {
        type: RESET_DELETE_PRODUCTODESTINOPROMOCION,
        error: error,
    }
}

export function deleteProductoDestinoPromocion(productoDestinoPromocion) {
    return {
        type: DELETE_PRODUCTODESTINOPROMOCION,
        productoDestinoPromocion
    }
}

export function saveDeleteProductoDestinoPromocion(productoDestinoPromocion) {
    return dispatch => {
        dispatch(requestDeleteProductoDestinoPromocion());
        return productosDestinoPromocionApi.saveDelete(productoDestinoPromocion)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProductoDestinoPromocion());
                            dispatch(receiveDeleteProductoDestinoPromocion(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProductoDestinoPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteProductoDestinoPromocion(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteProductoDestinoPromocion(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProductoDestinoPromocion(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteProductoDestinoPromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PRODUCTODESTINOPROMOCION
export const REQUEST_PRINT_PRODUCTODESTINOPROMOCION = "REQUEST_PRINT_PRODUCTODESTINOPROMOCION";
export const SUCCESS_PRINT_PRODUCTODESTINOPROMOCION = "SUCCESS_PRINT_PRODUCTODESTINOPROMOCION";
export const ERROR_PRINT_PRODUCTODESTINOPROMOCION = "ERROR_PRINT_PRODUCTODESTINOPROMOCION";
export const RESET_PRINT_PRODUCTODESTINOPROMOCION = "RESET_PRINT_PRODUCTODESTINOPROMOCION";

function requestPrintProductoDestinoPromocion() {
    return {
        type: REQUEST_PRINT_PRODUCTODESTINOPROMOCION,
    }
}

function receivePrintProductoDestinoPromocion(turnos) {
    return {
        type: SUCCESS_PRINT_PRODUCTODESTINOPROMOCION,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintProductoDestinoPromocion(error) {
    return {
        type: ERROR_PRINT_PRODUCTODESTINOPROMOCION,
        error: error,
    }
}

export function resetPrintProductoDestinoPromocion() {
    return {
        type: RESET_PRINT_PRODUCTODESTINOPROMOCION,
    }
}

export function printProductoDestinoPromocion(idProductoDestinoPromocion) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintProductoDestinoPromocion());
        return productosDestinoPromocionApi.printProductoDestinoPromocion(idProductoDestinoPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintProductoDestinoPromocion(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintProductoDestinoPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintProductoDestinoPromocion(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintProductoDestinoPromocion(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintProductoDestinoPromocion(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}