import {
    INVALIDATE_PRODUCTOSDESTINOPROMOCION,
    ERROR_PRODUCTOSDESTINOPROMOCION,
    RECEIVE_PRODUCTOSDESTINOPROMOCION,
    REQUEST_PRODUCTOSDESTINOPROMOCION,
    RESET_PRODUCTOSDESTINOPROMOCION,
    ERROR_PRODUCTODESTINOPROMOCION,
    RECEIVE_PRODUCTODESTINOPROMOCION,
    REQUEST_PRODUCTODESTINOPROMOCION,
    UPDATE_PRODUCTODESTINOPROMOCION,
    REQUEST_UPDATE_PRODUCTODESTINOPROMOCION,
    SUCCESS_UPDATE_PRODUCTODESTINOPROMOCION,
    ERROR_UPDATE_PRODUCTODESTINOPROMOCION,
    RESET_UPDATE_PRODUCTODESTINOPROMOCION,
    REQUEST_UPDATE_PRODUCTOSDESTINOPROMOCION,
    SUCCESS_UPDATE_PRODUCTOSDESTINOPROMOCION,
    ERROR_UPDATE_PRODUCTOSDESTINOPROMOCION,
    RESET_UPDATE_PRODUCTOSDESTINOPROMOCION,
    CREATE_PRODUCTODESTINOPROMOCION,
    ERROR_CREATE_PRODUCTODESTINOPROMOCION,
    REQUEST_CREATE_PRODUCTODESTINOPROMOCION,
    RESET_CREATE_PRODUCTODESTINOPROMOCION,
    SUCCESS_CREATE_PRODUCTODESTINOPROMOCION,
    REQUEST_CREATE_PRODUCTOSDESTINOPROMOCION,
    SUCCESS_CREATE_PRODUCTOSDESTINOPROMOCION,
    ERROR_CREATE_PRODUCTOSDESTINOPROMOCION,
    RESET_CREATE_PRODUCTOSDESTINOPROMOCION,
    DELETE_PRODUCTODESTINOPROMOCION,
    DELETE_CREATE_PRODUCTODESTINOPROMOCION,
    DELETE_UPDATE_PRODUCTODESTINOPROMOCION,
    REQUEST_DELETE_PRODUCTODESTINOPROMOCION,
    SUCCESS_DELETE_PRODUCTODESTINOPROMOCION,
    ERROR_DELETE_PRODUCTODESTINOPROMOCION,
    RESET_DELETE_PRODUCTODESTINOPROMOCION,
    REQUEST_PRINT_PRODUCTODESTINOPROMOCION,
    SUCCESS_PRINT_PRODUCTODESTINOPROMOCION,
    ERROR_PRINT_PRODUCTODESTINOPROMOCION,
    RESET_PRINT_PRODUCTODESTINOPROMOCION
} from '../actions/ProductoDestinoPromocionActions';

import {
    RECEIVE_PROMOCION,
    RECEIVE_PROMOCIONES,
    SUCCESS_DELETE_PROMOCION,
    SUCCESS_CREATE_PROMOCION,
    SUCCESS_UPDATE_PROMOCION,
    SUCCESS_UPDATE_PROMOCIONES

} from '../actions/PromocionActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        productosDestinoPromocion: {},
    }
}

function productosDestinoPromocionById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PRODUCTOSDESTINOPROMOCION:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTOSDESTINOPROMOCION:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTOSDESTINOPROMOCION:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTOSDESTINOPROMOCION:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                productosDestinoPromocion: {}
            });
        case RECEIVE_PRODUCTOSDESTINOPROMOCION:
            let dato = action.productosDestinoPromocion.entities.productosDestinoPromocion;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productosDestinoPromocion: merge({}, state.productosDestinoPromocion, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PRODUCTODESTINOPROMOCION:
            let datoProductoDestinoPromocion = action.productoDestinoPromocion.entities.productosDestinoPromocion;
            return Object.assign({}, state, {
                productosDestinoPromocion: merge({}, state.productosDestinoPromocion, datoProductoDestinoPromocion),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_PRODUCTODESTINOPROMOCION:
            let datoProductoDestinoPromocionEliminado = action.productoDestinoPromocion.entities.productosDestinoPromocion;
            return Object.assign({}, state, {
                productosDestinoPromocion: mergeWith(clone(datoProductoDestinoPromocionEliminado), state.productosDestinoPromocion, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTODESTINOPROMOCION:
            let datoProductoDestinoPromocionCreado = action.productoDestinoPromocion.entities.productosDestinoPromocion;
            return Object.assign({}, state, {
                productosDestinoPromocion: mergeWith(clone(datoProductoDestinoPromocionCreado), state.productosDestinoPromocion, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTOSDESTINOPROMOCION:
            let datosProductoDestinoPromocionCreado = action.productosDestinoPromocion.entities.productosDestinoPromocion;
                return Object.assign({}, state, {
                    productosDestinoPromocion: mergeWith(clone(datosProductoDestinoPromocionCreado), state.productosDestinoPromocion, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PRODUCTODESTINOPROMOCION:
            let datoProductoDestinoPromocionActualizado = action.productoDestinoPromocion.entities.productosDestinoPromocion;
            return Object.assign({}, state, {
                productosDestinoPromocion: mergeWith(clone(datoProductoDestinoPromocionActualizado), state.productosDestinoPromocion, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PRODUCTOSDESTINOPROMOCION:
            let datosProductoDestinoPromocionActualizado = action.productosDestinoPromocion.entities.productosDestinoPromocion;
                return Object.assign({}, state, {
                    productosDestinoPromocion: mergeWith(clone(datosProductoDestinoPromocionActualizado), state.productosDestinoPromocion, (objValue, srcValue) => {return objValue;})
                });

            //PROMOCION
case RECEIVE_PROMOCION:
    let promocion = action.promocion.entities && action.promocion.entities.productosDestinoPromocion ? action.promocion.entities.productosDestinoPromocion : {};
    return Object.assign({}, state, {
        productosDestinoPromocion: merge({}, state.productosDestinoPromocion, promocion),
    });
case RECEIVE_PROMOCIONES:
    let promociones = action.promociones.entities && action.promociones.entities.productosDestinoPromocion ? action.promociones.entities.productosDestinoPromocion : {};
    return Object.assign({}, state, {
        productosDestinoPromocion: merge({}, state.productosDestinoPromocion, promociones),
    });
case SUCCESS_DELETE_PROMOCION:
    let datopromocionEliminado = action.promocion.entities && action.promocion.entities.productosDestinoPromocion ? action.promocion.entities.productosDestinoPromocion : {};
    return Object.assign({}, state, {
        productosDestinoPromocion: mergeWith(clone(datopromocionEliminado), pickBy(state.productosDestinoPromocion, function(productoDestinoPromocion) {return productoDestinoPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PROMOCION:
    let datopromocionCreado = action.promocion.entities && action.promocion.entities.productosDestinoPromocion ? action.promocion.entities.productosDestinoPromocion : {};
    return Object.assign({}, state, {
        productosDestinoPromocion: mergeWith(clone(datopromocionCreado), pickBy(state.productosDestinoPromocion, function(productoDestinoPromocion) {return productoDestinoPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PROMOCION:
    let datopromocionActualizado = action.promocion.entities && action.promocion.entities.productosDestinoPromocion ? action.promocion.entities.productosDestinoPromocion : {};
    return Object.assign({}, state, {
        productosDestinoPromocion: mergeWith(clone(datopromocionActualizado), pickBy(state.productosDestinoPromocion, function(productoDestinoPromocion) {return productoDestinoPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PROMOCIONES:
    let datospromocionActualizado = action.promociones.entities && action.promociones.entities.productosDestinoPromocion ? action.promociones.entities.productosDestinoPromocion : {} ;
        return Object.assign({}, state, {
            productosDestinoPromocion: mergeWith(clone(datospromocionActualizado), state.productosDestinoPromocion, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                productosDestinoPromocion: {}
            });
        default:
            return state
    }
}


function allProductosDestinoPromocion(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTOSDESTINOPROMOCION:
            return action.productosDestinoPromocion.result && action.productosDestinoPromocion.result.productosDestinoPromocion ? union(action.productosDestinoPromocion.result.productosDestinoPromocion, state) : (action.productosDestinoPromocion.result ? action.productosDestinoPromocion.result : state) ;
        case RECEIVE_PRODUCTODESTINOPROMOCION:
                return action.productoDestinoPromocion.result ? union([action.productoDestinoPromocion.result], state) : state;
        
        case SUCCESS_CREATE_PRODUCTODESTINOPROMOCION:
                   let datoProductoDestinoPromocionSCreate = action.productoDestinoPromocion.entities.productosDestinoPromocion;
                   let idNuevoSCreate = null;
                   if (Object.values(datoProductoDestinoPromocionSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoProductoDestinoPromocionSCreate)[0] && Object.values(datoProductoDestinoPromocionSCreate)[0].id ? Object.values(datoProductoDestinoPromocionSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PRODUCTOSDESTINOPROMOCION:
                   let productosDestinoPromocionCreate = action.productosDestinoPromocion.entities && action.productosDestinoPromocion.entities.productosDestinoPromocion ? action.productosDestinoPromocion.entities.productosDestinoPromocion : null;
                   return productosDestinoPromocionCreate ?
                       union(state, Object.values(productosDestinoPromocionCreate).map((productosDestinoPromocion) => {
                           return productosDestinoPromocion.id
                       })) : state;
        case RESET_PRODUCTOSDESTINOPROMOCION:
            return [];

            case RECEIVE_PROMOCION:
    let promocion = action.promocion.entities && action.promocion.entities.productosDestinoPromocion ? action.promocion.entities.productosDestinoPromocion : null ;
    return promocion ?
        union(state, Object.values(promocion).map((promocion) => {
            return promocion.id
        })) : state;
case RECEIVE_PROMOCIONES:
    let promociones = action.promociones.entities && action.promociones.entities.productosDestinoPromocion ? action.promociones.entities.productosDestinoPromocion : null;
    return promociones ?
        union(state, Object.values(promociones).map((promociones) => {
            return promociones.id
        })) : state;

case SUCCESS_DELETE_PROMOCION:
    let promocionDelete = action.promocion.entities && action.promocion.entities.productosDestinoPromocion ? action.promocion.entities.productosDestinoPromocion : null ;
        return promocionDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionDelete).map((promocion) => {
                return promocion.id
            })) : state;
case SUCCESS_CREATE_PROMOCION:
   let promocionCreate = action.promocion.entities && action.promocion.entities.productosDestinoPromocion ? action.promocion.entities.productosDestinoPromocion : null ;
       return promocionCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionCreate).map((promocion) => {
               return promocion.id
           })) : state;
case SUCCESS_UPDATE_PROMOCION:
    let promocionUpdate = action.promocion.entities && action.promocion.entities.productosDestinoPromocion ? action.promocion.entities.productosDestinoPromocion : null ;
        return promocionUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionUpdate).map((promocion) => {
                return promocion.id
            })) : state;
 case SUCCESS_UPDATE_PROMOCIONES:
     let promocionesUpdate = action.promociones.entities && action.promociones.entities.productosDestinoPromocion ? action.promociones.entities.productosDestinoPromocion : null;
        return promocionesUpdate ?
            union(state, Object.values(promocionesUpdate).map((promociones) => {
                return promociones.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalProductosDestinoPromocion(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTOSDESTINOPROMOCION:
            return action.productosDestinoPromocion && action.productosDestinoPromocion.result.total ? action.productosDestinoPromocion.result.total : 0;
        case RESET_PRODUCTOSDESTINOPROMOCION:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTODESTINOPROMOCION:
            let dato = action.productoDestinoPromocion.entities.productosDestinoPromocion;
            let productoDestinoPromocion = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: productoDestinoPromocion ? productoDestinoPromocion : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PRODUCTODESTINOPROMOCION:
            let idsUpdate = [];
            Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionUpdate) => {
                if (productoDestinoPromocionUpdate && productoDestinoPromocionUpdate.id)
                    idsUpdate.push(productoDestinoPromocionUpdate.id);
            });
            return merge({}, state, {
                activo: action.productoDestinoPromocion,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PRODUCTODESTINOPROMOCION:
            let datoProductoDestinoPromocionActualizado = {};
            if (Object.values(action.productoDestinoPromocion.entities.productosDestinoPromocion).length > 0)
                datoProductoDestinoPromocionActualizado = Object.values(action.productoDestinoPromocion.entities.productosDestinoPromocion)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoProductoDestinoPromocionActualizado
            });
        case ERROR_UPDATE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PRODUCTOSDESTINOPROMOCION:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PRODUCTOSDESTINOPROMOCION:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PRODUCTOSDESTINOPROMOCION:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });

        case DELETE_PRODUCTODESTINOPROMOCION:
            let datoProductoDestinoPromocionDelete = action.productoDestinoPromocion;
            let idsDelete = [];
           Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionDelete) => {
               if (productoDestinoPromocionDelete && productoDestinoPromocionDelete.id)
                   idsDelete.push(productoDestinoPromocionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoProductoDestinoPromocionDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PRODUCTODESTINOPROMOCION:
                       let datoProductoDestinoPromocionDeleteUpdate = action.productoDestinoPromocion;
                       let idsDeleteUpdate = [];
                      Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionDelete) => {
                          if (productoDestinoPromocionDelete && productoDestinoPromocionDelete.id)
                              idsDeleteUpdate.push(productoDestinoPromocionDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoProductoDestinoPromocionDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PRODUCTODESTINOPROMOCION:
                    let datoProductoDestinoPromocionDeleted = {};
                    if (Object.values(action.productoDestinoPromocion.entities.productosDestinoPromocion).length > 0)
                        datoProductoDestinoPromocionDeleted = Object.values(action.productoDestinoPromocion.entities.productosDestinoPromocion)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoProductoDestinoPromocionDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PRODUCTODESTINOPROMOCION:
             let idsCreate = [];
             Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionCreate) => {
                 if (productoDestinoPromocionCreate && productoDestinoPromocionCreate.id)
                     idsCreate.push(productoDestinoPromocionCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.productoDestinoPromocion,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PRODUCTODESTINOPROMOCION:
            let datoProductoDestinoPromocionNuevo = {};
            if (Object.values(action.productoDestinoPromocion.entities.productosDestinoPromocion).length > 0)
                datoProductoDestinoPromocionNuevo = Object.values(action.productoDestinoPromocion.entities.productosDestinoPromocion)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoProductoDestinoPromocionNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PRODUCTOSDESTINOPROMOCION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PRODUCTOSDESTINOPROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTOSDESTINOPROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_PRODUCTODESTINOPROMOCION:
           let datoProductoDestinoPromocionDelete = action.productoDestinoPromocion;
           let idsDelete = [];
           Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionDelete) => {
               if (productoDestinoPromocionDelete && productoDestinoPromocionDelete.id)
                   idsDelete.push(productoDestinoPromocionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoProductoDestinoPromocionDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PRODUCTODESTINOPROMOCION:
                  let datoProductoDestinoPromocionDeleteCreate = action.productoDestinoPromocion;
                  let idsDeleteCreate = [];
                  Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionDelete) => {
                      if (productoDestinoPromocionDelete && productoDestinoPromocionDelete.id)
                          idsDeleteCreate.push(productoDestinoPromocionDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoProductoDestinoPromocionDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PRODUCTODESTINOPROMOCION:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.productoDestinoPromocion,
                error: null,
            });
        case REQUEST_DELETE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PRODUCTODESTINOPROMOCION:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const productosDestinoPromocion = combineReducers({
    byId: productosDestinoPromocionById,
    allIds: allProductosDestinoPromocion,
    update: update,
    create: create,
    totalProductosDestinoPromocion: totalProductosDestinoPromocion,
    delete: deleter,
    print: print
});

export default productosDestinoPromocion;