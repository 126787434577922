import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Actions
//Components
import { Box, IconButton, Typography } from "@material-ui/core";
import ContainerDoble from "../../customComponents/ContainerDoble";
import Line from "../../customComponents/Line";

//Icons
import background from "../../assets/img/informacionContacto/bgInformacionContacto.png";
import casa from "../../assets/img/informacionContacto/icons/casa.png";
import { ReactComponent as InstaIcon } from "../../assets/img/instagram.svg";
import { ReactComponent as FaceIcon } from "../../assets/img/facebook.svg";
import mail from "../../assets/img/informacionContacto/icons/mail.png";
import telefono from "../../assets/img/informacionContacto/icons/telefono.png";
import whatsapp from "../../assets/img/informacionContacto/icons/whatsapp.png";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "-100px",
        [theme.breakpoints.down(714)]: {
            marginTop: "-240px",
            paddingTop: "210px",
        },
        backgroundColor: "transparent",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        position: "relative",
        paddingTop: "7.1%",
        paddingBottom: "200px",
        "& .icono": {
            width: "20px",
            margin: "5px",
        },
        "& li": {
            display: "flex",
            alignItems: "center"
        }
    },
    containerInfo: {
        width: "38%",
        marginLeft: "10%",
        paddingTop: "50px",
        [theme.breakpoints.down(1076)]: {
            marginLeft: "8%",
            paddingTop: "90px",
            width: "80%",
        },
        [theme.breakpoints.down(714)]: {
            width: "100%",
            margin: 0,
            padding: theme.spacing(0, 2),
        }
    },
    listaInfo: {
        padding: 0,
        margin: 0,
        listStyle: "none",
        marginTop: "25px",
        maxWidth: "350px",
        [theme.breakpoints.down(714)]: {
            maxWidth: "100%",
            paddingBottom: "50px"
        },
        "& li": {
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
            "& a": {
                color: "white",
                textDecoration: "none",
                lineHeight: "initial",
            },
            "& p": {
                color: "white",
                lineHeight: "initial",
            }
        }
    },
    containerMapa: {
        width: "62%",
        marginRight: "3px",
        height: "299px",
        [theme.breakpoints.down(714)]: {
            width: "100%",
            marginRight: 0,
        },
        "& .parent": {
            // boxShadow: "0px 0px 40px #0000004a",
            position: "relative",
            transform: "skew(5deg) rotate(5deg)",
            overflow: "hidden",
            height: "140%",
            [theme.breakpoints.up(1920)]: {
                height: "183%",
            },
        },
        "& iframe": {
            top: "50%",
            width: "100%",
            height: "220%",
            position: "absolute",
            transform: "skew(-5deg) rotate(-5deg) translateY(-50%)",
        },

    },
    containerTitulo: {
        maxWidth: "350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    containerInformacionContacto: {
        [theme.breakpoints.down(714)]: {
            flexWrap: "wrap"
        }
    }
}));


export default function InformacionContacto() {
    const classes = useStyles();

    return (
        <Box component={"div"} className={classes.container}>
            <ContainerDoble className={classes.containerInformacionContacto}>
                <Box className={classes.containerInfo}>
                    <ContainerDoble className={classes.containerTitulo}>
                        <div>
                            <Typography variant={"h6"} style={{color: "#fff"}}>
                                <b>¿Dónde estamos?</b>
                            </Typography>
                            <Line width={"50px"} borderBottomWidth={"2px"} borderBottomColor={"white"}/>
                        </div>
                        <div>
                            <IconButton component={"a"} href="https://www.instagram.com/jvasrl" target="_blank">
                                <InstaIcon fill={"#fff"} className={"icono"}/>
                            </IconButton>
                            <IconButton component={"a"} href="https://www.facebook.com/jvasrl" target="_blank">
                                <FaceIcon fill={"#fff"} className={"icono"}/>
                            </IconButton>
                        </div>
                    </ContainerDoble>
                    <ul className={classes.listaInfo}>
                        <li>
                            < img src={casa} alt="" className={"icono"}/>
                            <Typography variant={"body2"} component={"a"} href={"https://goo.gl/maps/baNfAbs1EVqECove7"}
                                        target={"_blank"}>
                                <b>Juan Pablo II 6640</b> (Entre Iriondo y San Nicolás) - Rosario - Santa Fe
                            </Typography>
                        </li>
                        <li>
                            <img src={telefono} alt="" className={"icono"}/>
                            <Typography variant={"body2"}>
                                <b>0341 - 463 9970</b>
                            </Typography>
                        </li>
                        <li>
                            <img src={whatsapp} alt="" className={"icono"}/>
                            <Typography variant={"body2"} component={"a"} href={"https://wa.me/543412117923"}
                                        target={"_blank"}>
                                <b>+54 341 211-7923</b>
                            </Typography>
                        </li>
                        <li>
                            <img src={mail} alt="" className={"icono"}/>
                            <Typography variant={"body2"} component={"a"} href={"mailto:info@jvasrl.com"}
                                        target={"_blank"}>
                                <b>info@jvasrl.com</b>
                            </Typography>
                        </li>
                    </ul>

                </Box>
                <Box className={classes.containerMapa}>
                    <div className="parent">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6692.05305427222!2d-60.685765!3d-33.0030761!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeadef8e8970ad7c3!2sDistribuidora%20JVA%20s.r.l!5e0!3m2!1ses-419!2sar!4v1629743657552!5m2!1ses-419!2sar"
                            width="100%" height="450"
                            style={{border: 0}}
                            allowFullScreen="" loading="lazy"
                            className={"iframe"}/>
                    </div>

                </Box>

            </ContainerDoble>
        </Box>
    );
}
