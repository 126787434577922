import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from "@material-ui/lab";

class MensajeCorrecto extends React.Component {

    constructor(props) {
        super(props);
        this.state = {mostrar: false};
    }

    componentDidUpdate(prevProps) {
        let procesoFinalizado = false;
        Object.entries(this.props.state).forEach(
            ([key, value]) => {
                var valuePrev = prevProps.state[key];
                if (value.create && valuePrev && valuePrev.create && !value.create.isCreating && valuePrev.create.isCreating && !value.create.error) {
                    procesoFinalizado = true;
                }
                if (value.update && valuePrev && valuePrev.update && !value.update.isUpdating && valuePrev.update.isUpdating && !value.update.error) {
                    procesoFinalizado = true;
                }
                if (value.delete && valuePrev && valuePrev.delete && !value.delete.isDeleting && valuePrev.delete.isDeleting && !value.delete.error) {
                    procesoFinalizado = true;
                }
                if (value.notify && valuePrev && valuePrev.notify && !value.notify.isNotifying && valuePrev.notify.isNotifying && !value.notify.error) {
                    procesoFinalizado = true;
                }
            }
        );
        if (this.state.mostrar != procesoFinalizado && procesoFinalizado) {
            this.setState({mostrar: procesoFinalizado});
            setTimeout(() => this.setState({mostrar: false}), 5000);
        }
    }


    render() {
        const {mostrar} = this.state;
        return (
            <Snackbar
                open={mostrar}
                onClose={() => {
                    this.setState({mostrar: false})
                }}
                //TransitionComponent={<Slide direction="up" />}
                autoHideDuration={6000}
            >
                <Alert onClose={() => {
                    this.setState({mostrar: false})
                }} severity="success">
                    La operación se ha realizado con éxito.
                </Alert>
            </Snackbar>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state,
    };
}

// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps)(MensajeCorrecto));
