import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
    return ({
        producto: {
            flex: "0 0 auto",
            margin: "0px 5px",
            width: "284px",
            height: "380px",
            [theme.breakpoints.down(975)]: {
                width: "175px",
                height: "280px",
            },
        }

    })
});
export default function SkeletonRowProducto() {
    const classes = useStyles();
    return <Box component={"div"} marginTop={"25px"} maxWidth={"100%"}>
        <Skeleton variant="text" width={"320px"} height={"70px"} style={{marginLeft: "5px"}}/>
        <Box component={"div"} display={"flex"} justifyContent={"space-between"}
             overflow={"auto"}>
            <Skeleton variant="rect" className={classes.producto}/>
            <Skeleton variant="rect" className={classes.producto}/>
            <Skeleton variant="rect" className={classes.producto}/>
            <Skeleton variant="rect" className={classes.producto}/>
        </Box>
    </Box>;
}