import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Actions
//Components
import { Box, Button, Typography } from "@material-ui/core";
import ContainerLimit from "../../customComponents/ContainerLimit";

//Icons
import bgConsultas from "../../assets/img/consultas/bgConsultas.png";
import jvaResponde from "../../assets/img/consultas/jvaResponde.png";
import manoWhatsApp from "../../assets/img/consultas/manoWhatsApp.png";
import haceConsultas from "../../assets/img/consultas/haceConsultas.png";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `url(${bgConsultas})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        paddingTop: "150px",
        paddingBottom: "250px",
        position: "relative",
        [theme.breakpoints.down(600)]: {
            backgroundPositionX: "-630px"
        },
        [theme.breakpoints.up(2000)]: {
            paddingTop: "430px",
            paddingBottom: "430px",
            height: "calc(100vw - 90vh)",
        },
        "& h1": {
            textAlign: "center",
            [theme.breakpoints.down(714)]: {
                textAlign: "left",
                paddingLeft: "60px",
            },
        }
    },
    containerConsultas: {
        display: "flex",
        alignItems: "center",
        "& img": {
            width: "100%"
        },
        [theme.breakpoints.down(714)]: {
            padding: theme.spacing(0, 2)
        },
        [theme.breakpoints.down(1070)]: {
            display: "block",
            marginTop: "60px",
        },
    },
    btnAction: {
        display: "block",
        margin: "15px auto",
        backgroundColor: "#FF9900",
        color: "white",
        paddingLeft: "15px",
        paddingRight: "15px",
        maxWidth: "fit-content",
        [theme.breakpoints.down(714)]: {
            marginLeft: "60px",
        },
        "&:hover": {
            backgroundColor: "#ec8a00",
        }
    },
}));


export default function Consultas() {
    const classes = useStyles();

    return (
        <Box component={"div"} className={classes.container}>
            <ContainerLimit className={classes.containerConsultas}>
                <Box component={"div"} flexGrow={1}/>
                <Box component={"div"} maxWidth={"450px"}>
                    <img src={haceConsultas} alt=""/>
                    <Typography variant={"h1"} style={{color: "#fff"}}>
                        341 211 7923
                    </Typography>
                    <Button className={classes.btnAction} component={"a"} href={"https://wa.me/543412117923"}
                            target={"_blank"}>
                        ¡ESCRIBINOS AHORA!
                    </Button>
                </Box>
            </ContainerLimit>
        </Box>
    );
}
