import {
    INVALIDATE_PRODUCTOSORIGENPROMOCION,
    ERROR_PRODUCTOSORIGENPROMOCION,
    RECEIVE_PRODUCTOSORIGENPROMOCION,
    REQUEST_PRODUCTOSORIGENPROMOCION,
    RESET_PRODUCTOSORIGENPROMOCION,
    ERROR_PRODUCTOORIGENPROMOCION,
    RECEIVE_PRODUCTOORIGENPROMOCION,
    REQUEST_PRODUCTOORIGENPROMOCION,
    UPDATE_PRODUCTOORIGENPROMOCION,
    REQUEST_UPDATE_PRODUCTOORIGENPROMOCION,
    SUCCESS_UPDATE_PRODUCTOORIGENPROMOCION,
    ERROR_UPDATE_PRODUCTOORIGENPROMOCION,
    RESET_UPDATE_PRODUCTOORIGENPROMOCION,
    REQUEST_UPDATE_PRODUCTOSORIGENPROMOCION,
    SUCCESS_UPDATE_PRODUCTOSORIGENPROMOCION,
    ERROR_UPDATE_PRODUCTOSORIGENPROMOCION,
    RESET_UPDATE_PRODUCTOSORIGENPROMOCION,
    CREATE_PRODUCTOORIGENPROMOCION,
    ERROR_CREATE_PRODUCTOORIGENPROMOCION,
    REQUEST_CREATE_PRODUCTOORIGENPROMOCION,
    RESET_CREATE_PRODUCTOORIGENPROMOCION,
    SUCCESS_CREATE_PRODUCTOORIGENPROMOCION,
    REQUEST_CREATE_PRODUCTOSORIGENPROMOCION,
    SUCCESS_CREATE_PRODUCTOSORIGENPROMOCION,
    ERROR_CREATE_PRODUCTOSORIGENPROMOCION,
    RESET_CREATE_PRODUCTOSORIGENPROMOCION,
    DELETE_PRODUCTOORIGENPROMOCION,
    DELETE_CREATE_PRODUCTOORIGENPROMOCION,
    DELETE_UPDATE_PRODUCTOORIGENPROMOCION,
    REQUEST_DELETE_PRODUCTOORIGENPROMOCION,
    SUCCESS_DELETE_PRODUCTOORIGENPROMOCION,
    ERROR_DELETE_PRODUCTOORIGENPROMOCION,
    RESET_DELETE_PRODUCTOORIGENPROMOCION,
    REQUEST_PRINT_PRODUCTOORIGENPROMOCION,
    SUCCESS_PRINT_PRODUCTOORIGENPROMOCION,
    ERROR_PRINT_PRODUCTOORIGENPROMOCION,
    RESET_PRINT_PRODUCTOORIGENPROMOCION
} from '../actions/ProductoOrigenPromocionActions';

import {
    RECEIVE_PROMOCION,
    RECEIVE_PROMOCIONES,
    SUCCESS_DELETE_PROMOCION,
    SUCCESS_CREATE_PROMOCION,
    SUCCESS_UPDATE_PROMOCION,
    SUCCESS_UPDATE_PROMOCIONES

} from '../actions/PromocionActions';
import {
    RECEIVE_PRODUCTO,
    RECEIVE_PRODUCTOS,
    SUCCESS_DELETE_PRODUCTO,
    SUCCESS_CREATE_PRODUCTO,
    SUCCESS_UPDATE_PRODUCTO,
    SUCCESS_UPDATE_PRODUCTOS

} from '../actions/ProductoActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        productosOrigenPromocion: {},
    }
}

function productosOrigenPromocionById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PRODUCTOSORIGENPROMOCION:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTOSORIGENPROMOCION:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTOSORIGENPROMOCION:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTOSORIGENPROMOCION:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                productosOrigenPromocion: {}
            });
        case RECEIVE_PRODUCTOSORIGENPROMOCION:
            let dato = action.productosOrigenPromocion.entities.productosOrigenPromocion;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productosOrigenPromocion: merge({}, state.productosOrigenPromocion, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PRODUCTOORIGENPROMOCION:
            let datoProductoOrigenPromocion = action.productoOrigenPromocion.entities.productosOrigenPromocion;
            return Object.assign({}, state, {
                productosOrigenPromocion: merge({}, state.productosOrigenPromocion, datoProductoOrigenPromocion),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_PRODUCTOORIGENPROMOCION:
            let datoProductoOrigenPromocionEliminado = action.productoOrigenPromocion.entities.productosOrigenPromocion;
            return Object.assign({}, state, {
                productosOrigenPromocion: mergeWith(clone(datoProductoOrigenPromocionEliminado), state.productosOrigenPromocion, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTOORIGENPROMOCION:
            let datoProductoOrigenPromocionCreado = action.productoOrigenPromocion.entities.productosOrigenPromocion;
            return Object.assign({}, state, {
                productosOrigenPromocion: mergeWith(clone(datoProductoOrigenPromocionCreado), state.productosOrigenPromocion, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTOSORIGENPROMOCION:
            let datosProductoOrigenPromocionCreado = action.productosOrigenPromocion.entities.productosOrigenPromocion;
                return Object.assign({}, state, {
                    productosOrigenPromocion: mergeWith(clone(datosProductoOrigenPromocionCreado), state.productosOrigenPromocion, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PRODUCTOORIGENPROMOCION:
            let datoProductoOrigenPromocionActualizado = action.productoOrigenPromocion.entities.productosOrigenPromocion;
            return Object.assign({}, state, {
                productosOrigenPromocion: mergeWith(clone(datoProductoOrigenPromocionActualizado), state.productosOrigenPromocion, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PRODUCTOSORIGENPROMOCION:
            let datosProductoOrigenPromocionActualizado = action.productosOrigenPromocion.entities.productosOrigenPromocion;
                return Object.assign({}, state, {
                    productosOrigenPromocion: mergeWith(clone(datosProductoOrigenPromocionActualizado), state.productosOrigenPromocion, (objValue, srcValue) => {return objValue;})
                });

            //PROMOCION
case RECEIVE_PROMOCION:
    let promocion = action.promocion.entities && action.promocion.entities.productosOrigenPromocion ? action.promocion.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: merge({}, state.productosOrigenPromocion, promocion),
    });
case RECEIVE_PROMOCIONES:
    let promociones = action.promociones.entities && action.promociones.entities.productosOrigenPromocion ? action.promociones.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: merge({}, state.productosOrigenPromocion, promociones),
    });
case SUCCESS_DELETE_PROMOCION:
    let datopromocionEliminado = action.promocion.entities && action.promocion.entities.productosOrigenPromocion ? action.promocion.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: mergeWith(clone(datopromocionEliminado), pickBy(state.productosOrigenPromocion, function(productoOrigenPromocion) {return productoOrigenPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PROMOCION:
    let datopromocionCreado = action.promocion.entities && action.promocion.entities.productosOrigenPromocion ? action.promocion.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: mergeWith(clone(datopromocionCreado), pickBy(state.productosOrigenPromocion, function(productoOrigenPromocion) {return productoOrigenPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PROMOCION:
    let datopromocionActualizado = action.promocion.entities && action.promocion.entities.productosOrigenPromocion ? action.promocion.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: mergeWith(clone(datopromocionActualizado), pickBy(state.productosOrigenPromocion, function(productoOrigenPromocion) {return productoOrigenPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PROMOCIONES:
    let datospromocionActualizado = action.promociones.entities && action.promociones.entities.productosOrigenPromocion ? action.promociones.entities.productosOrigenPromocion : {} ;
        return Object.assign({}, state, {
            productosOrigenPromocion: mergeWith(clone(datospromocionActualizado), state.productosOrigenPromocion, (objValue, srcValue) => {return objValue;})
        });
//PRODUCTO
case RECEIVE_PRODUCTO:
    let producto = action.producto.entities && action.producto.entities.productosOrigenPromocion ? action.producto.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: merge({}, state.productosOrigenPromocion, producto),
    });
case RECEIVE_PRODUCTOS:
    let productos = action.productos.entities && action.productos.entities.productosOrigenPromocion ? action.productos.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: merge({}, state.productosOrigenPromocion, productos),
    });
case SUCCESS_DELETE_PRODUCTO:
    let datoproductoEliminado = action.producto.entities && action.producto.entities.productosOrigenPromocion ? action.producto.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: mergeWith(clone(datoproductoEliminado), pickBy(state.productosOrigenPromocion, function(productoOrigenPromocion) {return productoOrigenPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PRODUCTO:
    let datoproductoCreado = action.producto.entities && action.producto.entities.productosOrigenPromocion ? action.producto.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: mergeWith(clone(datoproductoCreado), pickBy(state.productosOrigenPromocion, function(productoOrigenPromocion) {return productoOrigenPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PRODUCTO:
    let datoproductoActualizado = action.producto.entities && action.producto.entities.productosOrigenPromocion ? action.producto.entities.productosOrigenPromocion : {};
    return Object.assign({}, state, {
        productosOrigenPromocion: mergeWith(clone(datoproductoActualizado), pickBy(state.productosOrigenPromocion, function(productoOrigenPromocion) {return productoOrigenPromocion.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PRODUCTOS:
    let datosproductoActualizado = action.productos.entities && action.productos.entities.productosOrigenPromocion ? action.productos.entities.productosOrigenPromocion : {} ;
        return Object.assign({}, state, {
            productosOrigenPromocion: mergeWith(clone(datosproductoActualizado), state.productosOrigenPromocion, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                productosOrigenPromocion: {}
            });
        default:
            return state
    }
}


function allProductosOrigenPromocion(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTOSORIGENPROMOCION:
            return action.productosOrigenPromocion.result && action.productosOrigenPromocion.result.productosOrigenPromocion ? union(action.productosOrigenPromocion.result.productosOrigenPromocion, state) : (action.productosOrigenPromocion.result ? action.productosOrigenPromocion.result : state) ;
        case RECEIVE_PRODUCTOORIGENPROMOCION:
                return action.productoOrigenPromocion.result ? union([action.productoOrigenPromocion.result], state) : state;
        
        case SUCCESS_CREATE_PRODUCTOORIGENPROMOCION:
                   let datoProductoOrigenPromocionSCreate = action.productoOrigenPromocion.entities.productosOrigenPromocion;
                   let idNuevoSCreate = null;
                   if (Object.values(datoProductoOrigenPromocionSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoProductoOrigenPromocionSCreate)[0] && Object.values(datoProductoOrigenPromocionSCreate)[0].id ? Object.values(datoProductoOrigenPromocionSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PRODUCTOSORIGENPROMOCION:
                   let productosOrigenPromocionCreate = action.productosOrigenPromocion.entities && action.productosOrigenPromocion.entities.productosOrigenPromocion ? action.productosOrigenPromocion.entities.productosOrigenPromocion : null;
                   return productosOrigenPromocionCreate ?
                       union(state, Object.values(productosOrigenPromocionCreate).map((productosOrigenPromocion) => {
                           return productosOrigenPromocion.id
                       })) : state;
        case RESET_PRODUCTOSORIGENPROMOCION:
            return [];

            case RECEIVE_PROMOCION:
    let promocion = action.promocion.entities && action.promocion.entities.productosOrigenPromocion ? action.promocion.entities.productosOrigenPromocion : null ;
    return promocion ?
        union(state, Object.values(promocion).map((promocion) => {
            return promocion.id
        })) : state;
case RECEIVE_PROMOCIONES:
    let promociones = action.promociones.entities && action.promociones.entities.productosOrigenPromocion ? action.promociones.entities.productosOrigenPromocion : null;
    return promociones ?
        union(state, Object.values(promociones).map((promociones) => {
            return promociones.id
        })) : state;

case SUCCESS_DELETE_PROMOCION:
    let promocionDelete = action.promocion.entities && action.promocion.entities.productosOrigenPromocion ? action.promocion.entities.productosOrigenPromocion : null ;
        return promocionDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionDelete).map((promocion) => {
                return promocion.id
            })) : state;
case SUCCESS_CREATE_PROMOCION:
   let promocionCreate = action.promocion.entities && action.promocion.entities.productosOrigenPromocion ? action.promocion.entities.productosOrigenPromocion : null ;
       return promocionCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionCreate).map((promocion) => {
               return promocion.id
           })) : state;
case SUCCESS_UPDATE_PROMOCION:
    let promocionUpdate = action.promocion.entities && action.promocion.entities.productosOrigenPromocion ? action.promocion.entities.productosOrigenPromocion : null ;
        return promocionUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionUpdate).map((promocion) => {
                return promocion.id
            })) : state;
 case SUCCESS_UPDATE_PROMOCIONES:
     let promocionesUpdate = action.promociones.entities && action.promociones.entities.productosOrigenPromocion ? action.promociones.entities.productosOrigenPromocion : null;
        return promocionesUpdate ?
            union(state, Object.values(promocionesUpdate).map((promociones) => {
                return promociones.id
            })) : state;case RECEIVE_PRODUCTO:
    let producto = action.producto.entities && action.producto.entities.productosOrigenPromocion ? action.producto.entities.productosOrigenPromocion : null ;
    return producto ?
        union(state, Object.values(producto).map((producto) => {
            return producto.id
        })) : state;
case RECEIVE_PRODUCTOS:
    let productos = action.productos.entities && action.productos.entities.productosOrigenPromocion ? action.productos.entities.productosOrigenPromocion : null;
    return productos ?
        union(state, Object.values(productos).map((productos) => {
            return productos.id
        })) : state;

case SUCCESS_DELETE_PRODUCTO:
    let productoDelete = action.producto.entities && action.producto.entities.productosOrigenPromocion ? action.producto.entities.productosOrigenPromocion : null ;
        return productoDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productoDelete).map((producto) => {
                return producto.id
            })) : state;
case SUCCESS_CREATE_PRODUCTO:
   let productoCreate = action.producto.entities && action.producto.entities.productosOrigenPromocion ? action.producto.entities.productosOrigenPromocion : null ;
       return productoCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productoCreate).map((producto) => {
               return producto.id
           })) : state;
case SUCCESS_UPDATE_PRODUCTO:
    let productoUpdate = action.producto.entities && action.producto.entities.productosOrigenPromocion ? action.producto.entities.productosOrigenPromocion : null ;
        return productoUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(productoUpdate).map((producto) => {
                return producto.id
            })) : state;
 case SUCCESS_UPDATE_PRODUCTOS:
     let productosUpdate = action.productos.entities && action.productos.entities.productosOrigenPromocion ? action.productos.entities.productosOrigenPromocion : null;
        return productosUpdate ?
            union(state, Object.values(productosUpdate).map((productos) => {
                return productos.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalProductosOrigenPromocion(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTOSORIGENPROMOCION:
            return action.productosOrigenPromocion && action.productosOrigenPromocion.result.total ? action.productosOrigenPromocion.result.total : 0;
        case RESET_PRODUCTOSORIGENPROMOCION:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTOORIGENPROMOCION:
            let dato = action.productoOrigenPromocion.entities.productosOrigenPromocion;
            let productoOrigenPromocion = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: productoOrigenPromocion ? productoOrigenPromocion : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PRODUCTOORIGENPROMOCION:
            let idsUpdate = [];
            Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionUpdate) => {
                if (productoOrigenPromocionUpdate && productoOrigenPromocionUpdate.id)
                    idsUpdate.push(productoOrigenPromocionUpdate.id);
            });
            return merge({}, state, {
                activo: action.productoOrigenPromocion,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PRODUCTOORIGENPROMOCION:
            let datoProductoOrigenPromocionActualizado = {};
            if (Object.values(action.productoOrigenPromocion.entities.productosOrigenPromocion).length > 0)
                datoProductoOrigenPromocionActualizado = Object.values(action.productoOrigenPromocion.entities.productosOrigenPromocion)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoProductoOrigenPromocionActualizado
            });
        case ERROR_UPDATE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PRODUCTOSORIGENPROMOCION:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PRODUCTOSORIGENPROMOCION:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PRODUCTOSORIGENPROMOCION:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });//PRODUCTO
 case SUCCESS_CREATE_PRODUCTO:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PRODUCTO:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_DELETE_PRODUCTO:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PRODUCTOS:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });

        case DELETE_PRODUCTOORIGENPROMOCION:
            let datoProductoOrigenPromocionDelete = action.productoOrigenPromocion;
            let idsDelete = [];
           Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionDelete) => {
               if (productoOrigenPromocionDelete && productoOrigenPromocionDelete.id)
                   idsDelete.push(productoOrigenPromocionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoProductoOrigenPromocionDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PRODUCTOORIGENPROMOCION:
                       let datoProductoOrigenPromocionDeleteUpdate = action.productoOrigenPromocion;
                       let idsDeleteUpdate = [];
                      Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionDelete) => {
                          if (productoOrigenPromocionDelete && productoOrigenPromocionDelete.id)
                              idsDeleteUpdate.push(productoOrigenPromocionDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoProductoOrigenPromocionDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PRODUCTOORIGENPROMOCION:
                    let datoProductoOrigenPromocionDeleted = {};
                    if (Object.values(action.productoOrigenPromocion.entities.productosOrigenPromocion).length > 0)
                        datoProductoOrigenPromocionDeleted = Object.values(action.productoOrigenPromocion.entities.productosOrigenPromocion)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoProductoOrigenPromocionDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PRODUCTOORIGENPROMOCION:
             let idsCreate = [];
             Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionCreate) => {
                 if (productoOrigenPromocionCreate && productoOrigenPromocionCreate.id)
                     idsCreate.push(productoOrigenPromocionCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.productoOrigenPromocion,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PRODUCTOORIGENPROMOCION:
            let datoProductoOrigenPromocionNuevo = {};
            if (Object.values(action.productoOrigenPromocion.entities.productosOrigenPromocion).length > 0)
                datoProductoOrigenPromocionNuevo = Object.values(action.productoOrigenPromocion.entities.productosOrigenPromocion)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoProductoOrigenPromocionNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PRODUCTOSORIGENPROMOCION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PRODUCTOSORIGENPROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTOSORIGENPROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
//PRODUCTO
 case SUCCESS_CREATE_PRODUCTO:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTO:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PRODUCTO:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PRODUCTOS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_PRODUCTOORIGENPROMOCION:
           let datoProductoOrigenPromocionDelete = action.productoOrigenPromocion;
           let idsDelete = [];
           Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionDelete) => {
               if (productoOrigenPromocionDelete && productoOrigenPromocionDelete.id)
                   idsDelete.push(productoOrigenPromocionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoProductoOrigenPromocionDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PRODUCTOORIGENPROMOCION:
                  let datoProductoOrigenPromocionDeleteCreate = action.productoOrigenPromocion;
                  let idsDeleteCreate = [];
                  Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionDelete) => {
                      if (productoOrigenPromocionDelete && productoOrigenPromocionDelete.id)
                          idsDeleteCreate.push(productoOrigenPromocionDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoProductoOrigenPromocionDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PRODUCTOORIGENPROMOCION:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.productoOrigenPromocion,
                error: null,
            });
        case REQUEST_DELETE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            eliminado: {},
        });//PRODUCTO
 case SUCCESS_CREATE_PRODUCTO:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTO:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PRODUCTO:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PRODUCTOS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PRODUCTOORIGENPROMOCION:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const productosOrigenPromocion = combineReducers({
    byId: productosOrigenPromocionById,
    allIds: allProductosOrigenPromocion,
    update: update,
    create: create,
    totalProductosOrigenPromocion: totalProductosOrigenPromocion,
    delete: deleter,
    print: print
});

export default productosOrigenPromocion;