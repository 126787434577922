//api
import zonasEntregaApi from "../api/zonasEntregaApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeZonasEntrega";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//ZONASENTREGA
export const REQUEST_ZONASENTREGA = 'REQUEST_ZONASENTREGA';
export const RECEIVE_ZONASENTREGA = 'RECEIVE_ZONASENTREGA';
export const INVALIDATE_ZONASENTREGA = 'INVALIDATE_ZONASENTREGA';
export const ERROR_ZONASENTREGA = "ERROR_ZONASENTREGA";
export const RESET_ZONASENTREGA = "RESET_ZONASENTREGA";


export function invalidateZonasEntrega() {
    return {
        type: INVALIDATE_ZONASENTREGA
    }
}

function requestZonasEntrega() {
    return {
        type: REQUEST_ZONASENTREGA,
    }
}

function receiveZonasEntrega(json) {
    return {
        type: RECEIVE_ZONASENTREGA,
    zonasEntrega: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorZonasEntrega(error) {
    return {
        type: ERROR_ZONASENTREGA,
        error: error,
    }
}

export function resetZonasEntrega() {
    return {
        type: RESET_ZONASENTREGA
    }
}

export function fetchZonasEntrega(filtros) {
    return dispatch => {
        dispatch(requestZonasEntrega());
        return zonasEntregaApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveZonasEntrega(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorZonasEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorZonasEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchZonasEntrega(state) {
    const zonasEntrega = state.zonasEntrega.byId;
    if (!zonasEntrega) {
        return true
    } else if (zonasEntrega.isFetching) {
        return false
    } else {
        return zonasEntrega.didInvalidate;
    }
}

export function fetchZonasEntregaIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchZonasEntrega(getState())) {
            return dispatch(fetchZonasEntrega(filtros))
        }
    }
}


//MODEL
export const REQUEST_ZONAENTREGA = 'REQUEST_ZONAENTREGA';
export const RECEIVE_ZONAENTREGA = 'RECEIVE_ZONAENTREGA';
export const INVALIDATE_ZONAENTREGA = 'INVALIDATE_ZONAENTREGA';
export const ERROR_ZONAENTREGA = "ERROR_ZONAENTREGA";
export const RESET_ZONAENTREGA = "RESET_ZONAENTREGA";


export function invalidateZonaEntrega() {
    return {
        type: INVALIDATE_ZONAENTREGA
    }
}

function requestZonaEntrega() {
    return {
        type: REQUEST_ZONAENTREGA,
    }
}

export function receiveZonaEntrega(json) {
    return {
        type: RECEIVE_ZONAENTREGA,
    zonaEntrega: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorZonaEntrega(error) {
    return {
        type: ERROR_ZONAENTREGA,
        error: error,
    }
}

export function fetchZonaEntrega(idZonaEntrega) {
    return dispatch => {
        dispatch(requestZonaEntrega());
        return zonasEntregaApi.getOne(idZonaEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveZonaEntrega(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorZonaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorZonaEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_ZONAENTREGA = 'UPDATE_ZONAENTREGA';
export const REQUEST_UPDATE_ZONAENTREGA = "REQUEST_UPDATE_ZONAENTREGA";
export const SUCCESS_UPDATE_ZONAENTREGA = "SUCCESS_UPDATE_ZONAENTREGA";
export const ERROR_UPDATE_ZONAENTREGA = "ERROR_UPDATE_ZONAENTREGA";
export const RESET_UPDATE_ZONAENTREGA = "RESET_UPDATE_ZONAENTREGA";
export const DELETE_UPDATE_ZONAENTREGA = "DELETE_UPDATE_ZONAENTREGA";

function requestUpdateZonaEntrega() {
    return {
        type: REQUEST_UPDATE_ZONAENTREGA,
    }
}

function receiveUpdateZonaEntrega(zonaEntrega) {
    return {
        type: SUCCESS_UPDATE_ZONAENTREGA,
        receivedAt: Date.now(),
        zonaEntrega: normalizeDato(zonaEntrega)
    }
}

function errorUpdateZonaEntrega(error) {
    return {
        type: ERROR_UPDATE_ZONAENTREGA,
        error: error,
    }
}

export function resetUpdateZonaEntrega() {
    return {
        type: RESET_UPDATE_ZONAENTREGA,
    }
}

export function updateZonaEntrega(zonaEntrega) {
    return {
        type: UPDATE_ZONAENTREGA,
        zonaEntrega
    }
}

export function saveUpdateZonaEntrega() {
    return (dispatch, getState) => {
        dispatch(requestUpdateZonaEntrega());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let zonaEntrega = denormalizeDato(getState().zonasEntrega.update.activo, store);

        return zonasEntregaApi.saveUpdate(zonaEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateZonaEntrega(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateZonaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateZonasEntrega(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateZonasEntrega(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateZonaEntrega(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateZonaEntrega(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateZonaEntrega(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateZonaEntrega(zonaEntrega) {
    return {
        type: DELETE_UPDATE_ZONAENTREGA,
        zonaEntrega
    }
}

//UPDATE ZONASENTREGA
export const REQUEST_UPDATE_ZONASENTREGA = "REQUEST_UPDATE_ZONASENTREGA";
export const SUCCESS_UPDATE_ZONASENTREGA = "SUCCESS_UPDATE_ZONASENTREGA";
export const ERROR_UPDATE_ZONASENTREGA = "ERROR_UPDATE_ZONASENTREGA";
export const RESET_UPDATE_ZONASENTREGA = "RESET_UPDATE_ZONASENTREGA";

function requestUpdateZonasEntrega() {
    return {
        type: REQUEST_UPDATE_ZONASENTREGA,
}
}

function receiveUpdateZonasEntrega(zonasEntrega) {
    return {
        type: SUCCESS_UPDATE_ZONASENTREGA,
    receivedAt: Date.now(),
        zonasEntrega: normalizeDatos(zonasEntrega)
}
}

function errorUpdateZonasEntrega(error) {
    return {
        type: ERROR_UPDATE_ZONASENTREGA,
    error: error,
}
}

export function resetUpdateZonasEntrega() {
    return {
        type: RESET_UPDATE_ZONASENTREGA,
}
}

export function saveUpdateZonasEntrega() {
    return (dispatch, getState) => {
        dispatch(requestUpdateZonasEntrega());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let zonasEntrega = getState().zonasEntrega.update.activos.map((idZonaEntrega) => {
            return denormalizeDato(getState().zonasEntrega.update.activo[idZonaEntrega], store);
        });

        return zonasEntregaApi.saveUpdateZonasEntrega(zonasEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateZonasEntrega(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateZonasEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateZonasEntrega(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateZonasEntrega(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateZonasEntrega(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateZonasEntrega(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA ZONAENTREGA
export const CREATE_ZONAENTREGA = 'CREATE_ZONAENTREGA';
export const REQUEST_CREATE_ZONAENTREGA = "REQUEST_CREATE_ZONAENTREGA";
export const SUCCESS_CREATE_ZONAENTREGA = "SUCCESS_CREATE_ZONAENTREGA";
export const ERROR_CREATE_ZONAENTREGA = "ERROR_CREATE_ZONAENTREGA";
export const RESET_CREATE_ZONAENTREGA = "RESET_CREATE_ZONAENTREGA";
export const DELETE_CREATE_ZONAENTREGA = "DELETE_CREATE_ZONAENTREGA";


//ALTA ZONAENTREGA
function requestCreateZonaEntrega() {
    return {
        type: REQUEST_CREATE_ZONAENTREGA,
    }
}

function receiveCreateZonaEntrega(zonaEntrega) {
    return {
        type: SUCCESS_CREATE_ZONAENTREGA,
        receivedAt: Date.now(),
        zonaEntrega: normalizeDato(zonaEntrega)
    }
}

export function errorCreateZonaEntrega(error) {
    return {
        type: ERROR_CREATE_ZONAENTREGA,
        error: error,
    }
}

export function resetCreateZonaEntrega() {
    return {
        type: RESET_CREATE_ZONAENTREGA
    }
}

export function createZonaEntrega(zonaEntrega) {
    return {
        type: CREATE_ZONAENTREGA,
        zonaEntrega
    }
}

export function deleteCreateZonaEntrega(zonaEntrega) {
    return {
        type: DELETE_CREATE_ZONAENTREGA,
        zonaEntrega
    }
}

export function saveCreateZonaEntrega() {
    return (dispatch, getState) => {
        dispatch(requestCreateZonaEntrega());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let zonaEntrega = denormalizeDato(getState().zonasEntrega.create.nuevo, store);

        return zonasEntregaApi.saveCreate(zonaEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateZonaEntrega(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateZonaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateZonasEntrega(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateZonasEntrega(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateZonaEntrega(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateZonaEntrega(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateZonaEntrega(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE ZONASENTREGA
export const REQUEST_CREATE_ZONASENTREGA = "REQUEST_CREATE_ZONASENTREGA";
export const SUCCESS_CREATE_ZONASENTREGA = "SUCCESS_CREATE_ZONASENTREGA";
export const ERROR_CREATE_ZONASENTREGA = "ERROR_CREATE_ZONASENTREGA";
export const RESET_CREATE_ZONASENTREGA = "RESET_CREATE_ZONASENTREGA";

function requestCreateZonasEntrega() {
    return {
        type: REQUEST_CREATE_ZONASENTREGA,
}
}

function receiveCreateZonasEntrega(zonasEntrega) {
    return {
        type: SUCCESS_CREATE_ZONASENTREGA,
    receivedAt: Date.now(),
        zonasEntrega: normalizeDatos(zonasEntrega)
}
}

function errorCreateZonasEntrega(error) {
    return {
        type: ERROR_CREATE_ZONASENTREGA,
    error: error,
}
}

export function resetCreateZonasEntrega() {
    return {
        type: RESET_CREATE_ZONASENTREGA,
}
}

export function saveCreateZonasEntrega() {
    return (dispatch, getState) => {
        dispatch(requestCreateZonasEntrega());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let zonasEntrega = getState().zonasEntrega.create.nuevos.map((idZonaEntrega) => {
            return denormalizeDato(getState().zonasEntrega.create.nuevo[idZonaEntrega], store);
        });

        return zonasEntregaApi.saveCreateZonasEntrega(zonasEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateZonasEntrega(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateZonasEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateZonasEntrega(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateZonasEntrega(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateZonasEntrega(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateZonasEntrega(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE ZONAENTREGA
export const DELETE_ZONAENTREGA = 'DELETE_ZONAENTREGA';
export const REQUEST_DELETE_ZONAENTREGA = "REQUEST_DELETE_ZONAENTREGA";
export const SUCCESS_DELETE_ZONAENTREGA = "SUCCESS_DELETE_ZONAENTREGA";
export const ERROR_DELETE_ZONAENTREGA = "ERROR_DELETE_ZONAENTREGA";
export const RESET_DELETE_ZONAENTREGA = "RESET_DELETE_ZONAENTREGA";

function requestDeleteZonaEntrega() {
    return {
        type: REQUEST_DELETE_ZONAENTREGA,
    }
}

function receiveDeleteZonaEntrega(zonaEntrega) {
    return {
        type: SUCCESS_DELETE_ZONAENTREGA,
        receivedAt: Date.now(),
        zonaEntrega: normalizeDato(zonaEntrega)
    }
}

function errorDeleteZonaEntrega(error) {
    return {
        type: ERROR_DELETE_ZONAENTREGA,
        error: error,
    }
}

export function resetDeleteZonaEntrega(error) {
    return {
        type: RESET_DELETE_ZONAENTREGA,
        error: error,
    }
}

export function deleteZonaEntrega(zonaEntrega) {
    return {
        type: DELETE_ZONAENTREGA,
        zonaEntrega
    }
}

export function saveDeleteZonaEntrega(zonaEntrega) {
    return dispatch => {
        dispatch(requestDeleteZonaEntrega());
        return zonasEntregaApi.saveDelete(zonaEntrega)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteZonaEntrega());
                            dispatch(receiveDeleteZonaEntrega(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteZonaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteZonaEntrega(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteZonaEntrega(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteZonaEntrega(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteZonaEntrega(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT ZONAENTREGA
export const REQUEST_PRINT_ZONAENTREGA = "REQUEST_PRINT_ZONAENTREGA";
export const SUCCESS_PRINT_ZONAENTREGA = "SUCCESS_PRINT_ZONAENTREGA";
export const ERROR_PRINT_ZONAENTREGA = "ERROR_PRINT_ZONAENTREGA";
export const RESET_PRINT_ZONAENTREGA = "RESET_PRINT_ZONAENTREGA";

function requestPrintZonaEntrega() {
    return {
        type: REQUEST_PRINT_ZONAENTREGA,
    }
}

function receivePrintZonaEntrega(turnos) {
    return {
        type: SUCCESS_PRINT_ZONAENTREGA,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintZonaEntrega(error) {
    return {
        type: ERROR_PRINT_ZONAENTREGA,
        error: error,
    }
}

export function resetPrintZonaEntrega() {
    return {
        type: RESET_PRINT_ZONAENTREGA,
    }
}

export function printZonaEntrega(idZonaEntrega) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintZonaEntrega());
        return zonasEntregaApi.printZonaEntrega(idZonaEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintZonaEntrega(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintZonaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintZonaEntrega(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintZonaEntrega(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintZonaEntrega(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}