//api
import contactosApi from "../api/contactosApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeContactos";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//CONTACTOS
export const REQUEST_CONTACTOS = 'REQUEST_CONTACTOS';
export const RECEIVE_CONTACTOS = 'RECEIVE_CONTACTOS';
export const INVALIDATE_CONTACTOS = 'INVALIDATE_CONTACTOS';
export const ERROR_CONTACTOS = "ERROR_CONTACTOS";
export const RESET_CONTACTOS = "RESET_CONTACTOS";


export function invalidateContactos() {
    return {
        type: INVALIDATE_CONTACTOS
    }
}

function requestContactos() {
    return {
        type: REQUEST_CONTACTOS,
    }
}

function receiveContactos(json) {
    return {
        type: RECEIVE_CONTACTOS,
    contactos: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorContactos(error) {
    return {
        type: ERROR_CONTACTOS,
        error: error,
    }
}

export function resetContactos() {
    return {
        type: RESET_CONTACTOS
    }
}

export function fetchContactos(filtros) {
    return dispatch => {
        dispatch(requestContactos());
        return contactosApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveContactos(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorContactos(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorContactos(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchContactos(state) {
    const contactos = state.contactos.byId;
    if (!contactos) {
        return true
    } else if (contactos.isFetching) {
        return false
    } else {
        return contactos.didInvalidate;
    }
}

export function fetchContactosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchContactos(getState())) {
            return dispatch(fetchContactos(filtros))
        }
    }
}


//MODEL
export const REQUEST_CONTACTO = 'REQUEST_CONTACTO';
export const RECEIVE_CONTACTO = 'RECEIVE_CONTACTO';
export const INVALIDATE_CONTACTO = 'INVALIDATE_CONTACTO';
export const ERROR_CONTACTO = "ERROR_CONTACTO";
export const RESET_CONTACTO = "RESET_CONTACTO";


export function invalidateContacto() {
    return {
        type: INVALIDATE_CONTACTO
    }
}

function requestContacto() {
    return {
        type: REQUEST_CONTACTO,
    }
}

export function receiveContacto(json) {
    return {
        type: RECEIVE_CONTACTO,
    contacto: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorContacto(error) {
    return {
        type: ERROR_CONTACTO,
        error: error,
    }
}

export function fetchContacto(idContacto) {
    return dispatch => {
        dispatch(requestContacto());
        return contactosApi.getOne(idContacto)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveContacto(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorContacto(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorContacto(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_CONTACTO = 'UPDATE_CONTACTO';
export const REQUEST_UPDATE_CONTACTO = "REQUEST_UPDATE_CONTACTO";
export const SUCCESS_UPDATE_CONTACTO = "SUCCESS_UPDATE_CONTACTO";
export const ERROR_UPDATE_CONTACTO = "ERROR_UPDATE_CONTACTO";
export const RESET_UPDATE_CONTACTO = "RESET_UPDATE_CONTACTO";
export const DELETE_UPDATE_CONTACTO = "DELETE_UPDATE_CONTACTO";

function requestUpdateContacto() {
    return {
        type: REQUEST_UPDATE_CONTACTO,
    }
}

function receiveUpdateContacto(contacto) {
    return {
        type: SUCCESS_UPDATE_CONTACTO,
        receivedAt: Date.now(),
        contacto: normalizeDato(contacto)
    }
}

function errorUpdateContacto(error) {
    return {
        type: ERROR_UPDATE_CONTACTO,
        error: error,
    }
}

export function resetUpdateContacto() {
    return {
        type: RESET_UPDATE_CONTACTO,
    }
}

export function updateContacto(contacto) {
    return {
        type: UPDATE_CONTACTO,
        contacto
    }
}

export function saveUpdateContacto(files) {
    return (dispatch, getState) => {
        dispatch(requestUpdateContacto());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let contacto = denormalizeDato(getState().contactos.update.activo, store);

        return contactosApi.saveUpdate(contacto, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveUpdateContacto(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateContacto(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateContactos(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateContactos(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateContacto(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                    dispatch(receiveUpdateContacto(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateContacto(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateContacto(contacto) {
    return {
        type: DELETE_UPDATE_CONTACTO,
        contacto
    }
}

//UPDATE CONTACTOS
export const REQUEST_UPDATE_CONTACTOS = "REQUEST_UPDATE_CONTACTOS";
export const SUCCESS_UPDATE_CONTACTOS = "SUCCESS_UPDATE_CONTACTOS";
export const ERROR_UPDATE_CONTACTOS = "ERROR_UPDATE_CONTACTOS";
export const RESET_UPDATE_CONTACTOS = "RESET_UPDATE_CONTACTOS";

function requestUpdateContactos() {
    return {
        type: REQUEST_UPDATE_CONTACTOS,
}
}

function receiveUpdateContactos(contactos) {
    return {
        type: SUCCESS_UPDATE_CONTACTOS,
    receivedAt: Date.now(),
        contactos: normalizeDatos(contactos)
}
}

function errorUpdateContactos(error) {
    return {
        type: ERROR_UPDATE_CONTACTOS,
    error: error,
}
}

export function resetUpdateContactos() {
    return {
        type: RESET_UPDATE_CONTACTOS,
}
}

export function saveUpdateContactos() {
    return (dispatch, getState) => {
        dispatch(requestUpdateContactos());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let contactos = getState().contactos.update.activos.map((idContacto) => {
            return denormalizeDato(getState().contactos.update.activo[idContacto], store);
        });

        return contactosApi.saveUpdateContactos(contactos)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateContactos(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateContactos(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateContactos(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateContactos(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateContactos(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateContactos(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA CONTACTO
export const CREATE_CONTACTO = 'CREATE_CONTACTO';
export const REQUEST_CREATE_CONTACTO = "REQUEST_CREATE_CONTACTO";
export const SUCCESS_CREATE_CONTACTO = "SUCCESS_CREATE_CONTACTO";
export const ERROR_CREATE_CONTACTO = "ERROR_CREATE_CONTACTO";
export const RESET_CREATE_CONTACTO = "RESET_CREATE_CONTACTO";
export const DELETE_CREATE_CONTACTO = "DELETE_CREATE_CONTACTO";


//ALTA CONTACTO
function requestCreateContacto() {
    return {
        type: REQUEST_CREATE_CONTACTO,
    }
}

function receiveCreateContacto(contacto) {
    return {
        type: SUCCESS_CREATE_CONTACTO,
        receivedAt: Date.now(),
        contacto: normalizeDato(contacto)
    }
}

export function errorCreateContacto(error) {
    return {
        type: ERROR_CREATE_CONTACTO,
        error: error,
    }
}

export function resetCreateContacto() {
    return {
        type: RESET_CREATE_CONTACTO
    }
}

export function createContacto(contacto) {
    return {
        type: CREATE_CONTACTO,
        contacto
    }
}

export function deleteCreateContacto(contacto) {
    return {
        type: DELETE_CREATE_CONTACTO,
        contacto
    }
}

export function saveCreateContacto(files) {
    return (dispatch, getState) => {
        dispatch(requestCreateContacto());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let contacto = denormalizeDato(getState().contactos.create.nuevo, store);

        return contactosApi.saveCreate(contacto, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveCreateContacto(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateContacto(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateContactos(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateContactos(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateContacto(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateContacto(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateContacto(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE CONTACTOS
export const REQUEST_CREATE_CONTACTOS = "REQUEST_CREATE_CONTACTOS";
export const SUCCESS_CREATE_CONTACTOS = "SUCCESS_CREATE_CONTACTOS";
export const ERROR_CREATE_CONTACTOS = "ERROR_CREATE_CONTACTOS";
export const RESET_CREATE_CONTACTOS = "RESET_CREATE_CONTACTOS";

function requestCreateContactos() {
    return {
        type: REQUEST_CREATE_CONTACTOS,
}
}

function receiveCreateContactos(contactos) {
    return {
        type: SUCCESS_CREATE_CONTACTOS,
    receivedAt: Date.now(),
        contactos: normalizeDatos(contactos)
}
}

function errorCreateContactos(error) {
    return {
        type: ERROR_CREATE_CONTACTOS,
    error: error,
}
}

export function resetCreateContactos() {
    return {
        type: RESET_CREATE_CONTACTOS,
}
}

export function saveCreateContactos() {
    return (dispatch, getState) => {
        dispatch(requestCreateContactos());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let contactos = getState().contactos.create.nuevos.map((idContacto) => {
            return denormalizeDato(getState().contactos.create.nuevo[idContacto], store);
        });

        return contactosApi.saveCreateContactos(contactos)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateContactos(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateContactos(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateContactos(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateContactos(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateContactos(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateContactos(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE CONTACTO
export const DELETE_CONTACTO = 'DELETE_CONTACTO';
export const REQUEST_DELETE_CONTACTO = "REQUEST_DELETE_CONTACTO";
export const SUCCESS_DELETE_CONTACTO = "SUCCESS_DELETE_CONTACTO";
export const ERROR_DELETE_CONTACTO = "ERROR_DELETE_CONTACTO";
export const RESET_DELETE_CONTACTO = "RESET_DELETE_CONTACTO";

function requestDeleteContacto() {
    return {
        type: REQUEST_DELETE_CONTACTO,
    }
}

function receiveDeleteContacto(contacto) {
    return {
        type: SUCCESS_DELETE_CONTACTO,
        receivedAt: Date.now(),
        contacto: normalizeDato(contacto)
    }
}

function errorDeleteContacto(error) {
    return {
        type: ERROR_DELETE_CONTACTO,
        error: error,
    }
}

export function resetDeleteContacto(error) {
    return {
        type: RESET_DELETE_CONTACTO,
        error: error,
    }
}

export function deleteContacto(contacto) {
    return {
        type: DELETE_CONTACTO,
        contacto
    }
}

export function saveDeleteContacto(contacto) {
    return dispatch => {
        dispatch(requestDeleteContacto());
        return contactosApi.saveDelete(contacto)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteContacto());
                            dispatch(receiveDeleteContacto(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteContacto(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteContacto(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteContacto(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteContacto(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteContacto(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT CONTACTO
export const REQUEST_PRINT_CONTACTO = "REQUEST_PRINT_CONTACTO";
export const SUCCESS_PRINT_CONTACTO = "SUCCESS_PRINT_CONTACTO";
export const ERROR_PRINT_CONTACTO = "ERROR_PRINT_CONTACTO";
export const RESET_PRINT_CONTACTO = "RESET_PRINT_CONTACTO";

function requestPrintContacto() {
    return {
        type: REQUEST_PRINT_CONTACTO,
    }
}

function receivePrintContacto(turnos) {
    return {
        type: SUCCESS_PRINT_CONTACTO,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintContacto(error) {
    return {
        type: ERROR_PRINT_CONTACTO,
        error: error,
    }
}

export function resetPrintContacto() {
    return {
        type: RESET_PRINT_CONTACTO,
    }
}

export function printContacto(idContacto) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintContacto());
        return contactosApi.printContacto(idContacto)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintContacto(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintContacto(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintContacto(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintContacto(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintContacto(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}