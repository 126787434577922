import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import { makeStyles } from "@material-ui/core/styles";
import ContainerLimit from "../ContainerLimit";

const useStyles = makeStyles((theme) => ({
    carrouselDestacados: {
        "& .swiper-container-horizontal > .swiper-scrollbar": {
            width: "100px !important",
            top: "0px !important",
            right: "8px !important",
            zIndex: "50 !important",
            height: "3px !important",
            left: "inherit !important",
            opacity: 0,
        },
    },
    carrouselButtons: {
        position: "absolute",
        left: "-16px",
        top: "49%",
        width: "104%",
        margin: "0 auto",
        // display: "none",
        "&:hover": {
            display: "block",
        },
        "& .swiper-button-prev, & .swiper-container-rtl .swiper-button-next": {
            left: "-4px",
        },
        "& .swiper-button-prev:after, &.swiper-container-rtl .swiper-button-next:after": {
            fontSize: "24px",
            color: theme.palette.primary[theme.palette.type],
        },
        "& .swiper-button-next:after, & .swiper-container-rtl .swiper-button-prev:after": {
            fontSize: "24px",
            color: theme.palette.primary[theme.palette.type],
        },
        "& .swiper-button-prev, .swiper-container-rtl .swiper-button-next, & .swiper-button-next, & .swiper-container-rtl .swiper-button-prev": {
            width: "54px",
            height: "54px",
            backgroundColor: "#fff",
            borderRadius: "100%",
            borderWidth: 0,
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, .19)",
            cursor: "pointer",
            outline: 0,
            zIndex: 2,
        },
    },
}));

export default function CarrouselDestacados(props) {
    const [swiper, updateSwiper] = useState(null);
    const [isBegginig, updateIsBeggining] = useState(null);
    const [isEnd, updateIsEnd] = useState(null);
    const classes = useStyles();

    // Swiper instance
    useEffect(
        () => {
            if (swiper !== null) {
                updateIsBeggining(swiper.isBeginning);
                updateIsEnd(swiper.isEnd);
                swiper.on("slideChange", () => {
                    updateIsBeggining(swiper.isBeginning);
                    updateIsEnd(swiper.isEnd);
                });
            }
        },
        [swiper && (swiper.isEnd || swiper.isBeggining)]
    );

    const params = {
        mousewheel: {
            invert: false,
            forceToAxis: true
        },
        speed: 200,
        freeMode: true,
        autoWidth: true,
        slidesPerView: 'auto',
        slidesPerGroup: 2,
        normalizeSlideIndex: false,
        getSwiper: updateSwiper, // Get swiper instance callback
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
        },
        // renderScrollbar: () => <div id={"carrousel-scrollbar-" + props.idCategoria} className="swiper-scrollbar"></div>
    };

    // Manipulate swiper from outside
    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    function toggleScrollBar(id) {
        let scrollbar = document.getElementById("carrousel-scrollbar-" + id);
        if (scrollbar)
            scrollbar.classList.contains('show') ? scrollbar.classList.remove('show') : scrollbar.classList.add('show');
    };

    return (
        <>
            <ContainerLimit className={classes.carrouselDestacados}>
                <Swiper {...params} shouldSwiperUpdate updateOnWindowResize>
                    {props.children}
                </Swiper>
            </ContainerLimit>
            {/*<div className={classes.carrouselButtons}*/}
            {/*    // onMouseOver={() => toggleScrollBar(props.idCategoria)}*/}
            {/*    // onMouseOut={() => toggleScrollBar(props.idCategoria)}*/}
            {/*>*/}
            {/*    <div className={`swiper-button-prev ${isBegginig && "swiper-button-disabled"}`} onClick={goPrev}/>*/}
            {/*    <div className={`swiper-button-next ${isEnd && "swiper-button-disabled"}`} onClick={goNext}/>*/}
            {/*</div>*/}
        </>
    );
}

