import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
//Components
import { Typography, Box, Button, TextField, MenuItem, CircularProgress } from "@material-ui/core";
import Line from "../../customComponents/Line";
import ContainerLimit from "../../customComponents/ContainerLimit";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { createContacto, saveCreateContacto } from "../../actions/ContactoActions";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#003462",
        marginTop: "-200px",
        padding: "0 16px",
        paddingTop: "200px",
        paddingBottom: "100px",
    },
    trabajaConNosotros: {
        width: "20%",
        marginRight: "16px",
        [theme.breakpoints.down(960)]: {
            width: "100%",
            paddingTop: "100px",
        },
    },
    formContacto: {
        width: "80%",
        borderLeft: "1px solid #59c4c6",
        paddingLeft: "20px",
        [theme.breakpoints.down(960)]: {
            width: "100%",
            borderLeft: "none",
            paddingLeft: 0
        },
        "& .input-contacto": {
            backgroundColor: "#004074",
            borderRadius: "4px",
            marginTop: "6px",
            marginBottom: "6px",
            "& .MuiInputBase-input": {
                color: "white",
            },
            "& .MuiFormLabel-root": {
                color: "rgba(255,255,255,0.5)",
            },
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'rgba(89,196,198,0.51)',
            },
            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                color: "#59c4c6"
            }
        },
        "& .input-contacto:first-child:not(.containerInputsRow .input-contacto)": {
            marginTop: 0
        },
        "& .containerInputsRow": {
            display: "flex",
            [theme.breakpoints.down(960)]: {
                flexWrap: "wrap",
            },
        },
        "& .containerInputsRow .input-contacto:first-child": {
            marginRight: "6px",
            [theme.breakpoints.down(960)]: {
                marginRight: 0,
            },
        },
        "& .containerInputsRow .input-contacto:last-child": {
            marginLeft: "6px",
            [theme.breakpoints.down(960)]: {
                marginLeft: 0,
            },
        }
    },
    containerContacto: {
        [theme.breakpoints.down(960)]: {
            flexWrap: "wrap",
            flexDirection: "column-reverse",
        },
    },
    containerTitulo: {
        textAlign: "center",
        marginBottom: "100px",
        [theme.breakpoints.down(960)]: {
            marginBottom: "60px",
            paddingTop: "35px"
        },
    },
    fileInput: {
        "& label": {
            display: "flex",
            padding: "0px 8px",
            paddingBottom: "11px",
            paddingTop: "12px",
            border: "1px dashed #7978786e",
            borderRadius: "8px",
            backgroundColor: "rgba(89,196,198,0.21)",
            cursor: "pointer",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "50px",
            position: "relative",
            "& *": {
                pointerEvents: "none",
            },
        },
        "& input[type=file]": {
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            top: "50%",
            left: "50%",
            position: "absolute",
            zIndex: "-1",
        }
    },
    wrapperBtnEnviar: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }


}));

export default function Contacto() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [openCVModal, setOpenCVModal] = React.useState(false);
    const labelRef = useRef();
    const [files, setFiles] = React.useState(null);
    const contactos = useSelector(state => state.contactos);
    let contacto = contactos ? contactos.create.nuevo : {};
    let contactoIsCreating = contactos ? contactos.create.isCreating : {};

    const handleClickOpenCVModal = () => {
        setOpenCVModal(true);
    };

    const handleCloseCVModal = () => {
        setOpenCVModal(false);
    };

    const handleChangeFile = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            labelRef.current.innerHTML = e.target.files[i].name;
            let file = e.target.files[i];
            let filename = file.name.replace(/[^a-zA-Z0-9]/g, '_');
            Object.defineProperty(file, 'name', {
                writable: true,
                value: filename
            });
            setFiles(file);
            changeDatosContacto({fileName: filename});
        }
    }

    const handleChangeDatosContacto = (e) => {
        console.log("ohla", e);
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        changeDatosContacto(cambio);
    }

    const changeDatosContacto = (cambio) => {
        dispatch(createContacto(cambio));
    };

    const submitFormContacto = (e) => {
        e.preventDefault();
        // if (this.state.file)
        dispatch(saveCreateContacto())
        // else
        //     alert("No hay archivo seleccionado");
    }
    const submitFormCV = (e) => {
        e.preventDefault();
        if (files)
            dispatch(saveCreateContacto([files]))
        else
            alert("No hay archivo seleccionado");
    }
    return (
        <div className={classes.container} id={"contacto"}>
            <ContainerLimit>
                <Box className={classes.containerTitulo}>
                    <Typography variant={"h1"} align={"center"} style={{color: "#fff"}}>
                        Contáctate
                    </Typography>
                    <Line width={"45px"}
                          height={"20px"}
                          borderBottomColor={"#59c4c6"}
                          borderBottomWidth={"4px"}
                          align={"center"}
                    />
                </Box>
                <Box display={"flex"} className={classes.containerContacto}>
                    <Box className={classes.trabajaConNosotros}>
                        <Typography variant={"h6"} style={{color: "#fff"}}>
                            <b>¡Trabajá con Nosotros!</b>
                        </Typography>
                        <Line width={"45px"} borderBottomWidth={"2px"} borderBottomColor={"#59c4c6"}/>
                        <Typography variant={"caption"} component={"p"}
                                    style={{opacity: "0.4", color: "#fff", marginTop: "30px"}}>
                            Te invitamos a ser parte de un equipo
                            motivado, flexible y ágil que permita el
                            desarrollo personal y profesional. Con libertad
                            para desafiarnos y aprender de manera
                            colaborativa, lleno de experiencias únicas.
                            ¡Postulate!
                        </Typography>
                        <Button variant={"outlined"}
                                onClick={handleClickOpenCVModal}
                                style={{
                                    color: "#59c4c6",
                                    borderColor: "#59c4c6",
                                    margin: "25px auto",
                                    marginBottom: "5px",
                                    display: "block"
                                }}
                        >
                            Cargar C.V.
                        </Button>
                    </Box>
                    <Box className={classes.formContacto}>
                        <form style={{position: "relative"}} onSubmit={submitFormContacto}>
                            <TextField className={"input-contacto"} id="nombre" label="Nombre" variant="outlined"
                                       onChange={handleChangeDatosContacto}
                                       value={contacto && contacto.nombre ? contacto.nombre : ""}
                                       required
                                       fullWidth/>
                            <Box className={"containerInputsRow"}>
                                <TextField className={"input-contacto"} id="email" label="E-mail" variant="outlined"
                                           onChange={handleChangeDatosContacto}
                                           value={contacto && contacto.email ? contacto.email : ""}
                                           required
                                           fullWidth/>
                                <TextField className={"input-contacto"} id="celular" label="Teléfono" name={"telefono"}
                                           variant="outlined"
                                           onChange={handleChangeDatosContacto}
                                           value={contacto && contacto.celular ? contacto.celular : ""}
                                           required
                                           fullWidth/>
                            </Box>
                            <TextField className={"input-contacto"} id="mensaje" label="Mensaje" variant="outlined"
                                       onChange={handleChangeDatosContacto}
                                       value={contacto && contacto.mensaje ? contacto.mensaje : ""}
                                       required
                                       fullWidth
                                       multiline
                                       rows={5}
                            />
                            <Box component={"div"}
                                 style={{
                                     position: "absolute",
                                     bottom: "-50px",
                                     right: 0
                                 }}>
                                <Box component={"div"} className={classes.wrapperBtnEnviar}>
                                    <Button type={"submit"}
                                            disabled={contactoIsCreating}
                                            style={{
                                                backgroundColor: "#59c4c6",
                                                padding: "5px 35px",
                                            }}>
                                        Enviar
                                    </Button>
                                    {contactoIsCreating &&
                                    <CircularProgress size={24} style={{color: "#002142"}}
                                                      className={classes.buttonProgress}/>}
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </ContainerLimit>
            <Dialog
                open={openCVModal}
                onClose={handleCloseCVModal}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    <Typography variant={"h2"}>
                        ¡Trabajá con nosotros!
                    </Typography>
                </DialogTitle>
                <form onSubmit={submitFormCV}>
                    <DialogContent>
                        <DialogContentText>
                            Cargá tus datos en el siguiente formulario para postularte en nuestras
                            búsquedas laborales
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="nombre"
                            label="Nombre y Apellido"
                            type="text"
                            name={"name"}
                            fullWidth
                            onChange={handleChangeDatosContacto}
                            value={contacto && contacto.nombre ? contacto.nombre : ""}
                            required
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="E-mail"
                            type="email"
                            name={"email"}
                            fullWidth
                            onChange={handleChangeDatosContacto}
                            value={contacto && contacto.email ? contacto.email : ""}
                            required
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="celular"
                            name={"telefono"}
                            label="Celular"
                            type="number"
                            fullWidth
                            onChange={handleChangeDatosContacto}
                            value={contacto && contacto.celular ? contacto.celular : ""}
                            required
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="mensaje"
                            label="Mensaje"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={handleChangeDatosContacto}
                            value={contacto && contacto.mensaje ? contacto.mensaje : ""}
                            required
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="area"
                            name="area"
                            label="Seleccione área"
                            fullWidth
                            onChange={handleChangeDatosContacto}
                            select
                            required
                        >
                            <MenuItem value={"Administración"}>Administración</MenuItem>
                            <MenuItem value={"Deposito"}>Depósito</MenuItem>
                            <MenuItem value={"Ventas"}>Ventas</MenuItem>
                            <MenuItem value={"Otra"}>Otra</MenuItem>
                        </TextField>
                        <Box component={"div"} marginTop={"10px"}>
                            <div className={classes.fileInput}>
                                <label htmlFor="file" ref={instance => labelRef.current = instance}>
                                    Seleccionar Archivo
                                </label>
                                <input type="file" name="file" id="file" className="inputfile"
                                       accept=".doc,.docx,.pdf"
                                       onChange={(e) => handleChangeFile(e)}
                                       required
                                />
                            </div>
                            <label htmlFor="file" style={{position: "relative", top: "3px"}}><i>Subir archivo (.pdf,
                                .doc)*</i></label>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCVModal} style={{color: "rgba(0,0,0,0.44)"}}>
                            Cancelar
                        </Button>
                        <div className={classes.wrapperBtnEnviar}>
                            <Button type="submit" color="primary" disabled={contactoIsCreating}>
                                Enviar
                            </Button>
                            {contactoIsCreating && <CircularProgress size={24} style={{color: "#59c4c6",}}
                                                                     className={classes.buttonProgress}/>}
                        </div>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
