//api
import marcasApi from "../api/marcasApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeMarcas";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//MARCAS
export const REQUEST_MARCAS = 'REQUEST_MARCAS';
export const RECEIVE_MARCAS = 'RECEIVE_MARCAS';
export const INVALIDATE_MARCAS = 'INVALIDATE_MARCAS';
export const ERROR_MARCAS = "ERROR_MARCAS";
export const RESET_MARCAS = "RESET_MARCAS";


export function invalidateMarcas() {
    return {
        type: INVALIDATE_MARCAS
    }
}

function requestMarcas() {
    return {
        type: REQUEST_MARCAS,
    }
}

function receiveMarcas(json) {
    return {
        type: RECEIVE_MARCAS,
    marcas: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorMarcas(error) {
    return {
        type: ERROR_MARCAS,
        error: error,
    }
}

export function resetMarcas() {
    return {
        type: RESET_MARCAS
    }
}

export function fetchMarcas(filtros) {
    return dispatch => {
        dispatch(requestMarcas());
        return marcasApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMarcas(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorMarcas(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMarcas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchMarcas(state) {
    const marcas = state.marcas.byId;
    if (!marcas) {
        return true
    } else if (marcas.isFetching) {
        return false
    } else {
        return marcas.didInvalidate;
    }
}

export function fetchMarcasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchMarcas(getState())) {
            return dispatch(fetchMarcas(filtros))
        }
    }
}


//MODEL
export const REQUEST_MARCA = 'REQUEST_MARCA';
export const RECEIVE_MARCA = 'RECEIVE_MARCA';
export const INVALIDATE_MARCA = 'INVALIDATE_MARCA';
export const ERROR_MARCA = "ERROR_MARCA";
export const RESET_MARCA = "RESET_MARCA";


export function invalidateMarca() {
    return {
        type: INVALIDATE_MARCA
    }
}

function requestMarca() {
    return {
        type: REQUEST_MARCA,
    }
}

export function receiveMarca(json) {
    return {
        type: RECEIVE_MARCA,
    marca: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorMarca(error) {
    return {
        type: ERROR_MARCA,
        error: error,
    }
}

export function fetchMarca(idMarca) {
    return dispatch => {
        dispatch(requestMarca());
        return marcasApi.getOne(idMarca)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMarca(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorMarca(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMarca(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_MARCA = 'UPDATE_MARCA';
export const REQUEST_UPDATE_MARCA = "REQUEST_UPDATE_MARCA";
export const SUCCESS_UPDATE_MARCA = "SUCCESS_UPDATE_MARCA";
export const ERROR_UPDATE_MARCA = "ERROR_UPDATE_MARCA";
export const RESET_UPDATE_MARCA = "RESET_UPDATE_MARCA";
export const DELETE_UPDATE_MARCA = "DELETE_UPDATE_MARCA";

function requestUpdateMarca() {
    return {
        type: REQUEST_UPDATE_MARCA,
    }
}

function receiveUpdateMarca(marca) {
    return {
        type: SUCCESS_UPDATE_MARCA,
        receivedAt: Date.now(),
        marca: normalizeDato(marca)
    }
}

function errorUpdateMarca(error) {
    return {
        type: ERROR_UPDATE_MARCA,
        error: error,
    }
}

export function resetUpdateMarca() {
    return {
        type: RESET_UPDATE_MARCA,
    }
}

export function updateMarca(marca) {
    return {
        type: UPDATE_MARCA,
        marca
    }
}

export function saveUpdateMarca() {
    return (dispatch, getState) => {
        dispatch(requestUpdateMarca());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let marca = denormalizeDato(getState().marcas.update.activo, store);

        return marcasApi.saveUpdate(marca)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateMarca(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateMarca(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateMarcas(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateMarcas(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateMarca(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateMarca(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateMarca(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateMarca(marca) {
    return {
        type: DELETE_UPDATE_MARCA,
        marca
    }
}

//UPDATE MARCAS
export const REQUEST_UPDATE_MARCAS = "REQUEST_UPDATE_MARCAS";
export const SUCCESS_UPDATE_MARCAS = "SUCCESS_UPDATE_MARCAS";
export const ERROR_UPDATE_MARCAS = "ERROR_UPDATE_MARCAS";
export const RESET_UPDATE_MARCAS = "RESET_UPDATE_MARCAS";

function requestUpdateMarcas() {
    return {
        type: REQUEST_UPDATE_MARCAS,
}
}

function receiveUpdateMarcas(marcas) {
    return {
        type: SUCCESS_UPDATE_MARCAS,
    receivedAt: Date.now(),
        marcas: normalizeDatos(marcas)
}
}

function errorUpdateMarcas(error) {
    return {
        type: ERROR_UPDATE_MARCAS,
    error: error,
}
}

export function resetUpdateMarcas() {
    return {
        type: RESET_UPDATE_MARCAS,
}
}

export function saveUpdateMarcas() {
    return (dispatch, getState) => {
        dispatch(requestUpdateMarcas());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let marcas = getState().marcas.update.activos.map((idMarca) => {
            return denormalizeDato(getState().marcas.update.activo[idMarca], store);
        });

        return marcasApi.saveUpdateMarcas(marcas)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateMarcas(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateMarcas(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateMarcas(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateMarcas(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateMarcas(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateMarcas(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA MARCA
export const CREATE_MARCA = 'CREATE_MARCA';
export const REQUEST_CREATE_MARCA = "REQUEST_CREATE_MARCA";
export const SUCCESS_CREATE_MARCA = "SUCCESS_CREATE_MARCA";
export const ERROR_CREATE_MARCA = "ERROR_CREATE_MARCA";
export const RESET_CREATE_MARCA = "RESET_CREATE_MARCA";
export const DELETE_CREATE_MARCA = "DELETE_CREATE_MARCA";


//ALTA MARCA
function requestCreateMarca() {
    return {
        type: REQUEST_CREATE_MARCA,
    }
}

function receiveCreateMarca(marca) {
    return {
        type: SUCCESS_CREATE_MARCA,
        receivedAt: Date.now(),
        marca: normalizeDato(marca)
    }
}

export function errorCreateMarca(error) {
    return {
        type: ERROR_CREATE_MARCA,
        error: error,
    }
}

export function resetCreateMarca() {
    return {
        type: RESET_CREATE_MARCA
    }
}

export function createMarca(marca) {
    return {
        type: CREATE_MARCA,
        marca
    }
}

export function deleteCreateMarca(marca) {
    return {
        type: DELETE_CREATE_MARCA,
        marca
    }
}

export function saveCreateMarca() {
    return (dispatch, getState) => {
        dispatch(requestCreateMarca());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let marca = denormalizeDato(getState().marcas.create.nuevo, store);

        return marcasApi.saveCreate(marca)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateMarca(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateMarca(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateMarcas(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateMarcas(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateMarca(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateMarca(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateMarca(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE MARCAS
export const REQUEST_CREATE_MARCAS = "REQUEST_CREATE_MARCAS";
export const SUCCESS_CREATE_MARCAS = "SUCCESS_CREATE_MARCAS";
export const ERROR_CREATE_MARCAS = "ERROR_CREATE_MARCAS";
export const RESET_CREATE_MARCAS = "RESET_CREATE_MARCAS";

function requestCreateMarcas() {
    return {
        type: REQUEST_CREATE_MARCAS,
}
}

function receiveCreateMarcas(marcas) {
    return {
        type: SUCCESS_CREATE_MARCAS,
    receivedAt: Date.now(),
        marcas: normalizeDatos(marcas)
}
}

function errorCreateMarcas(error) {
    return {
        type: ERROR_CREATE_MARCAS,
    error: error,
}
}

export function resetCreateMarcas() {
    return {
        type: RESET_CREATE_MARCAS,
}
}

export function saveCreateMarcas() {
    return (dispatch, getState) => {
        dispatch(requestCreateMarcas());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let marcas = getState().marcas.create.nuevos.map((idMarca) => {
            return denormalizeDato(getState().marcas.create.nuevo[idMarca], store);
        });

        return marcasApi.saveCreateMarcas(marcas)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateMarcas(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateMarcas(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateMarcas(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateMarcas(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateMarcas(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateMarcas(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE MARCA
export const DELETE_MARCA = 'DELETE_MARCA';
export const REQUEST_DELETE_MARCA = "REQUEST_DELETE_MARCA";
export const SUCCESS_DELETE_MARCA = "SUCCESS_DELETE_MARCA";
export const ERROR_DELETE_MARCA = "ERROR_DELETE_MARCA";
export const RESET_DELETE_MARCA = "RESET_DELETE_MARCA";

function requestDeleteMarca() {
    return {
        type: REQUEST_DELETE_MARCA,
    }
}

function receiveDeleteMarca(marca) {
    return {
        type: SUCCESS_DELETE_MARCA,
        receivedAt: Date.now(),
        marca: normalizeDato(marca)
    }
}

function errorDeleteMarca(error) {
    return {
        type: ERROR_DELETE_MARCA,
        error: error,
    }
}

export function resetDeleteMarca(error) {
    return {
        type: RESET_DELETE_MARCA,
        error: error,
    }
}

export function deleteMarca(marca) {
    return {
        type: DELETE_MARCA,
        marca
    }
}

export function saveDeleteMarca(marca) {
    return dispatch => {
        dispatch(requestDeleteMarca());
        return marcasApi.saveDelete(marca)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteMarca());
                            dispatch(receiveDeleteMarca(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteMarca(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteMarca(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteMarca(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteMarca(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteMarca(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT MARCA
export const REQUEST_PRINT_MARCA = "REQUEST_PRINT_MARCA";
export const SUCCESS_PRINT_MARCA = "SUCCESS_PRINT_MARCA";
export const ERROR_PRINT_MARCA = "ERROR_PRINT_MARCA";
export const RESET_PRINT_MARCA = "RESET_PRINT_MARCA";

function requestPrintMarca() {
    return {
        type: REQUEST_PRINT_MARCA,
    }
}

function receivePrintMarca(turnos) {
    return {
        type: SUCCESS_PRINT_MARCA,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintMarca(error) {
    return {
        type: ERROR_PRINT_MARCA,
        error: error,
    }
}

export function resetPrintMarca() {
    return {
        type: RESET_PRINT_MARCA,
    }
}

export function printMarca(idMarca) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintMarca());
        return marcasApi.printMarca(idMarca)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintMarca(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintMarca(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintMarca(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintMarca(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintMarca(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}