import {Box, CardActionArea, Divider} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import logoMerca from "../../../assets/img/logoMerca.png";

// import * as PropTypes from "prop-types";

function areEqual(prevProps, nextProps) {
    /*
    retorna true si al pasar los nextProps a renderizar retorna
    el mismo resultado que al pasar los prevProps a renderizar,
    de otro modo retorna false
    */
    return true;
}

const useStyles = makeStyles((theme) => ({
    containerImg: {
        margin: "0 auto",
        display: "block",
        textAlign: "center",
        "& img": {
            // maxHeight: "300px",
            maxWidth: "300px",
            height: "250px",
            width: "100%",
            objectFit: "contain",
            [theme.breakpoints.down(975)]: {
                height: "155px",
            }
        }
    },
}));

export default React.memo(function ImagenProducto(props) {

    // ImagenProducto.propTypes = {
    //     classes: PropTypes.any,
    //     producto: PropTypes.any
    // };
    const classes = useStyles();
    const {imgProducto} = props;

    return <CardActionArea>
        <Box component={"div"} className={classes.containerImg}>
            <Link to={"/producto/"}
                  style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img
                    //
                    src={imgProducto ? process.env.PUBLIC_URL + "/productos/" + imgProducto : logoMerca}
                    alt="producto.descripcion"/>
            </Link>
        </Box>
        <Divider/>
    </CardActionArea>;
}, areEqual);

