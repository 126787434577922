import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

//Components
import { Box, Toolbar, Typography, useMediaQuery, IconButton } from '@material-ui/core';
import { NavHashLink as Link } from 'react-router-hash-link'
//Icons
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { ReactComponent as LogoColor } from "../../../assets/img/jvaLogoColor.svg";
import { ReactComponent as InstaIcon } from "../../../assets/img/instagram.svg";
import { ReactComponent as FaceIcon } from "../../../assets/img/facebook.svg";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        minHeight: "50px",
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    toolbarOnScroll: {
        padding: theme.spacing(2, 3),
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        width: "152px",
        maxHeight: "44px",
        objectPosition: "left",
        objectFit: "contain",
        [theme.breakpoints.down(321)]: {
            display: "none",
        }
    },
    containerAppMenu: {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(6),
        [theme.breakpoints.down("md")]: {
            marginLeft: theme.spacing(3),
        },
        "& a": {
            margin: theme.spacing(0, 1),
            display: "flex",
            alignItems: "center",
            opacity: "0.9",
            color: "white",
            textDecoration: "none",
            "& p": {
                paddingTop: "2px"
            },
            "& svg": {
                width: "16px",
                height: "16px",
                fill: "#fff"
            }
        },
        "&.onScroll a": {
            color: "#003462",
        },
        "&.onScroll svg": {
            fill: "#003462",
        },
    },
    active: {
        borderBottom: "2px solid #ff9900",
    },

}));

export default function DesktopToolbar(props) {
    const classes = useStyles();
    const disableGutters = useMediaQuery('(min-width:1216px)');

    const scrollWithOffset = (el, yOffset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    return (
        <>
            <Toolbar id={"desktop-toolbar"}
                     className={
                         clsx([classes.toolbar, {
                             [classes.toolbarOnScroll]: props.triggerScrollColor || props.navBarOnPromocion
                         }])
                     }
                     disableGutters={disableGutters}
            >
                {
                    props.triggerScrollColor || props.navBarOnPromocion ?
                        <LogoColor className={classes.logo}/>
                        :
                        <Logo className={classes.logo}/>
                }
                <div className={classes.grow}/>
                <Box component={"div"}
                     className={clsx([classes.containerAppMenu, {"onScroll": props.triggerScrollColor || props.navBarOnPromocion}])}>
                    <Link to="/#inicio" smooth activeClassName={classes.active}
                          scroll={el => scrollWithOffset(el, 0)}
                    >
                        <Typography variant={"body2"} component={"p"}>Inicio</Typography>
                    </Link>
                    <Link to="/#quienesSomos" smooth activeClassName={classes.active}
                          scroll={el => scrollWithOffset(el, -150)}
                    >
                        <Typography variant={"body2"} component={"p"}>¿Quienes Somos?</Typography>
                    </Link>
                    <Link to="/promociones" color="inherit" activeClassName={classes.active}>
                        <Typography variant={"body2"} component={"p"}>Promociones</Typography>
                    </Link>
                    <Link to="/#comoComprar" smooth activeClassName={classes.active}
                          scroll={el => scrollWithOffset(el, -30)}

                    >
                        <Typography variant={"body2"} component={"p"}>¿Cómo comprar?</Typography>
                    </Link>
                    <Link to="/#zonasEntrega" smooth activeClassName={classes.active}
                          scroll={el => scrollWithOffset(el, -30)}

                    >
                        <Typography variant={"body2"} component={"p"}>Zonas de entrega</Typography>
                    </Link>
                    <Link to="/#contacto" smooth activeClassName={classes.active}
                          scroll={el => scrollWithOffset(el, 20)}

                    >
                        <Typography variant={"body2"} component={"p"}>Contacto</Typography>
                    </Link>
                    <IconButton component={"a"} href="https://www.facebook.com/jvasrl" target={"_blank"}>
                        <FaceIcon/>
                    </IconButton>
                    <IconButton component={"a"} href="https://www.instagram.com/jvasrl" target={"_blank"}>
                        <InstaIcon/>
                    </IconButton>
                </Box>
            </Toolbar>
        </>
    );
}
