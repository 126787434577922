import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Actions

//Components
import { Box, Typography, Button } from "@material-ui/core";
import ContainerLimit from "../../customComponents/ContainerLimit";

//Icons

import bgComoComprar from "../../assets/img/comoComprar/bgComoComprar.png";
import pidoLogo from "../../assets/img/comoComprar/icons/pido-logo.png";
import chicaConCel from "../../assets/img/comoComprar/chicaCel.png";
import tarjetaCel from "../../assets/img/comoComprar/tarjetaCel.png";
import entrega from "../../assets/img/comoComprar/entrega.png";
import tarjeta from "../../assets/img/comoComprar/icons/tarjeta.png";
import carrito from "../../assets/img/comoComprar/icons/carrito.png";
import camioncito from "../../assets/img/comoComprar/icons/camioncito.png";
import prisma from "../../assets/img/comoComprar/icons/prismaLogo.png";
import Line from "../../customComponents/Line";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `url(${bgComoComprar})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPositionY: "350px",
        [theme.breakpoints.down(1000)]: {
            backgroundPositionY: "390px",
        },
        paddingBottom: "20px",
        paddingTop: "60px",
        [theme.breakpoints.down(714)]: {
            backgroundImage: "none",
            padding: theme.spacing(0, 1),
        },
        [theme.breakpoints.up(1920)]: {
            paddingTop: "150px",
        },
    },
    containerTitulo: {
        marginBottom: "90px",
        [theme.breakpoints.down(714)]: {
            paddingTop: "30px",
            marginBottom: "30px",
        }

    },
    containerTarjetas: {
        display: "flex",
        justifyContent: "center",
        marginTop: "50px",
        flexWrap: "wrap",
    },
    rowComoComprar: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down(714)]: {
            "&:not(:first-child)": {
                marginTop: "50px",
            },
        },
        "& .imgComoComprar": {
            maxWidth: "400px",
            [theme.breakpoints.down(1159)]: {
                maxWidth: "300px",
            },
            [theme.breakpoints.down(840)]: {
                maxWidth: "250px",
            },
        },
        "& h6": {
            color: "#59C4C6",
        },
        "& h5": {
            color: "#003462",
            marginBottom: "15px",
        },
        "& p": {
            color: "#003462",
        }
    },
    icono: {
        maxWidth: "65px",
        [theme.breakpoints.down(840)]: {
            maxWidth: "45px",
        },
        width: "100%",
        filter: "drop-shadow(0px 5px 9px #ff99004f)",
        marginTop: "10px",
        marginBottom: "10px",
    },
    mediosHacerPedidoWrapper: {
        display: "flex",
        [theme.breakpoints.down(714)]: {
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        "& a": {
            textDecoration: "none",
            color: "#003462",
        },
        "& .itemMediosHacerPedido": {
            width: "50%",
            [theme.breakpoints.down(714)]: {
                width: "90%",
            },
            "&:first-child": {
                borderRight: "1px dotted #003462",
                paddingRight: "8px",
                [theme.breakpoints.down(714)]: {
                    borderRight: "none",
                    borderBottom: "1px dotted #003462",
                    paddingRight: 0,
                    paddingBottom: "16px"
                },
            },
            "&:last-child": {
                paddingLeft: "16px",
                [theme.breakpoints.down(714)]: {
                    paddingLeft: 0,
                    paddingTop: "16px"
                }
            },
        },

    },
    caracteristicaWrapper: {
        maxWidth: "500px",
        margin: theme.spacing(0, 1),
    },
    imgCaracteristicaWrapper: {
        margin: theme.spacing(0, 1),
        [theme.breakpoints.down(714)]: {
            display: "none"
        },
    },
    wrapperImg: {
        display: "flex",
        marginTop: "12px",
        "& *": {
            padding: "5px"
        },
        "& img": {
            width: "100%",
            maxWidth: "110px",
            objectFit: "contain",
        }
    },
    containerPasos: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(714)]: {
            flexDirection: "row",
            alignItems: "center",
            "& > *:not(:first-child)": {
                margin: "8px"
            },
        },
    },
    wrapperPasoDos: {
        paddingLeft: "80px",
        [theme.breakpoints.down(714)]: {
            paddingLeft: "inherit",
        },
    },
}));


export default function ComoComprar() {
    const classes = useStyles();

    return (
        <Box component={"div"} style={{backgroundColor: "white"}} id={"comoComprar"}>
            <ContainerLimit className={classes.container}>
                <Box component={"div"} className={classes.containerTitulo}>
                    <Typography variant={"h1"} align={"center"} style={{color: "#003462"}}>
                        ¿Cómo comprar?
                    </Typography>
                    <Line width={"70px"} height={"20px"} align={"center"} borderBottomColor={"#59c4c6"}
                          borderBottomWidth={"3px"}/>
                </Box>

                <Box component={"div"} className={classes.comoComprar}>
                    <Box component={"div"} className={classes.rowComoComprar}>
                        <Box component={"div"} className={classes.imgCaracteristicaWrapper}>
                            <img src={chicaConCel} className={"imgComoComprar"} alt=""/>
                        </Box>
                        <Box component={"div"} className={classes.caracteristicaWrapper}>
                            <Box component={"div"} className={classes.containerPasos}>
                                <img src={carrito} className={classes.icono}/>
                                <Typography component={"h6"} variant={"h6"}>
                                    Paso <b>1</b>
                                </Typography>
                            </Box>
                            <Typography comopnent={"h5"} variant={"h5"}>
                                Medios para hacer el pedido
                            </Typography>

                            <Box component={"div"} className={classes.mediosHacerPedidoWrapper}>
                                <Box component={"div"} className={"itemMediosHacerPedido"}>
                                    <Typography component={"p"} variant={"subtitle1"}>
                                        <b>ONLINE</b>
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        Solicita un vendedor
                                        comunicándote a nuestro
                                        WhatsApp
                                        &nbsp;
                                        <a href="https://wa.me/543412117923" target={"_blank"}>
                                            <b style={{whiteSpace: "nowrap"}}>
                                                +54 341 211 7923
                                            </b>
                                        </a>
                                        &nbsp;Bájate la aplicación&nbsp;
                                        <b>Pido</b> y hacé tu pedido online.
                                    </Typography>
                                    <Box component={"div"} className={classes.wrapperImg}>
                                        <img src={pidoLogo} alt=""/>
                                        <Button style={{
                                            backgroundColor: "#59c4c6",
                                            color: "white",
                                        }}
                                                component={"a"}
                                                href={"https://play.google.com/store/apps/details?id=chess.sampedidofacil&hl=es_AR&gl=US"}
                                                target={"_blank"}>
                                            DESCARGAR
                                        </Button>
                                    </Box>
                                </Box>

                                <Box component={"div"} className={"itemMediosHacerPedido"}>
                                    <Typography component={"p"} variant={"subtitle1"}>
                                        <b>PERSONALMENTE</b>
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        Venta al mostrador, llévate el pedido en el acto.
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>
                    </Box>

                    <Box component={"div"} className={classes.rowComoComprar}>
                        <Box component={"div"}
                             className={clsx([classes.caracteristicaWrapper, classes.wrapperPasoDos])}>
                            <Box component={"div"} className={classes.containerPasos}>
                                <img src={tarjeta} className={classes.icono}/>
                                <Typography component={"h6"} variant={"h6"}>
                                    Paso 2
                                </Typography>
                            </Box>
                            <Typography comopnent={"h5"} variant={"h5"}>
                                Formas de pago
                            </Typography>
                            <Typography component={"p"} variant={"body1"}>
                                Pagá en <b>efectivo</b> contra entrega, <b>transferencia</b> y
                                botón de pago de <b>Prisma</b>. ¡Pagá en cuotas!
                            </Typography>
                            <Box component={"div"} className={classes.wrapperImg}>
                                <img src={prisma} alt=""/>
                            </Box>
                        </Box>
                        <Box component={"div"} className={classes.imgCaracteristicaWrapper}>
                            <img src={tarjetaCel} className={"imgComoComprar"} alt=""/>
                        </Box>
                    </Box>

                    <Box component={"div"} className={classes.rowComoComprar}>
                        <Box component={"div"} className={classes.imgCaracteristicaWrapper}>
                            <img src={entrega} className={"imgComoComprar"} alt=""/>
                        </Box>
                        <Box component={"div"} className={classes.caracteristicaWrapper}>
                            <Box component={"div"} className={classes.containerPasos}>
                                <img src={camioncito} className={classes.icono}/>
                                <Typography component={"h6"} variant={"h6"}>
                                    Paso 3
                                </Typography>
                            </Box>
                            <Typography comopnent={"h5"} variant={"h5"}>
                                Recibí el pedido
                            </Typography>
                            <Typography component={"p"} variant={"body1"}>
                                Recibí el pedido en tu negocio en 48 horas
                                También podes retirarlo en nuestro depósito en el día.
                            </Typography>
                        </Box>
                    </Box>

                </Box>
            </ContainerLimit>
        </Box>
    );
}
