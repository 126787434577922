import {normalize, schema, denormalize} from 'normalizr';
import producto from "./normalizeProductos";

const productoDestinoPromocion = new schema.Entity('productosDestinoPromocion', {producto: producto}, {idAttribute: "id"});

export function normalizeDatos(myData) {
    const mySchema = {productosDestinoPromocion: [productoDestinoPromocion]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = productoDestinoPromocion;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = productoDestinoPromocion;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default productoDestinoPromocion;

