//api
import promocionesApi from "../api/promocionesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizePromociones";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PROMOCIONES
export const REQUEST_PROMOCIONES = 'REQUEST_PROMOCIONES';
export const RECEIVE_PROMOCIONES = 'RECEIVE_PROMOCIONES';
export const INVALIDATE_PROMOCIONES = 'INVALIDATE_PROMOCIONES';
export const ERROR_PROMOCIONES = "ERROR_PROMOCIONES";
export const RESET_PROMOCIONES = "RESET_PROMOCIONES";


export function invalidatePromociones() {
    return {
        type: INVALIDATE_PROMOCIONES
    }
}

function requestPromociones() {
    return {
        type: REQUEST_PROMOCIONES,
    }
}

function receivePromociones(json) {
    return {
        type: RECEIVE_PROMOCIONES,
    promociones: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPromociones(error) {
    return {
        type: ERROR_PROMOCIONES,
        error: error,
    }
}

export function resetPromociones() {
    return {
        type: RESET_PROMOCIONES
    }
}

export function fetchPromociones(filtros) {
    return dispatch => {
        dispatch(requestPromociones());
        return promocionesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePromociones(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPromociones(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPromociones(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPromociones(state) {
    const promociones = state.promociones.byId;
    if (!promociones) {
        return true
    } else if (promociones.isFetching) {
        return false
    } else {
        return promociones.didInvalidate;
    }
}

export function fetchPromocionesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPromociones(getState())) {
            return dispatch(fetchPromociones(filtros))
        }
    }
}


//MODEL
export const REQUEST_PROMOCION = 'REQUEST_PROMOCION';
export const RECEIVE_PROMOCION = 'RECEIVE_PROMOCION';
export const INVALIDATE_PROMOCION = 'INVALIDATE_PROMOCION';
export const ERROR_PROMOCION = "ERROR_PROMOCION";
export const RESET_PROMOCION = "RESET_PROMOCION";


export function invalidatePromocion() {
    return {
        type: INVALIDATE_PROMOCION
    }
}

function requestPromocion() {
    return {
        type: REQUEST_PROMOCION,
    }
}

export function receivePromocion(json) {
    return {
        type: RECEIVE_PROMOCION,
    promocion: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPromocion(error) {
    return {
        type: ERROR_PROMOCION,
        error: error,
    }
}

export function fetchPromocion(idPromocion) {
    return dispatch => {
        dispatch(requestPromocion());
        return promocionesApi.getOne(idPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePromocion(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPromocion(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_PROMOCION = 'UPDATE_PROMOCION';
export const REQUEST_UPDATE_PROMOCION = "REQUEST_UPDATE_PROMOCION";
export const SUCCESS_UPDATE_PROMOCION = "SUCCESS_UPDATE_PROMOCION";
export const ERROR_UPDATE_PROMOCION = "ERROR_UPDATE_PROMOCION";
export const RESET_UPDATE_PROMOCION = "RESET_UPDATE_PROMOCION";
export const DELETE_UPDATE_PROMOCION = "DELETE_UPDATE_PROMOCION";

function requestUpdatePromocion() {
    return {
        type: REQUEST_UPDATE_PROMOCION,
    }
}

function receiveUpdatePromocion(promocion) {
    return {
        type: SUCCESS_UPDATE_PROMOCION,
        receivedAt: Date.now(),
        promocion: normalizeDato(promocion)
    }
}

function errorUpdatePromocion(error) {
    return {
        type: ERROR_UPDATE_PROMOCION,
        error: error,
    }
}

export function resetUpdatePromocion() {
    return {
        type: RESET_UPDATE_PROMOCION,
    }
}

export function updatePromocion(promocion) {
    return {
        type: UPDATE_PROMOCION,
        promocion
    }
}

export function saveUpdatePromocion() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePromocion());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let promocion = denormalizeDato(getState().promociones.update.activo, store);

        return promocionesApi.saveUpdate(promocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePromocion(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdatePromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdatePromociones(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdatePromociones(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdatePromocion(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdatePromocion(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdatePromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdatePromocion(promocion) {
    return {
        type: DELETE_UPDATE_PROMOCION,
        promocion
    }
}

//UPDATE PROMOCIONES
export const REQUEST_UPDATE_PROMOCIONES = "REQUEST_UPDATE_PROMOCIONES";
export const SUCCESS_UPDATE_PROMOCIONES = "SUCCESS_UPDATE_PROMOCIONES";
export const ERROR_UPDATE_PROMOCIONES = "ERROR_UPDATE_PROMOCIONES";
export const RESET_UPDATE_PROMOCIONES = "RESET_UPDATE_PROMOCIONES";

function requestUpdatePromociones() {
    return {
        type: REQUEST_UPDATE_PROMOCIONES,
}
}

function receiveUpdatePromociones(promociones) {
    return {
        type: SUCCESS_UPDATE_PROMOCIONES,
    receivedAt: Date.now(),
        promociones: normalizeDatos(promociones)
}
}

function errorUpdatePromociones(error) {
    return {
        type: ERROR_UPDATE_PROMOCIONES,
    error: error,
}
}

export function resetUpdatePromociones() {
    return {
        type: RESET_UPDATE_PROMOCIONES,
}
}

export function saveUpdatePromociones() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePromociones());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let promociones = getState().promociones.update.activos.map((idPromocion) => {
            return denormalizeDato(getState().promociones.update.activo[idPromocion], store);
        });

        return promocionesApi.saveUpdatePromociones(promociones)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdatePromociones(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePromociones(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdatePromociones(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdatePromociones(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdatePromociones(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdatePromociones(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PROMOCION
export const CREATE_PROMOCION = 'CREATE_PROMOCION';
export const REQUEST_CREATE_PROMOCION = "REQUEST_CREATE_PROMOCION";
export const SUCCESS_CREATE_PROMOCION = "SUCCESS_CREATE_PROMOCION";
export const ERROR_CREATE_PROMOCION = "ERROR_CREATE_PROMOCION";
export const RESET_CREATE_PROMOCION = "RESET_CREATE_PROMOCION";
export const DELETE_CREATE_PROMOCION = "DELETE_CREATE_PROMOCION";


//ALTA PROMOCION
function requestCreatePromocion() {
    return {
        type: REQUEST_CREATE_PROMOCION,
    }
}

function receiveCreatePromocion(promocion) {
    return {
        type: SUCCESS_CREATE_PROMOCION,
        receivedAt: Date.now(),
        promocion: normalizeDato(promocion)
    }
}

export function errorCreatePromocion(error) {
    return {
        type: ERROR_CREATE_PROMOCION,
        error: error,
    }
}

export function resetCreatePromocion() {
    return {
        type: RESET_CREATE_PROMOCION
    }
}

export function createPromocion(promocion) {
    return {
        type: CREATE_PROMOCION,
        promocion
    }
}

export function deleteCreatePromocion(promocion) {
    return {
        type: DELETE_CREATE_PROMOCION,
        promocion
    }
}

export function saveCreatePromocion() {
    return (dispatch, getState) => {
        dispatch(requestCreatePromocion());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let promocion = denormalizeDato(getState().promociones.create.nuevo, store);

        return promocionesApi.saveCreate(promocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePromocion(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreatePromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreatePromociones(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreatePromociones(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreatePromocion(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreatePromocion(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreatePromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PROMOCIONES
export const REQUEST_CREATE_PROMOCIONES = "REQUEST_CREATE_PROMOCIONES";
export const SUCCESS_CREATE_PROMOCIONES = "SUCCESS_CREATE_PROMOCIONES";
export const ERROR_CREATE_PROMOCIONES = "ERROR_CREATE_PROMOCIONES";
export const RESET_CREATE_PROMOCIONES = "RESET_CREATE_PROMOCIONES";

function requestCreatePromociones() {
    return {
        type: REQUEST_CREATE_PROMOCIONES,
}
}

function receiveCreatePromociones(promociones) {
    return {
        type: SUCCESS_CREATE_PROMOCIONES,
    receivedAt: Date.now(),
        promociones: normalizeDatos(promociones)
}
}

function errorCreatePromociones(error) {
    return {
        type: ERROR_CREATE_PROMOCIONES,
    error: error,
}
}

export function resetCreatePromociones() {
    return {
        type: RESET_CREATE_PROMOCIONES,
}
}

export function saveCreatePromociones() {
    return (dispatch, getState) => {
        dispatch(requestCreatePromociones());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let promociones = getState().promociones.create.nuevos.map((idPromocion) => {
            return denormalizeDato(getState().promociones.create.nuevo[idPromocion], store);
        });

        return promocionesApi.saveCreatePromociones(promociones)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreatePromociones(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePromociones(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreatePromociones(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreatePromociones(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreatePromociones(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreatePromociones(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PROMOCION
export const DELETE_PROMOCION = 'DELETE_PROMOCION';
export const REQUEST_DELETE_PROMOCION = "REQUEST_DELETE_PROMOCION";
export const SUCCESS_DELETE_PROMOCION = "SUCCESS_DELETE_PROMOCION";
export const ERROR_DELETE_PROMOCION = "ERROR_DELETE_PROMOCION";
export const RESET_DELETE_PROMOCION = "RESET_DELETE_PROMOCION";

function requestDeletePromocion() {
    return {
        type: REQUEST_DELETE_PROMOCION,
    }
}

function receiveDeletePromocion(promocion) {
    return {
        type: SUCCESS_DELETE_PROMOCION,
        receivedAt: Date.now(),
        promocion: normalizeDato(promocion)
    }
}

function errorDeletePromocion(error) {
    return {
        type: ERROR_DELETE_PROMOCION,
        error: error,
    }
}

export function resetDeletePromocion(error) {
    return {
        type: RESET_DELETE_PROMOCION,
        error: error,
    }
}

export function deletePromocion(promocion) {
    return {
        type: DELETE_PROMOCION,
        promocion
    }
}

export function saveDeletePromocion(promocion) {
    return dispatch => {
        dispatch(requestDeletePromocion());
        return promocionesApi.saveDelete(promocion)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeletePromocion());
                            dispatch(receiveDeletePromocion(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeletePromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeletePromocion(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeletePromocion(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeletePromocion(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeletePromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PROMOCION
export const REQUEST_PRINT_PROMOCION = "REQUEST_PRINT_PROMOCION";
export const SUCCESS_PRINT_PROMOCION = "SUCCESS_PRINT_PROMOCION";
export const ERROR_PRINT_PROMOCION = "ERROR_PRINT_PROMOCION";
export const RESET_PRINT_PROMOCION = "RESET_PRINT_PROMOCION";

function requestPrintPromocion() {
    return {
        type: REQUEST_PRINT_PROMOCION,
    }
}

function receivePrintPromocion(turnos) {
    return {
        type: SUCCESS_PRINT_PROMOCION,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintPromocion(error) {
    return {
        type: ERROR_PRINT_PROMOCION,
        error: error,
    }
}

export function resetPrintPromocion() {
    return {
        type: RESET_PRINT_PROMOCION,
    }
}

export function printPromocion(idPromocion) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintPromocion());
        return promocionesApi.printPromocion(idPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintPromocion(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintPromocion(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintPromocion(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintPromocion(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}