import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Actions

//Components
import { Box, Typography } from "@material-ui/core";

//Icons

import bgQuienesSomos from "../../assets/img/nosDestacamos/bgDestacamos.png";
import ContainerLimit from "../../customComponents/ContainerLimit";

import cliente from "../../assets/img/nosDestacamos/icons/cliente.png";
import free from "../../assets/img/nosDestacamos/icons/free.png";
import ofertas from "../../assets/img/nosDestacamos/icons/ofertas.png";
import stock from "../../assets/img/nosDestacamos/icons/stock.png";
import ventaMostrador from "../../assets/img/nosDestacamos/icons/ventaMostrador.png";
import zonas from "../../assets/img/nosDestacamos/icons/zonas.png";
import Line from "../../customComponents/Line";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `url(${bgQuienesSomos})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingBottom: "350px",
        paddingTop: "200px",
        [theme.breakpoints.down(714)]: {
            paddingTop: "130px",
            paddingBottom: "130px"
        },
        [theme.breakpoints.up(1920)]: {
            paddingTop: "430px",
            paddingBottom: "430px",
            height: "100vh"
        }
    },
    containerTarjetas: {
        display: "flex",
        justifyContent: "center",
        marginTop: "50px",
        flexWrap: "wrap",
        [theme.breakpoints.down(646)]: {
            overflowX: "auto",
            flexWrap: "nowrap",
            justifyContent: "inherit",
            paddingTop: "20px",
            paddingBottom: "50px",
            width: "100vw",
            // maskImage: "linear-gradient(to right, transparent, white 10px, white 98%, transparent)",
        },
    },
    tarjeta: {
        borderRadius: "15px",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        width: "300px",
        minHeight: "230px",
        boxShadow: "4px 17px 30px #0000004d",
        // boxShadow: theme.shadows[10],
        margin: theme.spacing(6, 3),
        [theme.breakpoints.down(1044)]: {
            margin: theme.spacing(3, 1),
        },
        [theme.breakpoints.down(646)]: {
            display: "flex",
            flexDirection: "column",
            width: "200px",
            flex: "0 0 auto",
            padding: "16px",
            margin: "24px 5px",
        },
        "&:nth-child(2),&:nth-child(5)": {
            position: "relative",
            top: "80px",
            [theme.breakpoints.down(948)]: {
                top: 0,
            },
        },


    },
    icono: {
        backgroundColor: "#ff9900",
        borderRadius: "100%",
        width: "80px",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        marginTop: "-60px",
        marginBottom: "10px",
        "& img": {
            width: "50px",
            height: "auto",
        }
    }
}));


export default function Destacamos() {
    const classes = useStyles();

    const datos = [
        {
            img: free,
            titulo: <>ENVÍO <span style={{color: "#59c4c6"}}>GRATIS</span> A DOMICILIO</>,
            cuerpo: "Entrega en 48hs. 15 años de experiencia en logística."
        },
        {
            img: stock,
            titulo: "STOCK PERMANENTE",
            cuerpo: "Gran cantidad de productos, variedad: Disponemos de 3000 productos con stock permanente."
        },
        {
            img: ofertas,
            titulo: "OFERTAS PERMANENTES",
            cuerpo: "Contamos con promociones que renovamos periódicamente. Descuentos por bulto cerrado/compra en volumen."
        },
        {
            img: cliente,
            titulo: "ATENCIÓN AL CLIENTE",
            cuerpo: "Staff de vendedores que van de puerta a puerta."
        },
        {
            img: ventaMostrador,
            titulo: "VENTA AL MOSTRADOR",
            cuerpo: "Consumidor final y mayorista Retiro en depósito."
        },
        {
            img: zonas,
            titulo: "ZONAS DE ENTREGA",
            cuerpo: "Dentro de los límites: el Río, Av. Avellaneda y Av. Pellegrini Villa Gobernador Gálvez. Hacemos entregas en puntos cercanos al limite."
        },
    ]

    let tarjetas = datos.map((dato) => {
        return (
            <Box component={"div"} className={classes.tarjeta}>
                <Box component={"div"} className={classes.icono}>
                    <img src={dato.img} alt=""/>
                </Box>
                <Typography variant={"subtitle1"} component={"p"} style={{color: "#003462", lineHeight: "inherit"}}>
                    <b>{dato.titulo}</b>
                </Typography>
                <Typography variant={"body1"} component={"p"}
                            style={{color: "#6d6d6d", lineHeight: 1.3, marginTop: "5px"}}>
                    {dato.cuerpo}
                </Typography>
            </Box>
        )
    })
    return (
        <Box component={"div"} className={classes.container}>
            <ContainerLimit>
                <Typography variant={"h1"} align={"center"} style={{color: "#fff"}}>
                    Nos destacamos por
                </Typography>
                <Line width={"70px"} height={"20px"} align={"center"} borderBottomColor={"#59c4c6"}
                      borderBottomWidth={"3px"}/>
                <Box component={"div"} className={classes.containerTarjetas}>
                    {tarjetas}
                </Box>
            </ContainerLimit>
        </Box>
    );
}
