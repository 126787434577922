import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavHashLink as Link } from 'react-router-hash-link'

//Components
import ContainerLimit from "../../../customComponents/ContainerLimit";
import { Box, Typography, Hidden, IconButton } from "@material-ui/core";

//Img
import logo from "../../../assets/img/footer/logo.png";
import ig from "../../../assets/img/footer/igTur.png";
import face from "../../../assets/img/footer/faceTur.png";
import dataFiscal from "../../../assets/img/footer/dataFiscal.jpg";
import clsx from "clsx";
import { ReactComponent as InstaIcon } from "../../../assets/img/instagram.svg";
import { ReactComponent as FaceIcon } from "../../../assets/img/facebook.svg";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: "#002142",
        borderTop: "2px solid #59c4c6",
        padding: "0 16px",
        paddingTop: "40px",
        paddingBottom: "40px",
        "& ul li:not(:first-child)": {
            marginTop: "5px",
        },
        "& ul li:not(:last-child)": {
            marginBottom: "5px",
        }
    },
    containerFooter: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down(714)]: {
            flexWrap: "wrap",
        },
    },
    columnFooter: {
        maxWidth: "250px",
        [theme.breakpoints.down(714)]: {
            maxWidth: "100%",
        },
        width: "100%",
        "&:not(:first-child)": {
            marginLeft: "5px",
            [theme.breakpoints.down(714)]: {
                marginLeft: 0,
            },
        },
        "&:not(:last-child)": {
            marginRight: "5px",
            [theme.breakpoints.down(714)]: {
                marginRight: 0,
            },
        },
        "& p, & a": {
            color: "#59c4c6",
            textDecoration: "none",
        },
        "& a:hover": {
            color: "#70e5e7",
        },
    },
    logo: {
        maxWidth: "90px",
    },
    containerRRSS: {
        display: "flex",
        "& img": {
            maxWidth: "15px",
            margin: "5px"

        },
        [theme.breakpoints.down(714)]: {
            display: "none",
        },
    },
    footerNav: {
        [theme.breakpoints.down(960)]: {
            display: "none",
        },
    },
}));

export default function Footer(props) {
    const classes = useStyles();
    const scrollWithOffset = (el, yOffset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }
    return (
        <footer className={classes.footer}>
            <ContainerLimit className={classes.containerFooter}>
                <Box component={"div"} className={classes.columnFooter} display={"flex"} flexDirection={"column"}>
                    <img src={logo} className={classes.logo} alt=""/>
                    <Typography variant={"body2"} component={"p"} style={{marginTop: "20px"}}>
                        <b><span>{new Date().getFullYear()}</span> Distribuidora JVA SRL</b>
                    </Typography>
                    <Typography variant={"body2"} component={"p"}>
                        © Todos los derechos reservados.
                    </Typography>
                    <Typography variant={"caption"} component={"p"} style={{marginTop: "auto"}}>
                        Desarrollo: <span><a href="https://exadec.com" target={"_blank"}>Exadec</a></span>
                    </Typography>
                    <Typography variant={"caption"} component={"p"}>
                        Diseño: <span><a href="https://dimensiondos.com/" target={"_blank"}>Dimensión Dos</a></span>
                    </Typography>
                </Box>
                <Box component={"div"} className={classes.columnFooter}>
                    <Box component={"div"} className={classes.containerRRSS}>
                        <IconButton component={"a"} href="https://www.instagram.com/jvasrl" target="_blank">
                            <InstaIcon fill={"#59c4c6"}/>
                        </IconButton>
                        <IconButton component={"a"} href="https://www.facebook.com/jvasrl" target="_blank">
                            <FaceIcon fill={"#59c4c6"}/>
                        </IconButton>
                    </Box>
                    <ul style={{listStyle: "none", margin: 0, padding: 0}}>
                        <li>
                            <Typography variant={"caption"} component={"p"} style={{marginTop: "20px"}}>
                                Dirección: <b>Juan Pablo II 6640</b> (entre calles Iriondo y San Nicolás)
                            </Typography>
                        </li>
                        <li>
                            <Typography variant={"caption"} component={"p"}>
                                Teléfono: <b>0341-4639970</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant={"caption"} component={"a"} href={"https://wa.me/543412117923"}
                                        target={"_blank"}>
                                WhatsApp: <b>+54 341 211 7923</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant={"caption"} component={"a"} href={"mailto:info@jvasrl.com"}
                                        target={"_blank"}>
                                e-mail: <b>info@jvasrl.com</b>
                            </Typography>
                        </li>
                    </ul>
                </Box>
                <Box component={"div"} className={clsx([classes.columnFooter, classes.footerNav])}>
                    <ul style={{
                        listStyle: "none",
                        padding: 0,
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 auto",
                        width: "fit-content",
                    }}>
                        <li>
                            <Link to="/#inicio" smooth activeClassName={classes.active}
                                  scroll={el => scrollWithOffset(el, 0)}
                            >
                                <Typography variant={"body2"} component={"p"}>Inicio</Typography>
                            </Link>
                        </li>
                        <li>
                            <Link to="/#quienesSomos" smooth activeClassName={classes.active}
                                  scroll={el => scrollWithOffset(el, -150)}
                            >
                                <Typography variant={"body2"} component={"p"}>¿Quienes Somos?</Typography>
                            </Link>
                        </li>
                        <li>
                            <Link to="/promociones" color="inherit" activeClassName={classes.active}>
                                <Typography variant={"body2"} component={"p"}>Promociones</Typography>
                            </Link>
                        </li>
                        <li>
                            <Link to="/#comoComprar" smooth activeClassName={classes.active}
                                  scroll={el => scrollWithOffset(el, -30)}

                            >
                                <Typography variant={"body2"} component={"p"}>¿Cómo comprar?</Typography>
                            </Link>
                        </li>
                        <li>
                            <Link to="/#zonasEntrega" smooth activeClassName={classes.active}
                                  scroll={el => scrollWithOffset(el, -30)}

                            >
                                <Typography variant={"body2"} component={"p"}>Zonas de entrega</Typography>
                            </Link>
                        </li>
                        <li>
                            <Link to="/#contacto" smooth activeClassName={classes.active}
                                  scroll={el => scrollWithOffset(el, 20)}

                            >
                                <Typography variant={"body2"} component={"p"}>Contacto</Typography>
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Typography variant={"caption"} component={"a"} href={""}*/}
                        {/*                target={"_blank"}>*/}
                        {/*        Inicio*/}
                        {/*    </Typography>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Typography variant={"caption"} component={"a"} href={""}*/}
                        {/*                target={"_blank"}>*/}
                        {/*        ¿Quiénes Somos?*/}
                        {/*    </Typography>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Typography variant={"caption"} component={"a"} href={""}*/}
                        {/*                target={"_blank"}>*/}
                        {/*        ¿Cómo comprar?*/}
                        {/*    </Typography>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Typography variant={"caption"} component={"a"} href={""}*/}
                        {/*                target={"_blank"}>*/}
                        {/*        Promociones*/}
                        {/*    </Typography>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Typography variant={"caption"} component={"a"} href={""}*/}
                        {/*                target={"_blank"}>*/}
                        {/*        Zonas de entrega*/}
                        {/*    </Typography>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Typography variant={"caption"} component={"a"} href={""}*/}
                        {/*                target={"_blank"}>*/}
                        {/*        Contacto*/}
                        {/*    </Typography>*/}
                        {/*</li>*/}
                    </ul>
                </Box>
                <Box component={"div"} className={classes.columnFooter}
                     style={{display: "flex", flexGrow: "1", alignItems: "flex-end", justifyContent: "flex-end"}}>
                    <img src={dataFiscal} style={{maxWidth: "40px"}}/>
                </Box>
            </ContainerLimit>
        </footer>
    );
}
