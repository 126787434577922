import {denormalize, normalize, schema} from 'normalizr';

const stock = new schema.Entity('stocks', {}, {idAttribute: "id"});
const stockVariedad = new schema.Entity('stocksVariedad', {stock: stock}, {idAttribute: "id"});
const variedadCaracteristica = new schema.Entity('variedadesCaracteristica', {stock_variedad: [stockVariedad]}, {idAttribute: "id"});
const caracteristicaProducto = new schema.Entity('caracteristicasProducto', {variedad_caracteristica: [variedadCaracteristica]}, {idAttribute: "id"});
const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});
let productoOrigenPromocion = new schema.Entity("productosOrigenPromocion", {}, {
    idAttribute: "id"
});
const producto = new schema.Entity('productos', {
    marca: marca,
    producto_origen_promocion: [productoOrigenPromocion],
    caracteristica_producto: [caracteristicaProducto]
}, {idAttribute: "id"});

const categoria = new schema.Entity("categorias", {}, {
    idAttribute: "id"
});
productoOrigenPromocion.define({
    producto: producto
});

categoria.define({
    producto: [producto],
    categoria_hija: [categoria]
});

export function normalizeDatos(myData) {

    const mySchema = [categoria];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export function normalizeDato(myData) {

    const mySchema = categoria;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = categoria;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}