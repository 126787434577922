import React, { Component } from 'react';
//css
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
        campoTexto: {
            margin: "15px 0",
            width: "100%",
            "& label": {
                position: "relative",
                display: "block",
                width: "100%",
                minHeight: "45px",
            },
            "& .campoTexto-label": {
                position: "absolute",
                display: "block",
                top: "20px",
                zIndex: 2,
                transition: "all 200ms ease-in-out",
                width: "100%",
                cursor: "text",
                pointerEvents: "none",
                textAlign: "left",
                opacity: 0.6
            },

            "& input, & textarea": {
                position: "relative",
                top: "15px",
                zIndex: 1,
                width: "100%",
                border: 0,
                borderBottom: "1px solid",
                borderBottomColor: props => props.color ? props.color : "#000",
                transition: "border-color 200ms ease-in-out",
                outline: "none",
                padding: 0,
                margin: 0,
                backgroundColor: "transparent",
                "&:focus": {
                    borderBottomStyle: "solid",
                    borderBottomWidth: "1px",
                    borderBottomColor: props => props.borderColorFocus ? props.borderColorFocus : "#000",
                }
            },

            "& textarea": {
                minHeight: "30px",
                padding: "8px 0",
            },

            "& input": {
                height: "30px",
            },
            "& input:focus + .campoTexto-label, & textarea:focus + .campoTexto-label": {
                top: 0,
                cursor: "inherit",
                fontSize: "12px",
                color: props => props.color ? props.color : "#000",
                opacity: 1
            },
            "& .campoTexto-label.show, & .campoTexto-label.show": {
                top: 0,
                cursor: "inherit",
                fontSize: "12px",
                opacity: 1
            }
        }


    }))
;
export default function CampoTexto(props) {
    const classes = useStyles(props);
    let {
        id,
        nombre,
        type,
        img,
        color,
        value,
        handleChange,
        autoComplete,
        required,
        name,
        onBlur,
        disabled,
        borderColorFocus
    } = props;
    return (
        <div className={classes.campoTexto} id={"input-" + id}>
            <label>
                {/*{img}*/}
                <input type={type} id={id} name={name} className="campoTexto-input"
                       disabled={disabled ? disabled : false}
                       autoComplete="peppapig"
                       style={color ? {color: color} : {color: "inherit"}}
                       value={value}
                       onChange={(e) => {
                           if (handleChange) handleChange(e)
                       }} required={required}
                    // onBlur={(e) => {
                    //     if (onBlur) onBlur(e)
                    // }}
                       placeholder=""
                />

                {/*<div className="campoTexto-linea" style={(color ? {borderColor: color} : {borderColor: ""})}/>*/}
                <Typography variant={"body1"}
                            component={"span"}
                            className={`campoTexto-label ${!!value && value !== "" ? "show" : ""}`}
                            style={{color: props.color ? props.color : "#000"}}
                    // style={color ? {color: color} : {color: "inherit"}}
                >
                    {nombre}
                </Typography>
            </label>
        </div>
    );
}