import React, { useEffect } from 'react';
//Components
import Banners from "./Banners";
import CatalogoPromociones from "./CatalogoPromociones";
import QuienesSomos from "./QuienesSomos";
import Destacamos from "./Destacamos";
import ComoComprar from "./ComoComprar";
import Consultas from "./Consultas";
import ConsultaZonasEntrega from "./ConsultaZonasEntrega/ConsultaZonasEntrega";
import NuestrasMarcas from "./NuestrasMarcas";
import InformacionContacto from "./InformacionContacto";
import Contacto from "./Contacto";
import MensajeCorrecto from "../../Messages/MensajeCorrecto";
import MensajeError from "../../Messages/MensajeError";
import Footer from "../LayoutLanding/Footer/Footer";

export default function PaginaPrincipal() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banners/>
            <QuienesSomos/>
            <CatalogoPromociones/>
            <Destacamos/>
            <ComoComprar/>
            <Consultas/>
            <ConsultaZonasEntrega/>
            <NuestrasMarcas/>
            <InformacionContacto/>
            <Contacto/>
            <Footer/>
            <MensajeCorrecto/>
            <MensajeError/>
        </>
    );
}

