import {
    INVALIDATE_MONEDAS,
    ERROR_MONEDAS,
    RECEIVE_MONEDAS,
    REQUEST_MONEDAS,
    RESET_MONEDAS,
    ERROR_MONEDA,
    RECEIVE_MONEDA,
    REQUEST_MONEDA,
    UPDATE_MONEDA,
    REQUEST_UPDATE_MONEDA,
    SUCCESS_UPDATE_MONEDA,
    ERROR_UPDATE_MONEDA,
    RESET_UPDATE_MONEDA,
    REQUEST_UPDATE_MONEDAS,
    SUCCESS_UPDATE_MONEDAS,
    ERROR_UPDATE_MONEDAS,
    RESET_UPDATE_MONEDAS,
    CREATE_MONEDA,
    ERROR_CREATE_MONEDA,
    REQUEST_CREATE_MONEDA,
    RESET_CREATE_MONEDA,
    SUCCESS_CREATE_MONEDA,
    REQUEST_CREATE_MONEDAS,
    SUCCESS_CREATE_MONEDAS,
    ERROR_CREATE_MONEDAS,
    RESET_CREATE_MONEDAS,
    DELETE_MONEDA,
    DELETE_CREATE_MONEDA,
    DELETE_UPDATE_MONEDA,
    REQUEST_DELETE_MONEDA,
    SUCCESS_DELETE_MONEDA,
    ERROR_DELETE_MONEDA,
    RESET_DELETE_MONEDA,
    REQUEST_PRINT_MONEDA,
    SUCCESS_PRINT_MONEDA,
    ERROR_PRINT_MONEDA,
    RESET_PRINT_MONEDA
} from '../actions/MonedaActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        monedas: {},
    }
}

function monedasById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_MONEDAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_MONEDAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_MONEDAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_MONEDAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                monedas: {}
            });
        case RECEIVE_MONEDAS:
            let dato = action.monedas.entities.monedas;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                monedas: merge({}, state.monedas, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_MONEDA:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_MONEDA:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_MONEDA:
            let datoMoneda = action.moneda.entities.monedas;
            return Object.assign({}, state, {
                monedas: merge({}, state.monedas, datoMoneda),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_MONEDA:
            let datoMonedaEliminado = action.moneda.entities.monedas;
            return Object.assign({}, state, {
                monedas: mergeWith(clone(datoMonedaEliminado), state.monedas, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_MONEDA:
            let datoMonedaCreado = action.moneda.entities.monedas;
            return Object.assign({}, state, {
                monedas: mergeWith(clone(datoMonedaCreado), state.monedas, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_MONEDAS:
            let datosMonedaCreado = action.monedas.entities.monedas;
                return Object.assign({}, state, {
                    monedas: mergeWith(clone(datosMonedaCreado), state.monedas, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_MONEDA:
            let datoMonedaActualizado = action.moneda.entities.monedas;
            return Object.assign({}, state, {
                monedas: mergeWith(clone(datoMonedaActualizado), state.monedas, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_MONEDAS:
            let datosMonedaActualizado = action.monedas.entities.monedas;
                return Object.assign({}, state, {
                    monedas: mergeWith(clone(datosMonedaActualizado), state.monedas, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                monedas: {}
            });
        default:
            return state
    }
}


function allMonedas(state = [], action) {
    switch (action.type) {
        case RECEIVE_MONEDAS:
            return action.monedas.result && action.monedas.result.monedas ? union(action.monedas.result.monedas, state) : (action.monedas.result ? action.monedas.result : state) ;
        case RECEIVE_MONEDA:
                return action.moneda.result ? union([action.moneda.result], state) : state;
        
        case SUCCESS_CREATE_MONEDA:
                   let datoMonedaSCreate = action.moneda.entities.monedas;
                   let idNuevoSCreate = null;
                   if (Object.values(datoMonedaSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoMonedaSCreate)[0] && Object.values(datoMonedaSCreate)[0].id ? Object.values(datoMonedaSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_MONEDAS:
                   let monedasCreate = action.monedas.entities && action.monedas.entities.monedas ? action.monedas.entities.monedas : null;
                   return monedasCreate ?
                       union(state, Object.values(monedasCreate).map((monedas) => {
                           return monedas.id
                       })) : state;
        case RESET_MONEDAS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalMonedas(state = null, action) {
    switch (action.type) {
        case RECEIVE_MONEDAS:
            return action.monedas && action.monedas.result.total ? action.monedas.result.total : 0;
        case RESET_MONEDAS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_MONEDA:
            let dato = action.moneda.entities.monedas;
            let moneda = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: moneda ? moneda : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_MONEDA:
            let idsUpdate = [];
            Object.values(action.moneda).map((monedaUpdate) => {
                if (monedaUpdate && monedaUpdate.id)
                    idsUpdate.push(monedaUpdate.id);
            });
            return merge({}, state, {
                activo: action.moneda,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_MONEDA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_MONEDA:
            let datoMonedaActualizado = {};
            if (Object.values(action.moneda.entities.monedas).length > 0)
                datoMonedaActualizado = Object.values(action.moneda.entities.monedas)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoMonedaActualizado
            });
        case ERROR_UPDATE_MONEDA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_MONEDAS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_MONEDAS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_MONEDAS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_MONEDA:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_MONEDA:
            let datoMonedaDelete = action.moneda;
            let idsDelete = [];
           Object.values(action.moneda).map((monedaDelete) => {
               if (monedaDelete && monedaDelete.id)
                   idsDelete.push(monedaDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoMonedaDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_MONEDA:
                       let datoMonedaDeleteUpdate = action.moneda;
                       let idsDeleteUpdate = [];
                      Object.values(action.moneda).map((monedaDelete) => {
                          if (monedaDelete && monedaDelete.id)
                              idsDeleteUpdate.push(monedaDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoMonedaDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_MONEDA:
                    let datoMonedaDeleted = {};
                    if (Object.values(action.moneda.entities.monedas).length > 0)
                        datoMonedaDeleted = Object.values(action.moneda.entities.monedas)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoMonedaDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_MONEDA:
             let idsCreate = [];
             Object.values(action.moneda).map((monedaCreate) => {
                 if (monedaCreate && monedaCreate.id)
                     idsCreate.push(monedaCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.moneda,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_MONEDA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_MONEDA:
            let datoMonedaNuevo = {};
            if (Object.values(action.moneda.entities.monedas).length > 0)
                datoMonedaNuevo = Object.values(action.moneda.entities.monedas)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoMonedaNuevo,
                nuevos: []
            });
        case ERROR_CREATE_MONEDA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_MONEDAS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_MONEDAS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_MONEDAS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_MONEDA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_MONEDA:
           let datoMonedaDelete = action.moneda;
           let idsDelete = [];
           Object.values(action.moneda).map((monedaDelete) => {
               if (monedaDelete && monedaDelete.id)
                   idsDelete.push(monedaDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoMonedaDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_MONEDA:
                  let datoMonedaDeleteCreate = action.moneda;
                  let idsDeleteCreate = [];
                  Object.values(action.moneda).map((monedaDelete) => {
                      if (monedaDelete && monedaDelete.id)
                          idsDeleteCreate.push(monedaDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoMonedaDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_MONEDA:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.moneda,
                error: null,
            });
        case REQUEST_DELETE_MONEDA:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_MONEDA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_MONEDA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_MONEDA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_MONEDA:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_MONEDA:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_MONEDA:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const monedas = combineReducers({
    byId: monedasById,
    allIds: allMonedas,
    update: update,
    create: create,
    totalMonedas: totalMonedas,
    delete: deleter,
    print: print
});

export default monedas;