import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Actions
//Components
import { Box, Button, Typography } from "@material-ui/core";
import ContainerLimit from "../../../customComponents/ContainerLimit";

//Icons
import bgZonas from "../../../assets/img/zonas/bgZonas.png";
import jvaResponde from "../../../assets/img/consultas/jvaResponde.png";
import manoWhatsApp from "../../../assets/img/consultas/manoWhatsApp.png";
import haceConsultas from "../../../assets/img/consultas/haceConsultas.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchZonasEntregaIfNeeded } from "../../../actions/ZonaEntregaActions";
import MapWithASearchBox from "./MapWithASearchBox";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "transparent",
        backgroundImage: `url(${bgZonas})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        paddingTop: "110px",
        paddingBottom: "150px",
        position: "relative",
        [theme.breakpoints.down(714)]: {
            backgroundPositionY: "-100px",
            top: "-90px",
            backgroundColor: "transparent",
            zIndex: "9"
        },
        [theme.breakpoints.down(600)]: {
            top: "-160px",
        },
        [theme.breakpoints.up(3000)]: {
            backgroundPositionY: "-1090px",
        },
    },
    containerConsultas: {
        display: "flex",
        alignItems: "center",
        "& img": {
            width: "100%"
        }
    },
    mensaje: {
        display: "none"
    }
}));


export default function ConsultaZonasEntrega() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const zonasEntrega = useSelector(state => state.zonasEntrega);

    useEffect(() => {
        dispatch(fetchZonasEntregaIfNeeded({}));
    }, []);

    return (
        <Box component={"div"} className={classes.container} id={"zonasEntrega"}>
            <MapWithASearchBox/>
        </Box>
    );
}
