import {normalize, schema, denormalize} from 'normalizr';
import productoDestinoPromocion from "./normalizeProductosDestinoPromocion";
import productoOrigenPromocion from "./normalizeProductosOrigenPromocion";

const promocion = new schema.Entity('promociones', {
    producto_destino_promocion: [productoDestinoPromocion],
    producto_origen_promocion: [productoOrigenPromocion],
}, {idAttribute: "id"});

export function normalizeDatos(myData) {
    const mySchema = {promociones: [promocion]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {
    const mySchema = promocion;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {
    const mySchema = promocion;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default promocion;

