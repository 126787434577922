import React from 'react';
import {Box, Typography} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';

const CustomRibbon = withStyles((theme) => ({
    root: {
        width: "85px",
        height: "88px",
        overflow: "hidden",
        position: "absolute",
        top: "-3px",
        right: "-3px",
        "& .ribbon": {
            textAlign: "center",
            transform: "rotate(45deg)",
            position: "relative",
            padding: "7px 0",
            [theme.breakpoints.down(975)]: {
                padding: "3px 0",
            },
            color: theme.palette.primary["contrastText"],
            backgroundColor: theme.palette.primary[theme.palette.type],
            left: "-5px",
            top: "15px",
            width: "120px",
            boxShadow: theme.shadows[3],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 6,
            "& svg": {
                fontSize: "0.75rem",
            },
            "&:before, &:after": {
                content: "",
                borderTop: "3px solid",
                borderTopColor: theme.palette.primary[theme.palette.type],
                borderLeft: "3px solid transparent",
                borderRight: "3px solid transparent",
                position: "absolute",
                bottom: "-3px",
            },

            "&:before": {
                left: 0,
            },

            "&:after": {
                right: 0,
            }
        }


    },
}))(Box);


export default function Ribbon(props) {
    return (
        <CustomRibbon {...props}>
            <Typography variant={"caption"} component={"div"} className={"ribbon"}
                        style={{fontWeight: "300", lineHeight: "inherit"}}>
                {props.children}
            </Typography>
        </CustomRibbon>
    )
}

