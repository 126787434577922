import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

//Components
import {Box, CardActionArea, Divider} from "@material-ui/core";
import {green} from "@material-ui/core/colors";

//Icons
import {isNil} from "lodash";
import ImagenProducto from "./componentes/ImagenProducto";
import InformacionProducto from "./componentes/InformacionProducto";
import AddCartProducto from "./componentes/AddCartProducto";
//Icons
import RedeemIcon from '@material-ui/icons/Redeem';

import calculoPreciosProducto from "../../utils/calculoPreciosProducto";
import clsx from "clsx";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    producto: {
        position: "relative",
        width: "fit-content",
        margin: theme.spacing(2, 1),
        [theme.breakpoints.down(975)]: {
            margin: "16px 4px",
        },
        height: "auto",
    },
    wrapperProducto: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "14px",
        overflow: "hidden",
        boxShadow: theme.shadows[4],
        width: "284px",
        [theme.breakpoints.down(975)]: {
            width: "200px",
        },
        // width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",

    },
    bodyProducto: {
        padding: theme.spacing(2),
        [theme.breakpoints.down(975)]: {
            padding: theme.spacing(1),
        },
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        justifyContent: "flex-start"
    },
}));

function areEqual(prevProps, nextProps) {
    /*
    retorna true si al pasar los nextProps a renderizar retorna
    el mismo resultado que al pasar los prevProps a renderizar,
    de otro modo retorna false
    */
    return true;
}

export default React.memo(function ProductoPromocionCombo(props) {

    const classes = useStyles();

    const {productos, producto, promocion, moneda, productoOrigenPromocion, productosOrigenPromocion} = props;

    // const productosOrigenPromocion = useSelector(state => state.productosOrigenPromocion);

    //Calculo de precios
    let valores = calculoPreciosProducto.calcularPreciosCombo(productos, moneda, productosOrigenPromocion, promocion);

    return (
        <Box component={"div"} className={clsx(["swiper-slide",classes.producto])}>
            <Box component={"div"} className={classes.wrapperProducto}>
                <ImagenProducto imgProducto={producto ? producto.imgProducto : ""}/>

                <Box component={"div"} className={classes.bodyProducto}>
                    <InformacionProducto precioAnterior={promocion.tipoPromocion === "Descuento" ? valores.precioAnterior : ""}
                                         leyendaPrecioAnterior={promocion.tipoPromocion === "Descuento" ? valores.leyendaPrecioAnterior : ""}
                                         precioActual={valores.precioActual}
                                         chip={valores.porcentaje ? (valores.porcentaje + "% OFF") : (promocion && promocion.resultadoPromocion !== "" ? promocion.resultadoPromocion : null)}
                                         nomProducto={promocion ? promocion.nomPromocion : ""}
                                         presentacion={producto ? producto.presentacion : ""}
                                         ribbon={"Combo"}
                                         desPromocion={promocion && promocion.desResultadoPromocion ? promocion.desResultadoPromocion : null}
                                         simbolo={moneda ? moneda.simbolo : null}/>
                    {/*<AddCartProducto/>*/}
                </Box>
            </Box>
        </Box>
    );
}, areEqual);
