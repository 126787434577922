//api
import productosApi from "../api/productosApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProductos";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PRODUCTOS
export const REQUEST_PRODUCTOS = 'REQUEST_PRODUCTOS';
export const RECEIVE_PRODUCTOS = 'RECEIVE_PRODUCTOS';
export const INVALIDATE_PRODUCTOS = 'INVALIDATE_PRODUCTOS';
export const ERROR_PRODUCTOS = "ERROR_PRODUCTOS";
export const RESET_PRODUCTOS = "RESET_PRODUCTOS";


export function invalidateProductos() {
    return {
        type: INVALIDATE_PRODUCTOS
    }
}

function requestProductos() {
    return {
        type: REQUEST_PRODUCTOS,
    }
}

function receiveProductos(json) {
    return {
        type: RECEIVE_PRODUCTOS,
    productos: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProductos(error) {
    return {
        type: ERROR_PRODUCTOS,
        error: error,
    }
}

export function resetProductos() {
    return {
        type: RESET_PRODUCTOS
    }
}

export function fetchProductos(filtros) {
    return dispatch => {
        dispatch(requestProductos());
        return productosApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductos(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductos(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductos(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProductos(state) {
    const productos = state.productos.byId;
    if (!productos) {
        return true
    } else if (productos.isFetching) {
        return false
    } else {
        return productos.didInvalidate;
    }
}

export function fetchProductosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProductos(getState())) {
            return dispatch(fetchProductos(filtros))
        }
    }
}


//MODEL
export const REQUEST_PRODUCTO = 'REQUEST_PRODUCTO';
export const RECEIVE_PRODUCTO = 'RECEIVE_PRODUCTO';
export const INVALIDATE_PRODUCTO = 'INVALIDATE_PRODUCTO';
export const ERROR_PRODUCTO = "ERROR_PRODUCTO";
export const RESET_PRODUCTO = "RESET_PRODUCTO";


export function invalidateProducto() {
    return {
        type: INVALIDATE_PRODUCTO
    }
}

function requestProducto() {
    return {
        type: REQUEST_PRODUCTO,
    }
}

export function receiveProducto(json) {
    return {
        type: RECEIVE_PRODUCTO,
    producto: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProducto(error) {
    return {
        type: ERROR_PRODUCTO,
        error: error,
    }
}

export function fetchProducto(idProducto) {
    return dispatch => {
        dispatch(requestProducto());
        return productosApi.getOne(idProducto)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProducto(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProducto(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProducto(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_PRODUCTO = 'UPDATE_PRODUCTO';
export const REQUEST_UPDATE_PRODUCTO = "REQUEST_UPDATE_PRODUCTO";
export const SUCCESS_UPDATE_PRODUCTO = "SUCCESS_UPDATE_PRODUCTO";
export const ERROR_UPDATE_PRODUCTO = "ERROR_UPDATE_PRODUCTO";
export const RESET_UPDATE_PRODUCTO = "RESET_UPDATE_PRODUCTO";
export const DELETE_UPDATE_PRODUCTO = "DELETE_UPDATE_PRODUCTO";

function requestUpdateProducto() {
    return {
        type: REQUEST_UPDATE_PRODUCTO,
    }
}

function receiveUpdateProducto(producto) {
    return {
        type: SUCCESS_UPDATE_PRODUCTO,
        receivedAt: Date.now(),
        producto: normalizeDato(producto)
    }
}

function errorUpdateProducto(error) {
    return {
        type: ERROR_UPDATE_PRODUCTO,
        error: error,
    }
}

export function resetUpdateProducto() {
    return {
        type: RESET_UPDATE_PRODUCTO,
    }
}

export function updateProducto(producto) {
    return {
        type: UPDATE_PRODUCTO,
        producto
    }
}

export function saveUpdateProducto(files) {
    return (dispatch, getState) => {
        dispatch(requestUpdateProducto());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let producto = denormalizeDato(getState().productos.update.activo, store);

        return productosApi.saveUpdate(producto, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveUpdateProducto(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProducto(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateProductos(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateProductos(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProducto(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                    dispatch(receiveUpdateProducto(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateProducto(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateProducto(producto) {
    return {
        type: DELETE_UPDATE_PRODUCTO,
        producto
    }
}

//UPDATE PRODUCTOS
export const REQUEST_UPDATE_PRODUCTOS = "REQUEST_UPDATE_PRODUCTOS";
export const SUCCESS_UPDATE_PRODUCTOS = "SUCCESS_UPDATE_PRODUCTOS";
export const ERROR_UPDATE_PRODUCTOS = "ERROR_UPDATE_PRODUCTOS";
export const RESET_UPDATE_PRODUCTOS = "RESET_UPDATE_PRODUCTOS";

function requestUpdateProductos() {
    return {
        type: REQUEST_UPDATE_PRODUCTOS,
}
}

function receiveUpdateProductos(productos) {
    return {
        type: SUCCESS_UPDATE_PRODUCTOS,
    receivedAt: Date.now(),
        productos: normalizeDatos(productos)
}
}

function errorUpdateProductos(error) {
    return {
        type: ERROR_UPDATE_PRODUCTOS,
    error: error,
}
}

export function resetUpdateProductos() {
    return {
        type: RESET_UPDATE_PRODUCTOS,
}
}

export function saveUpdateProductos() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductos());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productos = getState().productos.update.activos.map((idProducto) => {
            return denormalizeDato(getState().productos.update.activo[idProducto], store);
        });

        return productosApi.saveUpdateProductos(productos)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductos(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProductos(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateProductos(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateProductos(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateProductos(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateProductos(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PRODUCTO
export const CREATE_PRODUCTO = 'CREATE_PRODUCTO';
export const REQUEST_CREATE_PRODUCTO = "REQUEST_CREATE_PRODUCTO";
export const SUCCESS_CREATE_PRODUCTO = "SUCCESS_CREATE_PRODUCTO";
export const ERROR_CREATE_PRODUCTO = "ERROR_CREATE_PRODUCTO";
export const RESET_CREATE_PRODUCTO = "RESET_CREATE_PRODUCTO";
export const DELETE_CREATE_PRODUCTO = "DELETE_CREATE_PRODUCTO";


//ALTA PRODUCTO
function requestCreateProducto() {
    return {
        type: REQUEST_CREATE_PRODUCTO,
    }
}

function receiveCreateProducto(producto) {
    return {
        type: SUCCESS_CREATE_PRODUCTO,
        receivedAt: Date.now(),
        producto: normalizeDato(producto)
    }
}

export function errorCreateProducto(error) {
    return {
        type: ERROR_CREATE_PRODUCTO,
        error: error,
    }
}

export function resetCreateProducto() {
    return {
        type: RESET_CREATE_PRODUCTO
    }
}

export function createProducto(producto) {
    return {
        type: CREATE_PRODUCTO,
        producto
    }
}

export function deleteCreateProducto(producto) {
    return {
        type: DELETE_CREATE_PRODUCTO,
        producto
    }
}

export function saveCreateProducto(files) {
    return (dispatch, getState) => {
        dispatch(requestCreateProducto());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let producto = denormalizeDato(getState().productos.create.nuevo, store);

        return productosApi.saveCreate(producto, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveCreateProducto(data));
                }
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProducto(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateProductos(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateProductos(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateProducto(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateProducto(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateProducto(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PRODUCTOS
export const REQUEST_CREATE_PRODUCTOS = "REQUEST_CREATE_PRODUCTOS";
export const SUCCESS_CREATE_PRODUCTOS = "SUCCESS_CREATE_PRODUCTOS";
export const ERROR_CREATE_PRODUCTOS = "ERROR_CREATE_PRODUCTOS";
export const RESET_CREATE_PRODUCTOS = "RESET_CREATE_PRODUCTOS";

function requestCreateProductos() {
    return {
        type: REQUEST_CREATE_PRODUCTOS,
}
}

function receiveCreateProductos(productos) {
    return {
        type: SUCCESS_CREATE_PRODUCTOS,
    receivedAt: Date.now(),
        productos: normalizeDatos(productos)
}
}

function errorCreateProductos(error) {
    return {
        type: ERROR_CREATE_PRODUCTOS,
    error: error,
}
}

export function resetCreateProductos() {
    return {
        type: RESET_CREATE_PRODUCTOS,
}
}

export function saveCreateProductos() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductos());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productos = getState().productos.create.nuevos.map((idProducto) => {
            return denormalizeDato(getState().productos.create.nuevo[idProducto], store);
        });

        return productosApi.saveCreateProductos(productos)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductos(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateProductos(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateProductos(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateProductos(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateProductos(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateProductos(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PRODUCTO
export const DELETE_PRODUCTO = 'DELETE_PRODUCTO';
export const REQUEST_DELETE_PRODUCTO = "REQUEST_DELETE_PRODUCTO";
export const SUCCESS_DELETE_PRODUCTO = "SUCCESS_DELETE_PRODUCTO";
export const ERROR_DELETE_PRODUCTO = "ERROR_DELETE_PRODUCTO";
export const RESET_DELETE_PRODUCTO = "RESET_DELETE_PRODUCTO";

function requestDeleteProducto() {
    return {
        type: REQUEST_DELETE_PRODUCTO,
    }
}

function receiveDeleteProducto(producto) {
    return {
        type: SUCCESS_DELETE_PRODUCTO,
        receivedAt: Date.now(),
        producto: normalizeDato(producto)
    }
}

function errorDeleteProducto(error) {
    return {
        type: ERROR_DELETE_PRODUCTO,
        error: error,
    }
}

export function resetDeleteProducto(error) {
    return {
        type: RESET_DELETE_PRODUCTO,
        error: error,
    }
}

export function deleteProducto(producto) {
    return {
        type: DELETE_PRODUCTO,
        producto
    }
}

export function saveDeleteProducto(producto) {
    return dispatch => {
        dispatch(requestDeleteProducto());
        return productosApi.saveDelete(producto)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProducto());
                            dispatch(receiveDeleteProducto(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProducto(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteProducto(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteProducto(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProducto(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteProducto(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PRODUCTO
export const REQUEST_PRINT_PRODUCTO = "REQUEST_PRINT_PRODUCTO";
export const SUCCESS_PRINT_PRODUCTO = "SUCCESS_PRINT_PRODUCTO";
export const ERROR_PRINT_PRODUCTO = "ERROR_PRINT_PRODUCTO";
export const RESET_PRINT_PRODUCTO = "RESET_PRINT_PRODUCTO";

function requestPrintProducto() {
    return {
        type: REQUEST_PRINT_PRODUCTO,
    }
}

function receivePrintProducto(turnos) {
    return {
        type: SUCCESS_PRINT_PRODUCTO,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintProducto(error) {
    return {
        type: ERROR_PRINT_PRODUCTO,
        error: error,
    }
}

export function resetPrintProducto() {
    return {
        type: RESET_PRINT_PRODUCTO,
    }
}

export function printProducto(idProducto) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintProducto());
        return productosApi.printProducto(idProducto)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintProducto(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintProducto(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintProducto(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintProducto(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintProducto(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}