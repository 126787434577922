import { combineReducers } from 'redux'
import categorias from '../reducers/categorias';
import zonasEntrega from '../reducers/zonasEntrega';
import promociones from '../reducers/promociones';
import productos from '../reducers/productos';
import productosDestinoPromocion from '../reducers/productosDestinoPromocion';
import productosOrigenPromocion from '../reducers/productosOrigenPromocion';
import tiendas from '../reducers/tiendas';
import monedas from '../reducers/monedas';
import marcas from '../reducers/marcas';
import contactos from '../reducers/contactos';
import banners from '../reducers/banners';

const appReducers = combineReducers({
    banners,
    categorias,
    contactos,
    marcas,
    monedas,
    promociones,
    productos,
    productosDestinoPromocion,
    productosOrigenPromocion,
    tiendas,
    zonasEntrega
});

export default appReducers;