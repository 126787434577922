import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import * as PropTypes from "prop-types";
import {Link, useHistory} from "react-router-dom";

//Components
import ContainerLimit from "../../customComponents/ContainerLimit";
import CarrouselDestacados from "../../customComponents/Carruseles/CarrouselDestacados";
import Producto from "../Productos/Producto";
import {Box, Button, Typography, useMediaQuery} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import {fetchPromocionesIfNeeded} from "../../actions/PromocionActions";
import {fetchCategorias} from "../../actions/CategoriaActions";
import Line from "../../customComponents/Line";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import SkeletonRowProducto from "../Skeletons/SkeletonRowProducto";
import Footer from "../LayoutLanding/Footer/Footer";
import medidasUtil from "../../utils/medidas";

const useStyles = makeStyles((theme) => {
    return ({
        container: {
            backgroundColor: theme.palette.background.default,
            paddingTop: "10px",
            // paddingBottom: "30px",
            maxWidth: "100vw"
        },
        onlyComponent: {
            paddingTop: "120px",
            minHeight: "calc(100vh - 250px)",
            [theme.breakpoints.down(975)]: {
                paddingTop: "70px",
                minHeight: "calc(100vh - 390px)",
            }
        },
        containerTitulo: {
            paddingBottom: "25px",
            [theme.breakpoints.down(714)]: {
                paddingBottom: "0px",
            }
        },
        rowProductos: {
            position: "relative",
            maxWidth: "1220px",
            width: "100%",
            margin: "24px auto",
            "&:not(:first-child)": {
                marginTop: "50px",
            }
        },
        containerTituloCategoria: {
            padding: theme.spacing(0, 1),
            display: "flex",
            flexWrap: "wrap",
        },
        btnVerMas: {
            color: blue[500],
            textTransform: "inherit",
            marginLeft: theme.spacing(1),
            padding: "0 3px",
            "& a": {
                all: "unset"
            }

        }
    });
});

function RowCategoria(props) {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:975px)');
    const history = useHistory();
    const [showBtnVerMas, setShowBtnVerMas] = useState(true);

    let pathname = null;
    useEffect(() => {
        pathname = history.location.pathname
        setShowBtnVerMas(pathname !== "/promociones");
    }, [pathname]);


    return <div className={classes.rowProductos} key={props.i + "-cat"}>
        <div className={classes.containerTituloCategoria}>
            <Typography variant={"h4"} component={"h4"} style={{color: "#003462"}}>
                {props.titulo}
                {
                    showBtnVerMas ?
                        <span>
                        <Button className={classes.btnVerMas}>
                            <Link to={"/promociones"}>Ver más</Link>
                        </Button>
                    </span>
                        :
                        ""
                }
            </Typography>

        </div>
        {
            !isMobile &&
            <CarrouselDestacados>
                {props.productosPromocion}
            </CarrouselDestacados>

        }
        {
            isMobile &&
            <div style={{overflowX: "auto", margin: 0, display: "flex", padding: 0}}>
                {props.productosPromocion}
            </div>
        }
    </div>;
}

RowCategoria.propTypes = {
    i: PropTypes.number,
    titulo: PropTypes.string,
    productosPromocion: PropTypes.any
};

export default function CatalogoPromociones() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const promociones = useSelector(state => state.promociones);
    const productosOrigenPromocion = useSelector(state => state.productosOrigenPromocion);
    const productos = useSelector(state => state.productos);
    const categorias = useSelector(state => state.categorias);
    const history = useHistory();

    let pathname = history.location.pathname

    useEffect(() => {
        if (pathname === "/promociones") {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    useEffect(() => {
        dispatch(fetchPromocionesIfNeeded({estado: 1}));
        dispatch(fetchCategorias({orden: 1, publicar: true}));
    }, []);

    const getCategoriaPrincipal = (producto) => {
        let categoria = categorias.byId.categorias[producto.idCategoria];
        let idCategoriaPadre = categoria ? categoria.idCategoriaPadre : null;
        while (idCategoriaPadre) {
            categoria = categorias.byId.categorias[idCategoriaPadre];
            idCategoriaPadre = categoria ? categoria.idCategoriaPadre : null;
        }
        return categoria;
    }

    let arrayFila = [];
    let arrayCategorias = new Set();
    let titulo = "";
    let Rows = [];
    promociones.allIds.forEach((idPromocion, i) => {
        let promocion = promociones.byId.promociones[idPromocion];
        let Productos = [];
        let categoria = null;
        if (promocion.formatoPromocion === "Producto individual" || promocion.formatoPromocion === "Compra por volumen")
            Productos = promocion.producto_origen_promocion.map((idProductoOrigenPromocion, i) => {
                let productoOrigenPromocion = productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                let producto = null;
                if (productoOrigenPromocion)
                    producto = productos.byId.productos[productoOrigenPromocion.idProducto];
                if (producto) {
                    //Categoria
                    if (i === 0)
                        categoria = getCategoriaPrincipal(producto);
                    return <Producto producto={producto} promocion={promocion}
                                     productoOrigenPromocion={productoOrigenPromocion}/>
                }

            })
        else if (promocion.formatoPromocion === "Combo") {
            let productosOrigenPromocionProd = [];
            let productosProd = promocion.producto_origen_promocion.map((idProductoOrigenPromocion, i) => {
                let productoOrigenPromocion = productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                productosOrigenPromocionProd.push(productoOrigenPromocion);
                let producto = null;
                if (productoOrigenPromocion)
                    producto = productos.byId.productos[productoOrigenPromocion.idProducto];
                if (producto) {
                    //Categoria
                    if (i === 0)
                        categoria = getCategoriaPrincipal(producto);
                    return producto;
                }
            })
            Productos.push(<Producto productos={productosProd} promocion={promocion}
                                     productosOrigenPromocion={productosOrigenPromocionProd}/>);
        }
        if (Productos.length > 1) {
            let promocion = Productos[0].props.promocion;
            let producto = Productos[0].props.producto;
            //Medida
            let medida = null;
            if (Productos.length === 1) {
                medida = Productos[0].props.producto.medida;
            } else {
                Productos.some((Producto) => {
                    if (!medida)
                        medida = Producto.props.producto.medida;
                    if (Producto.props.producto.medida !== medida) {
                        medida = null;
                        return true;
                    }
                    return false;
                })
            }
            if (promocion.cantidad)
                titulo = `Llevando ${promocion.cantidad} ${medida ? medidasUtil.getDesUnidadMedida(medida, promocion.cantidad) : ""} de cualquiera de estos productos`;
            else if (promocion.cantidadesVolumen) {
                let arrayCant = JSON.parse(promocion.cantidadesVolumen);
                let cant = arrayCant.join(" o ");
                titulo = `Llevando ${cant} ${medida ? medidasUtil.getDesUnidadMedida(medida, 2) : ""} de cualquiera de estos productos`;
            }
            Rows.push(
                <RowCategoria titulo={titulo}
                              productosPromocion={Productos} i={i}/>);
        } else {
            if (Productos[0])
                arrayFila.push(Productos[0]);
            if (categoria)
                arrayCategorias.add(categoria);
        }
        if (arrayFila.length === 4 || i === promociones.allIds.length - 1) {
            if (arrayCategorias.size <= 2 && arrayCategorias.size > 0) {
                titulo = "Ofertas en ";
                let ind = 0;
                arrayCategorias.forEach((categoria) => {
                    titulo = titulo + (ind >= 1 ? " y " : " ") + categoria.desCategoria;
                    ind++;
                });
            } else
                titulo = "Ofertas especiales";
            Rows.push(
                <RowCategoria titulo={titulo} i={i}
                              productosPromocion={arrayFila}/>
            );
        }
        if (arrayFila.length === 4) {
            arrayFila = [];
            arrayCategorias = new Set();
        }
    });

    return (
        <div className={clsx([classes.container, {[classes.onlyComponent]: pathname === "/promociones"}])}>
            <ContainerLimit className={classes.container}>
                <Box component={"div"} className={classes.containerTitulo}>
                    <Typography variant={"h1"} align={"center"} style={{color: "#003462"}}>
                        Promociones
                    </Typography>
                    <Line width={"70px"} height={"20px"} align={"center"} borderBottomColor={"#59c4c6"}
                          borderBottomWidth={"3px"}/>
                </Box>
                {
                    !promociones.byId.isFetching ?
                        Rows.length === 0 ?
                            <Typography variant={"h4"} style={{paddingTop: "8px", paddingLeft: "16px"}}>
                                No hay ninguna promoción ☹️
                            </Typography>
                            : Rows
                        :
                        <>
                            <SkeletonRowProducto/>
                            <SkeletonRowProducto/>
                        </>
                }
            </ContainerLimit>
        </div>
    );
}
