import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

//Components
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {
    Avatar,
    Box,
    Chip,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from "@material-ui/core";
import NumberFormated from "../../customComponents/NumberFormated";

//Icons
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';


const useStyles = makeStyles((theme) => ({
    listItem: {
        borderRadius: "14px",
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0),
        "&:first-child": {
            marginTop: theme.spacing(1),
        },
        flexWrap: "wrap",
        "& .MuiListItemText-root": {
            flex: 1,
            flexGrow: 1,
            flexBasis: "80%",
        }
    },
    wrapperImg: {
        height: "100%",
        alignSelf: "baseline",
        marginTop: theme.spacing(2),
        "& .MuiAvatar-root": {
            maxWidth: "45px",
            width: "100%",
            height: "inherit",
        },
        "& .MuiAvatar-img": {
            objectFit: "contain"
        }

    },
    headerItem: {
        position: "relative",
        paddingRight: "28px",
        paddingTop: "3px",
    },
    btnDelItem: {
        position: "absolute",
        right: "-2px",
        top: "-9px",

    },
    bodyItem: {
        "& .descripcion": {
            color: theme.palette.text.secondary,
        },
        color: theme.palette.text.primary,
    },
    wrapperUnitario: {
        marginTop: theme.spacing(1),
        marginBottom: "3px",
    },
    chipOff: {
        margin: theme.spacing(0, 1),
        fontSize: ".8rem"
    },
    footerItem: {
        flexGrow: 1,
        flexBasis: "100%",
        flex: 2,
    },
    tfCantidad: {
        width: "125px",
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            padding: "0 7px",
        },
        "& .MuiInputBase-input": {
            textAlign: "center",
            padding: theme.spacing(1, 0),
        }
    },
    wrapperFooter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: theme.spacing(1),

    },
    nombreProducto: {
        display: "-webkit-box",
        lineClamp: 2,
        boxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
}));

function areEqual(prevProps, nextProps) {
    /*
    retorna true si al pasar los nextProps a renderizar retorna
    el mismo resultado que al pasar los prevProps a renderizar,
    de otro modo retorna false
    */
    return prevProps.idDetallePedido === nextProps.idDetallePedido;
}

export default React.memo(function ItemCarrito(props) {
    const classes = useStyles(props);

    return (
        <ListItem className={classes.listItem}>
            <ListItemAvatar className={classes.wrapperImg}>
                <Avatar variant={"square"}
                        src="https://almacenfamily.com/productos/7613032396350-2678-5eeae0bbdbc58.png"
                />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box component={"div"} className={classes.headerItem}>
                        <Typography variant={"body2"} component={"p"} className={classes.nombreProducto}>
                            Cápsulas de café Cappuccino Nescafé Dolce gusto
                        </Typography>
                        <IconButton size={"small"} className={classes.btnDelItem}>
                            <CloseOutlinedIcon style={{width: "18px", height: "18px"}}/>
                        </IconButton>
                    </Box>
                }
                secondary={
                    <Box component={"span"} className={classes.bodyItem}>
                        <Typography variant={"caption"} component={"span"} className={"descripcion"}>
                            Botella de 750cc
                        </Typography>
                        <Box component={"span"} className={classes.wrapperUnitario} display={"flex"}
                             alignItems={"center"}>
                            <NumberFormated enteroSize={".9rem"} decimalSize={".5rem"} simbolo={"$"} tachado>
                                1933.32
                            </NumberFormated>
                            <Chip label={"-15%"}
                                  size={"small"}
                                  variant={"outlined"}
                                  color={"primary"}
                                  className={classes.chipOff}
                                  component={"span"}/>
                            <NumberFormated enteroSize={"1rem"} decimalSize={".65rem"} simbolo={"$"}>
                                9999.99
                            </NumberFormated>
                        </Box>
                    </Box>
                }
            />
            <Box component={"div"} className={classes.footerItem}>
                <Divider style={{marginBottom: "3px"}}/>
                <Box className={classes.wrapperFooter} component={"div"}>
                    <TextField
                        variant={"outlined"}
                        margin={"none"}
                        size={"small"}
                        className={classes.tfCantidad}
                        value={"123"}
                        InputProps={{
                            startAdornment:
                                <IconButton size={"small"} color={"primary"}>
                                    <RemoveCircleRoundedIcon style={{width: "20px", height: "20px"}}/>
                                </IconButton>,
                            endAdornment:
                                <IconButton size={"small"} color={"primary"}>
                                    <AddCircleRoundedIcon style={{width: "20px", height: "20px"}}/>
                                </IconButton>
                        }}
                    />
                    <NumberFormated enteroSize={"1.2rem"} decimalSize={".83rem"} simbolo={"$"}>
                        323232.21
                    </NumberFormated>

                </Box>
            </Box>
        </ListItem>
    );
}, areEqual);

