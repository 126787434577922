import {
    INVALIDATE_PRODUCTOS,
    ERROR_PRODUCTOS,
    RECEIVE_PRODUCTOS,
    REQUEST_PRODUCTOS,
    RESET_PRODUCTOS,
    ERROR_PRODUCTO,
    RECEIVE_PRODUCTO,
    REQUEST_PRODUCTO,
    UPDATE_PRODUCTO,
    REQUEST_UPDATE_PRODUCTO,
    SUCCESS_UPDATE_PRODUCTO,
    ERROR_UPDATE_PRODUCTO,
    RESET_UPDATE_PRODUCTO,
    REQUEST_UPDATE_PRODUCTOS,
    SUCCESS_UPDATE_PRODUCTOS,
    ERROR_UPDATE_PRODUCTOS,
    RESET_UPDATE_PRODUCTOS,
    CREATE_PRODUCTO,
    ERROR_CREATE_PRODUCTO,
    REQUEST_CREATE_PRODUCTO,
    RESET_CREATE_PRODUCTO,
    SUCCESS_CREATE_PRODUCTO,
    REQUEST_CREATE_PRODUCTOS,
    SUCCESS_CREATE_PRODUCTOS,
    ERROR_CREATE_PRODUCTOS,
    RESET_CREATE_PRODUCTOS,
    DELETE_PRODUCTO,
    DELETE_CREATE_PRODUCTO,
    DELETE_UPDATE_PRODUCTO,
    REQUEST_DELETE_PRODUCTO,
    SUCCESS_DELETE_PRODUCTO,
    ERROR_DELETE_PRODUCTO,
    RESET_DELETE_PRODUCTO,
    REQUEST_PRINT_PRODUCTO,
    SUCCESS_PRINT_PRODUCTO,
    ERROR_PRINT_PRODUCTO,
    RESET_PRINT_PRODUCTO
} from '../actions/ProductoActions';

import {
    RECEIVE_PROMOCION,
    RECEIVE_PROMOCIONES,
    SUCCESS_DELETE_PROMOCION,
    SUCCESS_CREATE_PROMOCION,
    SUCCESS_UPDATE_PROMOCION,
    SUCCESS_UPDATE_PROMOCIONES

} from '../actions/PromocionActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        productos: {},
    }
}

function productosById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PRODUCTOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                productos: {}
            });
        case RECEIVE_PRODUCTOS:
            let dato = action.productos.entities.productos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productos: merge({}, state.productos, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PRODUCTO:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PRODUCTO:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PRODUCTO:
            let datoProducto = action.producto.entities.productos;
            return Object.assign({}, state, {
                productos: merge({}, state.productos, datoProducto),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_PRODUCTO:
            let datoProductoEliminado = action.producto.entities.productos;
            return Object.assign({}, state, {
                productos: mergeWith(clone(datoProductoEliminado), state.productos, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTO:
            let datoProductoCreado = action.producto.entities.productos;
            return Object.assign({}, state, {
                productos: mergeWith(clone(datoProductoCreado), state.productos, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PRODUCTOS:
            let datosProductoCreado = action.productos.entities.productos;
                return Object.assign({}, state, {
                    productos: mergeWith(clone(datosProductoCreado), state.productos, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PRODUCTO:
            let datoProductoActualizado = action.producto.entities.productos;
            return Object.assign({}, state, {
                productos: mergeWith(clone(datoProductoActualizado), state.productos, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PRODUCTOS:
            let datosProductoActualizado = action.productos.entities.productos;
                return Object.assign({}, state, {
                    productos: mergeWith(clone(datosProductoActualizado), state.productos, (objValue, srcValue) => {return objValue;})
                });

            //PROMOCION
case RECEIVE_PROMOCION:
    let promocion = action.promocion.entities && action.promocion.entities.productos ? action.promocion.entities.productos : {};
    return Object.assign({}, state, {
        productos: merge({}, state.productos, promocion),
    });
case RECEIVE_PROMOCIONES:
    let promociones = action.promociones.entities && action.promociones.entities.productos ? action.promociones.entities.productos : {};
    return Object.assign({}, state, {
        productos: merge({}, state.productos, promociones),
    });
case SUCCESS_DELETE_PROMOCION:
    let datopromocionEliminado = action.promocion.entities && action.promocion.entities.productos ? action.promocion.entities.productos : {};
    return Object.assign({}, state, {
        productos: mergeWith(clone(datopromocionEliminado), pickBy(state.productos, function(producto) {return producto.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_CREATE_PROMOCION:
    let datopromocionCreado = action.promocion.entities && action.promocion.entities.productos ? action.promocion.entities.productos : {};
    return Object.assign({}, state, {
        productos: mergeWith(clone(datopromocionCreado), pickBy(state.productos, function(producto) {return producto.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
case SUCCESS_UPDATE_PROMOCION:
    let datopromocionActualizado = action.promocion.entities && action.promocion.entities.productos ? action.promocion.entities.productos : {};
    return Object.assign({}, state, {
        productos: mergeWith(clone(datopromocionActualizado), pickBy(state.productos, function(producto) {return producto.id.toString().indexOf("-") === -1 }), (objValue, srcValue) => {return objValue;})
    });
 case SUCCESS_UPDATE_PROMOCIONES:
    let datospromocionActualizado = action.promociones.entities && action.promociones.entities.productos ? action.promociones.entities.productos : {} ;
        return Object.assign({}, state, {
            productos: mergeWith(clone(datospromocionActualizado), state.productos, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                productos: {}
            });
        default:
            return state
    }
}


function allProductos(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTOS:
            return action.productos.result && action.productos.result.productos ? union(action.productos.result.productos, state) : (action.productos.result ? action.productos.result : state) ;
        case RECEIVE_PRODUCTO:
                return action.producto.result ? union([action.producto.result], state) : state;
        
        case SUCCESS_CREATE_PRODUCTO:
                   let datoProductoSCreate = action.producto.entities.productos;
                   let idNuevoSCreate = null;
                   if (Object.values(datoProductoSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoProductoSCreate)[0] && Object.values(datoProductoSCreate)[0].id ? Object.values(datoProductoSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PRODUCTOS:
                   let productosCreate = action.productos.entities && action.productos.entities.productos ? action.productos.entities.productos : null;
                   return productosCreate ?
                       union(state, Object.values(productosCreate).map((productos) => {
                           return productos.id
                       })) : state;
        case RESET_PRODUCTOS:
            return [];

            case RECEIVE_PROMOCION:
    let promocion = action.promocion.entities && action.promocion.entities.productos ? action.promocion.entities.productos : null ;
    return promocion ?
        union(state, Object.values(promocion).map((promocion) => {
            return promocion.id
        })) : state;
case RECEIVE_PROMOCIONES:
    let promociones = action.promociones.entities && action.promociones.entities.productos ? action.promociones.entities.productos : null;
    return promociones ?
        union(state, Object.values(promociones).map((promociones) => {
            return promociones.id
        })) : state;

case SUCCESS_DELETE_PROMOCION:
    let promocionDelete = action.promocion.entities && action.promocion.entities.productos ? action.promocion.entities.productos : null ;
        return promocionDelete ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionDelete).map((promocion) => {
                return promocion.id
            })) : state;
case SUCCESS_CREATE_PROMOCION:
   let promocionCreate = action.promocion.entities && action.promocion.entities.productos ? action.promocion.entities.productos : null ;
       return promocionCreate ?
          union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionCreate).map((promocion) => {
               return promocion.id
           })) : state;
case SUCCESS_UPDATE_PROMOCION:
    let promocionUpdate = action.promocion.entities && action.promocion.entities.productos ? action.promocion.entities.productos : null ;
        return promocionUpdate ?
            union(filter(state, function(o) { return o.toString().indexOf("-") === -1; }), Object.values(promocionUpdate).map((promocion) => {
                return promocion.id
            })) : state;
 case SUCCESS_UPDATE_PROMOCIONES:
     let promocionesUpdate = action.promociones.entities && action.promociones.entities.productos ? action.promociones.entities.productos : null;
        return promocionesUpdate ?
            union(state, Object.values(promocionesUpdate).map((promociones) => {
                return promociones.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalProductos(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTOS:
            return action.productos && action.productos.result.total ? action.productos.result.total : 0;
        case RESET_PRODUCTOS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PRODUCTO:
            let dato = action.producto.entities.productos;
            let producto = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: producto ? producto : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PRODUCTO:
            let idsUpdate = [];
            Object.values(action.producto).map((productoUpdate) => {
                if (productoUpdate && productoUpdate.id)
                    idsUpdate.push(productoUpdate.id);
            });
            return merge({}, state, {
                activo: action.producto,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PRODUCTO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PRODUCTO:
            let datoProductoActualizado = {};
            if (Object.values(action.producto.entities.productos).length > 0)
                datoProductoActualizado = Object.values(action.producto.entities.productos)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoProductoActualizado
            });
        case ERROR_UPDATE_PRODUCTO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PRODUCTOS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PRODUCTOS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PRODUCTOS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PRODUCTO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });

        case DELETE_PRODUCTO:
            let datoProductoDelete = action.producto;
            let idsDelete = [];
           Object.values(action.producto).map((productoDelete) => {
               if (productoDelete && productoDelete.id)
                   idsDelete.push(productoDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoProductoDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PRODUCTO:
                       let datoProductoDeleteUpdate = action.producto;
                       let idsDeleteUpdate = [];
                      Object.values(action.producto).map((productoDelete) => {
                          if (productoDelete && productoDelete.id)
                              idsDeleteUpdate.push(productoDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoProductoDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PRODUCTO:
                    let datoProductoDeleted = {};
                    if (Object.values(action.producto.entities.productos).length > 0)
                        datoProductoDeleted = Object.values(action.producto.entities.productos)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoProductoDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PRODUCTO:
             let idsCreate = [];
             Object.values(action.producto).map((productoCreate) => {
                 if (productoCreate && productoCreate.id)
                     idsCreate.push(productoCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.producto,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PRODUCTO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PRODUCTO:
            let datoProductoNuevo = {};
            if (Object.values(action.producto.entities.productos).length > 0)
                datoProductoNuevo = Object.values(action.producto.entities.productos)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoProductoNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PRODUCTOS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PRODUCTOS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PRODUCTOS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PRODUCTO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_PRODUCTO:
           let datoProductoDelete = action.producto;
           let idsDelete = [];
           Object.values(action.producto).map((productoDelete) => {
               if (productoDelete && productoDelete.id)
                   idsDelete.push(productoDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoProductoDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PRODUCTO:
                  let datoProductoDeleteCreate = action.producto;
                  let idsDeleteCreate = [];
                  Object.values(action.producto).map((productoDelete) => {
                      if (productoDelete && productoDelete.id)
                          idsDeleteCreate.push(productoDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoProductoDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PRODUCTO:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.producto,
                error: null,
            });
        case REQUEST_DELETE_PRODUCTO:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PRODUCTO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PRODUCTO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PRODUCTO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PROMOCION
 case SUCCESS_CREATE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PROMOCION:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PROMOCIONES:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PRODUCTO:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PRODUCTO:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PRODUCTO:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const productos = combineReducers({
    byId: productosById,
    allIds: allProductos,
    update: update,
    create: create,
    totalProductos: totalProductos,
    delete: deleter,
    print: print
});

export default productos;