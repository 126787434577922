//api
import categoriasApi from "../api/categoriasApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeCategorias";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//CATEGORIAS
export const REQUEST_CATEGORIAS = 'REQUEST_CATEGORIAS';
export const RECEIVE_CATEGORIAS = 'RECEIVE_CATEGORIAS';
export const INVALIDATE_CATEGORIAS = 'INVALIDATE_CATEGORIAS';
export const ERROR_CATEGORIAS = "ERROR_CATEGORIAS";
export const RESET_CATEGORIAS = "RESET_CATEGORIAS";


export function invalidateCategorias() {
    return {
        type: INVALIDATE_CATEGORIAS
    }
}

function requestCategorias() {
    return {
        type: REQUEST_CATEGORIAS,
    }
}

function receiveCategorias(json) {
    return {
        type: RECEIVE_CATEGORIAS,
    categorias: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorCategorias(error) {
    return {
        type: ERROR_CATEGORIAS,
        error: error,
    }
}

export function resetCategorias() {
    return {
        type: RESET_CATEGORIAS
    }
}

export function fetchCategorias(filtros) {
    return dispatch => {
        dispatch(requestCategorias());
        return categoriasApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategorias(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCategorias(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategorias(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchCategorias(state) {
    const categorias = state.categorias.byId;
    if (!categorias) {
        return true
    } else if (categorias.isFetching) {
        return false
    } else {
        return categorias.didInvalidate;
    }
}

export function fetchCategoriasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchCategorias(getState())) {
            return dispatch(fetchCategorias(filtros))
        }
    }
}


//MODEL
export const REQUEST_CATEGORIA = 'REQUEST_CATEGORIA';
export const RECEIVE_CATEGORIA = 'RECEIVE_CATEGORIA';
export const INVALIDATE_CATEGORIA = 'INVALIDATE_CATEGORIA';
export const ERROR_CATEGORIA = "ERROR_CATEGORIA";
export const RESET_CATEGORIA = "RESET_CATEGORIA";


export function invalidateCategoria() {
    return {
        type: INVALIDATE_CATEGORIA
    }
}

function requestCategoria() {
    return {
        type: REQUEST_CATEGORIA,
    }
}

export function receiveCategoria(json) {
    return {
        type: RECEIVE_CATEGORIA,
    categoria: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorCategoria(error) {
    return {
        type: ERROR_CATEGORIA,
        error: error,
    }
}

export function fetchCategoria(idCategoria) {
    return dispatch => {
        dispatch(requestCategoria());
        return categoriasApi.getOne(idCategoria)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategoria(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCategoria(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategoria(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_CATEGORIA = 'UPDATE_CATEGORIA';
export const REQUEST_UPDATE_CATEGORIA = "REQUEST_UPDATE_CATEGORIA";
export const SUCCESS_UPDATE_CATEGORIA = "SUCCESS_UPDATE_CATEGORIA";
export const ERROR_UPDATE_CATEGORIA = "ERROR_UPDATE_CATEGORIA";
export const RESET_UPDATE_CATEGORIA = "RESET_UPDATE_CATEGORIA";
export const DELETE_UPDATE_CATEGORIA = "DELETE_UPDATE_CATEGORIA";

function requestUpdateCategoria() {
    return {
        type: REQUEST_UPDATE_CATEGORIA,
    }
}

function receiveUpdateCategoria(categoria) {
    return {
        type: SUCCESS_UPDATE_CATEGORIA,
        receivedAt: Date.now(),
        categoria: normalizeDato(categoria)
    }
}

function errorUpdateCategoria(error) {
    return {
        type: ERROR_UPDATE_CATEGORIA,
        error: error,
    }
}

export function resetUpdateCategoria() {
    return {
        type: RESET_UPDATE_CATEGORIA,
    }
}

export function updateCategoria(categoria) {
    return {
        type: UPDATE_CATEGORIA,
        categoria
    }
}

export function saveUpdateCategoria() {
    return (dispatch, getState) => {
        dispatch(requestUpdateCategoria());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categoria = denormalizeDato(getState().categorias.update.activo, store);

        return categoriasApi.saveUpdate(categoria)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateCategoria(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateCategoria(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateCategorias(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateCategorias(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateCategoria(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateCategoria(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateCategoria(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateCategoria(categoria) {
    return {
        type: DELETE_UPDATE_CATEGORIA,
        categoria
    }
}

//UPDATE CATEGORIAS
export const REQUEST_UPDATE_CATEGORIAS = "REQUEST_UPDATE_CATEGORIAS";
export const SUCCESS_UPDATE_CATEGORIAS = "SUCCESS_UPDATE_CATEGORIAS";
export const ERROR_UPDATE_CATEGORIAS = "ERROR_UPDATE_CATEGORIAS";
export const RESET_UPDATE_CATEGORIAS = "RESET_UPDATE_CATEGORIAS";

function requestUpdateCategorias() {
    return {
        type: REQUEST_UPDATE_CATEGORIAS,
}
}

function receiveUpdateCategorias(categorias) {
    return {
        type: SUCCESS_UPDATE_CATEGORIAS,
    receivedAt: Date.now(),
        categorias: normalizeDatos(categorias)
}
}

function errorUpdateCategorias(error) {
    return {
        type: ERROR_UPDATE_CATEGORIAS,
    error: error,
}
}

export function resetUpdateCategorias() {
    return {
        type: RESET_UPDATE_CATEGORIAS,
}
}

export function saveUpdateCategorias() {
    return (dispatch, getState) => {
        dispatch(requestUpdateCategorias());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categorias = getState().categorias.update.activos.map((idCategoria) => {
            return denormalizeDato(getState().categorias.update.activo[idCategoria], store);
        });

        return categoriasApi.saveUpdateCategorias(categorias)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateCategorias(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateCategorias(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateCategorias(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateCategorias(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateCategorias(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateCategorias(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA CATEGORIA
export const CREATE_CATEGORIA = 'CREATE_CATEGORIA';
export const REQUEST_CREATE_CATEGORIA = "REQUEST_CREATE_CATEGORIA";
export const SUCCESS_CREATE_CATEGORIA = "SUCCESS_CREATE_CATEGORIA";
export const ERROR_CREATE_CATEGORIA = "ERROR_CREATE_CATEGORIA";
export const RESET_CREATE_CATEGORIA = "RESET_CREATE_CATEGORIA";
export const DELETE_CREATE_CATEGORIA = "DELETE_CREATE_CATEGORIA";


//ALTA CATEGORIA
function requestCreateCategoria() {
    return {
        type: REQUEST_CREATE_CATEGORIA,
    }
}

function receiveCreateCategoria(categoria) {
    return {
        type: SUCCESS_CREATE_CATEGORIA,
        receivedAt: Date.now(),
        categoria: normalizeDato(categoria)
    }
}

export function errorCreateCategoria(error) {
    return {
        type: ERROR_CREATE_CATEGORIA,
        error: error,
    }
}

export function resetCreateCategoria() {
    return {
        type: RESET_CREATE_CATEGORIA
    }
}

export function createCategoria(categoria) {
    return {
        type: CREATE_CATEGORIA,
        categoria
    }
}

export function deleteCreateCategoria(categoria) {
    return {
        type: DELETE_CREATE_CATEGORIA,
        categoria
    }
}

export function saveCreateCategoria() {
    return (dispatch, getState) => {
        dispatch(requestCreateCategoria());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categoria = denormalizeDato(getState().categorias.create.nuevo, store);

        return categoriasApi.saveCreate(categoria)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateCategoria(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateCategoria(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateCategorias(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateCategorias(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateCategoria(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateCategoria(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateCategoria(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE CATEGORIAS
export const REQUEST_CREATE_CATEGORIAS = "REQUEST_CREATE_CATEGORIAS";
export const SUCCESS_CREATE_CATEGORIAS = "SUCCESS_CREATE_CATEGORIAS";
export const ERROR_CREATE_CATEGORIAS = "ERROR_CREATE_CATEGORIAS";
export const RESET_CREATE_CATEGORIAS = "RESET_CREATE_CATEGORIAS";

function requestCreateCategorias() {
    return {
        type: REQUEST_CREATE_CATEGORIAS,
}
}

function receiveCreateCategorias(categorias) {
    return {
        type: SUCCESS_CREATE_CATEGORIAS,
    receivedAt: Date.now(),
        categorias: normalizeDatos(categorias)
}
}

function errorCreateCategorias(error) {
    return {
        type: ERROR_CREATE_CATEGORIAS,
    error: error,
}
}

export function resetCreateCategorias() {
    return {
        type: RESET_CREATE_CATEGORIAS,
}
}

export function saveCreateCategorias() {
    return (dispatch, getState) => {
        dispatch(requestCreateCategorias());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let categorias = getState().categorias.create.nuevos.map((idCategoria) => {
            return denormalizeDato(getState().categorias.create.nuevo[idCategoria], store);
        });

        return categoriasApi.saveCreateCategorias(categorias)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateCategorias(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateCategorias(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateCategorias(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateCategorias(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateCategorias(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateCategorias(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE CATEGORIA
export const DELETE_CATEGORIA = 'DELETE_CATEGORIA';
export const REQUEST_DELETE_CATEGORIA = "REQUEST_DELETE_CATEGORIA";
export const SUCCESS_DELETE_CATEGORIA = "SUCCESS_DELETE_CATEGORIA";
export const ERROR_DELETE_CATEGORIA = "ERROR_DELETE_CATEGORIA";
export const RESET_DELETE_CATEGORIA = "RESET_DELETE_CATEGORIA";

function requestDeleteCategoria() {
    return {
        type: REQUEST_DELETE_CATEGORIA,
    }
}

function receiveDeleteCategoria(categoria) {
    return {
        type: SUCCESS_DELETE_CATEGORIA,
        receivedAt: Date.now(),
        categoria: normalizeDato(categoria)
    }
}

function errorDeleteCategoria(error) {
    return {
        type: ERROR_DELETE_CATEGORIA,
        error: error,
    }
}

export function resetDeleteCategoria(error) {
    return {
        type: RESET_DELETE_CATEGORIA,
        error: error,
    }
}

export function deleteCategoria(categoria) {
    return {
        type: DELETE_CATEGORIA,
        categoria
    }
}

export function saveDeleteCategoria(categoria) {
    return dispatch => {
        dispatch(requestDeleteCategoria());
        return categoriasApi.saveDelete(categoria)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteCategoria());
                            dispatch(receiveDeleteCategoria(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteCategoria(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteCategoria(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteCategoria(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteCategoria(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteCategoria(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT CATEGORIA
export const REQUEST_PRINT_CATEGORIA = "REQUEST_PRINT_CATEGORIA";
export const SUCCESS_PRINT_CATEGORIA = "SUCCESS_PRINT_CATEGORIA";
export const ERROR_PRINT_CATEGORIA = "ERROR_PRINT_CATEGORIA";
export const RESET_PRINT_CATEGORIA = "RESET_PRINT_CATEGORIA";

function requestPrintCategoria() {
    return {
        type: REQUEST_PRINT_CATEGORIA,
    }
}

function receivePrintCategoria(turnos) {
    return {
        type: SUCCESS_PRINT_CATEGORIA,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintCategoria(error) {
    return {
        type: ERROR_PRINT_CATEGORIA,
        error: error,
    }
}

export function resetPrintCategoria() {
    return {
        type: RESET_PRINT_CATEGORIA,
    }
}

export function printCategoria(idCategoria) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintCategoria());
        return categoriasApi.printCategoria(idCategoria)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintCategoria(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintCategoria(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintCategoria(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintCategoria(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintCategoria(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}