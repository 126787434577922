import React from 'react';
import {Box, Typography, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    simbolo: {
        fontSize: props => props.enteroSize,
        "&.responsive": {
            fontSize: props => props.responsive && props.responsive.enteroSize ? `${props.responsive.enteroSize}!important` : props.enteroSize,
        }

    },
    entero: {
        fontSize: props => props.enteroSize,
        "&.responsive": {
            fontSize: props => props.responsive && props.responsive.enteroSize ? `${props.responsive.enteroSize}!important` : props.enteroSize,
        }
    },
    decimal: {
        fontSize: props => props.decimalSize,
        "&.responsive": {
            fontSize: props => props.responsive && props.responsive.decimalSize ? `${props.responsive.decimalSize}!important` : props.decimalSize,
        },
        verticalAlign: "super",
        top: "1px",
        position: "relative",
    },
}));

function areEqual(prevProps, nextProps) {
    /*
    retorna true si al pasar los nextProps a renderizar retorna
    el mismo resultado que al pasar los prevProps a renderizar,
    de otro modo retorna false
    */
    return prevProps === nextProps;
}

export default React.memo(function NumberFormated(props) {
    const classes = useStyles(props);
    let total = props.children ? props.children.toString() : "";
    let totalSplit = total.split('.');
    const theme = useTheme();
    const breakPoint = useMediaQuery(`(max-width:${props.responsive && props.responsive.breakpoints ? props.responsive.breakpoints : null})`);

    NumberFormated.propTypes = {
        enteroSize: PropTypes.string.isRequired,
        decimalSize: PropTypes.string.isRequired,
        simbolo: PropTypes.string.isRequired,
        tachado: PropTypes.bool,
        margin: PropTypes.string,
        marginLeft: PropTypes.string,
        marginRight: PropTypes.string,
        marginTop: PropTypes.string,
        marginBottom: PropTypes.string,
        color: PropTypes.oneOf(["textPrimary", "textSecondary"]),
        responsive: PropTypes.object
    };

    return (
        <Box component={"span"} display={"flex"}
             style={{
                 textDecoration: props.tachado ? "line-through" : "",
                 margin: props.margin ? props.margin : "",
                 marginLeft: props.marginLeft ? props.marginLeft : "",
                 marginRight: props.marginRight ? props.marginRight : "",
                 marginTop: props.marginTop ? props.marginTop : "",
                 marginBottom: props.marginBottom ? props.marginBottom : ""
             }}
             color={
                 props.color && props.color === "textPrimary"
                     ?
                     theme.palette.text.primary
                     :
                     props.color && props.color === "textSecondary"
                         ?
                         theme.palette.text.secondary
                         :
                         props.color
             }>
            <Typography variant={"body2"}
                        component={"span"}
                        className={clsx(classes.simbolo, {
                            "responsive": breakPoint
                        })}
            >
                {props.simbolo && props.simbolo.length > 0 && totalSplit[0] !== "" ? props.simbolo + " " : ""}
            </Typography>
            <Typography variant={"body2"}
                        component={"span"}
                        className={clsx(classes.entero, {
                            "responsive": breakPoint
                        })}
            >
                {totalSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </Typography>
            <Typography variant={"body2"}
                        component={"span"}
                        className={clsx(classes.decimal, {
                            "responsive": breakPoint
                        })}
            >
                {totalSplit[1]}
            </Typography>
        </Box>
    )
}, areEqual);
