import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

//Components
import {Box, Button, MenuItem, Typography, Menu} from "@material-ui/core";

//Icons
import {KeyboardArrowDown as ArrowDown,} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    containerMiniTotal: {},
    entero: {},
    decimal: {
        verticalAlign: "super",
        top: "1px",
        position: "relative",
    },
    button: {
        padding: "3px 0",
        paddingTop: "6px",
        paddingLeft: "2px",
        paddingRight: "5px",
        margin: 0,
        marginLeft: "6px",
        // marginRight: "4px",
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            paddingLeft: "4px",
            paddingRight: "5px",
        },
        "& span": {
            marginRight: 0
        },
        "& svg": {
            opacity: "0.3",
            fontSize: "20px",
        },
    },
}));

export default function MiniMonedaTotal(props) {
    const classes = useStyles();
    const [openMenuMoneda, setOpenMenuMoneda] = React.useState(null);
    const enteroRef = useRef(null);

    let total = props.children.toString();
    let totalSplit = total.split('.');

    const handleToggleMenuMoneda = (event) => {
        setOpenMenuMoneda(event.currentTarget);
    };
    const handleCloseMenuMoneda = () => {
        setOpenMenuMoneda(null);
    };
    return (
        <>
            <Box component={"div"} style={{cursor: "pointer", display: "flex", alignItems: "center"}}>
                <Button component={"button"}
                        className={classes.button}
                        color={"inherit"}
                        startIcon={<ArrowDown/>}
                        size={"small"}
                        aria-controls="menu-monedas"
                        onClick={handleToggleMenuMoneda}
                >
                    <Typography component={"p"} variant={props.variant ? props.variant : "body2"}>
                        {props.moneda}
                    </Typography>
                    &nbsp;
                    <Box component={"div"}>
                        <Typography variant={props.variant ? props.variant : "body2"} component={"span"}
                                    className={classes.entero}
                                    ref={enteroRef}>
                            {totalSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </Typography>
                        <Typography variant={props.variant ? props.variant : "body2"} component={"span"}
                                    className={classes.decimal}
                                    style={{
                                        fontSize: props.fontSizeDecimal &&
                                        props.fontSizeDecimal !== null ? props.fontSizeDecimal : "9px",
                                    }}>
                            {totalSplit[1]}
                        </Typography>
                    </Box>
                </Button>
            </Box>
            <Menu
                id="menu-monedas"
                anchorEl={openMenuMoneda}
                anchorReference={null}
                keepMounted
                open={Boolean(openMenuMoneda)}
                onClose={handleCloseMenuMoneda}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleCloseMenuMoneda}>Dolares U$D</MenuItem>
                <MenuItem onClick={handleCloseMenuMoneda}>Pesos $</MenuItem>
            </Menu>
        </>
    )
}

MiniMonedaTotal.propTypes = {
    moneda: PropTypes.string,
}
