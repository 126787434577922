import {
    INVALIDATE_PROMOCIONES,
    ERROR_PROMOCIONES,
    RECEIVE_PROMOCIONES,
    REQUEST_PROMOCIONES,
    RESET_PROMOCIONES,
    ERROR_PROMOCION,
    RECEIVE_PROMOCION,
    REQUEST_PROMOCION,
    UPDATE_PROMOCION,
    REQUEST_UPDATE_PROMOCION,
    SUCCESS_UPDATE_PROMOCION,
    ERROR_UPDATE_PROMOCION,
    RESET_UPDATE_PROMOCION,
    REQUEST_UPDATE_PROMOCIONES,
    SUCCESS_UPDATE_PROMOCIONES,
    ERROR_UPDATE_PROMOCIONES,
    RESET_UPDATE_PROMOCIONES,
    CREATE_PROMOCION,
    ERROR_CREATE_PROMOCION,
    REQUEST_CREATE_PROMOCION,
    RESET_CREATE_PROMOCION,
    SUCCESS_CREATE_PROMOCION,
    REQUEST_CREATE_PROMOCIONES,
    SUCCESS_CREATE_PROMOCIONES,
    ERROR_CREATE_PROMOCIONES,
    RESET_CREATE_PROMOCIONES,
    DELETE_PROMOCION,
    DELETE_CREATE_PROMOCION,
    DELETE_UPDATE_PROMOCION,
    REQUEST_DELETE_PROMOCION,
    SUCCESS_DELETE_PROMOCION,
    ERROR_DELETE_PROMOCION,
    RESET_DELETE_PROMOCION,
    REQUEST_PRINT_PROMOCION,
    SUCCESS_PRINT_PROMOCION,
    ERROR_PRINT_PROMOCION,
    RESET_PRINT_PROMOCION
} from '../actions/PromocionActions';
import {
    CREATE_PRODUCTOORIGENPROMOCION,
    UPDATE_PRODUCTOORIGENPROMOCION,
    DELETE_PRODUCTOORIGENPROMOCION
} from '../actions/ProductoOrigenPromocionActions';
import {
    CREATE_PRODUCTODESTINOPROMOCION,
    UPDATE_PRODUCTODESTINOPROMOCION,
    DELETE_PRODUCTODESTINOPROMOCION
} from '../actions/ProductoDestinoPromocionActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        promociones: {},
    }
}

function promocionesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PROMOCIONES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                promociones: {}
            });
        case RECEIVE_PROMOCIONES:
            let dato = action.promociones.entities.promociones;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                promociones: merge({}, state.promociones, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PROMOCION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PROMOCION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PROMOCION:
            let datoPromocion = action.promocion.entities.promociones;
            return Object.assign({}, state, {
                promociones: merge({}, state.promociones, datoPromocion),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_PROMOCION:
            let datoPromocionEliminado = action.promocion.entities.promociones;
            return Object.assign({}, state, {
                promociones: mergeWith(clone(datoPromocionEliminado), state.promociones, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PROMOCION:
            let datoPromocionCreado = action.promocion.entities.promociones;
            return Object.assign({}, state, {
                promociones: mergeWith(clone(datoPromocionCreado), state.promociones, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_PROMOCIONES:
            let datosPromocionCreado = action.promociones.entities.promociones;
                return Object.assign({}, state, {
                    promociones: mergeWith(clone(datosPromocionCreado), state.promociones, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_PROMOCION:
            let datoPromocionActualizado = action.promocion.entities.promociones;
            return Object.assign({}, state, {
                promociones: mergeWith(clone(datoPromocionActualizado), state.promociones, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_PROMOCIONES:
            let datosPromocionActualizado = action.promociones.entities.promociones;
                return Object.assign({}, state, {
                    promociones: mergeWith(clone(datosPromocionActualizado), state.promociones, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                promociones: {}
            });
        default:
            return state
    }
}


function allPromociones(state = [], action) {
    switch (action.type) {
        case RECEIVE_PROMOCIONES:
            return action.promociones.result && action.promociones.result.promociones ? union(action.promociones.result.promociones, state) : (action.promociones.result ? action.promociones.result : state) ;
        case RECEIVE_PROMOCION:
                return action.promocion.result ? union([action.promocion.result], state) : state;
        
        case SUCCESS_CREATE_PROMOCION:
                   let datoPromocionSCreate = action.promocion.entities.promociones;
                   let idNuevoSCreate = null;
                   if (Object.values(datoPromocionSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoPromocionSCreate)[0] && Object.values(datoPromocionSCreate)[0].id ? Object.values(datoPromocionSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_PROMOCIONES:
                   let promocionesCreate = action.promociones.entities && action.promociones.entities.promociones ? action.promociones.entities.promociones : null;
                   return promocionesCreate ?
                       union(state, Object.values(promocionesCreate).map((promociones) => {
                           return promociones.id
                       })) : state;
        case RESET_PROMOCIONES:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalPromociones(state = null, action) {
    switch (action.type) {
        case RECEIVE_PROMOCIONES:
            return action.promociones && action.promociones.result.total ? action.promociones.result.total : 0;
        case RESET_PROMOCIONES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PROMOCION:
            let dato = action.promocion.entities.promociones;
            let promocion = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: promocion ? promocion : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PROMOCION:
            let idsUpdate = [];
            Object.values(action.promocion).map((promocionUpdate) => {
                if (promocionUpdate && promocionUpdate.id)
                    idsUpdate.push(promocionUpdate.id);
            });
            return merge({}, state, {
                activo: action.promocion,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_PROMOCION:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PROMOCION:
            let datoPromocionActualizado = {};
            if (Object.values(action.promocion.entities.promociones).length > 0)
                datoPromocionActualizado = Object.values(action.promocion.entities.promociones)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoPromocionActualizado
            });
        case ERROR_UPDATE_PROMOCION:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_PROMOCIONES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_PROMOCIONES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_PROMOCIONES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_PROMOCION:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           case CREATE_PRODUCTOORIGENPROMOCION:
    let promocionproductoOrigenPromocionCreateActivo = clone(state.activo);
    let promocionproductoOrigenPromocionCreateActivos = clone(state.activos);
    Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionCreate) => {
        if(productoOrigenPromocionCreate && productoOrigenPromocionCreate.idPromocion && promocionproductoOrigenPromocionCreateActivo[productoOrigenPromocionCreate.idPromocion]){
            if(productoOrigenPromocionCreate.idPromocion.toString().indexOf("-") === -1)
                promocionproductoOrigenPromocionCreateActivo[productoOrigenPromocionCreate.idPromocion].producto_origen_promocion = union(promocionproductoOrigenPromocionCreateActivo.producto_origen_promocion, [productoOrigenPromocionCreate.id]);
        } else if (productoOrigenPromocionCreate) {
            promocionproductoOrigenPromocionCreateActivo.producto_origen_promocion = union(promocionproductoOrigenPromocionCreateActivo.producto_origen_promocion ? promocionproductoOrigenPromocionCreateActivo.producto_origen_promocion : [], [productoOrigenPromocionCreate.id]);
        }
        if(productoOrigenPromocionCreate && productoOrigenPromocionCreate.idPromocion && productoOrigenPromocionCreate.idPromocion.toString().indexOf("-") === -1)
            promocionproductoOrigenPromocionCreateActivos = union(promocionproductoOrigenPromocionCreateActivos, [productoOrigenPromocionCreate.idPromocion]);
    });
    return Object.assign({}, state, {
        activo: promocionproductoOrigenPromocionCreateActivo,
        activos: promocionproductoOrigenPromocionCreateActivos
    });
case UPDATE_PRODUCTOORIGENPROMOCION:
    let promocionproductoOrigenPromocionUpdateActivo = clone(state.activo);
        let promocionproductoOrigenPromocionUpdateActivos = clone(state.activos);
        Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionUpdate) => {
            if(productoOrigenPromocionUpdate && productoOrigenPromocionUpdate.idPromocion && promocionproductoOrigenPromocionUpdateActivo[productoOrigenPromocionUpdate.idPromocion]){
                if(productoOrigenPromocionUpdate.idPromocion.toString().indexOf("-") === -1)
                    promocionproductoOrigenPromocionUpdateActivo[productoOrigenPromocionUpdate.idPromocion].producto_origen_promocion = union(promocionproductoOrigenPromocionUpdateActivo.producto_origen_promocion, [productoOrigenPromocionUpdate.id]);
            } else if (productoOrigenPromocionUpdate) {
                promocionproductoOrigenPromocionUpdateActivo.producto_origen_promocion = union(promocionproductoOrigenPromocionUpdateActivo.producto_origen_promocion ? promocionproductoOrigenPromocionUpdateActivo.producto_origen_promocion : [], [productoOrigenPromocionUpdate.id]);
            }
            if(productoOrigenPromocionUpdate && productoOrigenPromocionUpdate.idPromocion && productoOrigenPromocionUpdate.idPromocion.toString().indexOf("-") === -1)
                promocionproductoOrigenPromocionUpdateActivos = union(promocionproductoOrigenPromocionUpdateActivos, [productoOrigenPromocionUpdate.idPromocion]);
        });
        return Object.assign({}, state, {
            activo: promocionproductoOrigenPromocionUpdateActivo,
            activos: promocionproductoOrigenPromocionUpdateActivos
        });
case DELETE_PRODUCTOORIGENPROMOCION:
    let promocionproductoOrigenPromocionDeleteActivo = clone(state.activo);
    let promocionproductoOrigenPromocionDeleteActivos = clone(state.activos);
    Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionDelete) => {
        if(productoOrigenPromocionDelete && productoOrigenPromocionDelete.idPromocion && promocionproductoOrigenPromocionDeleteActivo[productoOrigenPromocionDelete.idPromocion]){
            if(productoOrigenPromocionDelete.idPromocion.toString().indexOf("-") === -1)
                promocionproductoOrigenPromocionDeleteActivo[productoOrigenPromocionDelete.idPromocion].producto_origen_promocion = difference(promocionproductoOrigenPromocionDeleteActivo.producto_origen_promocion, [productoOrigenPromocionDelete.id]);
        } else if (productoOrigenPromocionDelete) {
            promocionproductoOrigenPromocionDeleteActivo.producto_origen_promocion = difference(promocionproductoOrigenPromocionDeleteActivo.producto_origen_promocion ? promocionproductoOrigenPromocionDeleteActivo.producto_origen_promocion : [], [productoOrigenPromocionDelete.id]);
        }
        if(productoOrigenPromocionDelete && productoOrigenPromocionDelete.idPromocion && productoOrigenPromocionDelete.idPromocion.toString().indexOf("-") === -1)
            promocionproductoOrigenPromocionDeleteActivos = union(promocionproductoOrigenPromocionDeleteActivos, [productoOrigenPromocionDelete.idPromocion]);
    });
    return Object.assign({}, state, {
        activo: promocionproductoOrigenPromocionDeleteActivo,
        activos: promocionproductoOrigenPromocionDeleteActivos
    });case CREATE_PRODUCTODESTINOPROMOCION:
    let promocionproductoDestinoPromocionCreateActivo = clone(state.activo);
    let promocionproductoDestinoPromocionCreateActivos = clone(state.activos);
    Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionCreate) => {
        if(productoDestinoPromocionCreate && productoDestinoPromocionCreate.idPromocion && promocionproductoDestinoPromocionCreateActivo[productoDestinoPromocionCreate.idPromocion]){
            if(productoDestinoPromocionCreate.idPromocion.toString().indexOf("-") === -1)
                promocionproductoDestinoPromocionCreateActivo[productoDestinoPromocionCreate.idPromocion].producto_destino_promocion = union(promocionproductoDestinoPromocionCreateActivo.producto_destino_promocion, [productoDestinoPromocionCreate.id]);
        } else if (productoDestinoPromocionCreate) {
            promocionproductoDestinoPromocionCreateActivo.producto_destino_promocion = union(promocionproductoDestinoPromocionCreateActivo.producto_destino_promocion ? promocionproductoDestinoPromocionCreateActivo.producto_destino_promocion : [], [productoDestinoPromocionCreate.id]);
        }
        if(productoDestinoPromocionCreate && productoDestinoPromocionCreate.idPromocion && productoDestinoPromocionCreate.idPromocion.toString().indexOf("-") === -1)
            promocionproductoDestinoPromocionCreateActivos = union(promocionproductoDestinoPromocionCreateActivos, [productoDestinoPromocionCreate.idPromocion]);
    });
    return Object.assign({}, state, {
        activo: promocionproductoDestinoPromocionCreateActivo,
        activos: promocionproductoDestinoPromocionCreateActivos
    });
case UPDATE_PRODUCTODESTINOPROMOCION:
    let promocionproductoDestinoPromocionUpdateActivo = clone(state.activo);
        let promocionproductoDestinoPromocionUpdateActivos = clone(state.activos);
        Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionUpdate) => {
            if(productoDestinoPromocionUpdate && productoDestinoPromocionUpdate.idPromocion && promocionproductoDestinoPromocionUpdateActivo[productoDestinoPromocionUpdate.idPromocion]){
                if(productoDestinoPromocionUpdate.idPromocion.toString().indexOf("-") === -1)
                    promocionproductoDestinoPromocionUpdateActivo[productoDestinoPromocionUpdate.idPromocion].producto_destino_promocion = union(promocionproductoDestinoPromocionUpdateActivo.producto_destino_promocion, [productoDestinoPromocionUpdate.id]);
            } else if (productoDestinoPromocionUpdate) {
                promocionproductoDestinoPromocionUpdateActivo.producto_destino_promocion = union(promocionproductoDestinoPromocionUpdateActivo.producto_destino_promocion ? promocionproductoDestinoPromocionUpdateActivo.producto_destino_promocion : [], [productoDestinoPromocionUpdate.id]);
            }
            if(productoDestinoPromocionUpdate && productoDestinoPromocionUpdate.idPromocion && productoDestinoPromocionUpdate.idPromocion.toString().indexOf("-") === -1)
                promocionproductoDestinoPromocionUpdateActivos = union(promocionproductoDestinoPromocionUpdateActivos, [productoDestinoPromocionUpdate.idPromocion]);
        });
        return Object.assign({}, state, {
            activo: promocionproductoDestinoPromocionUpdateActivo,
            activos: promocionproductoDestinoPromocionUpdateActivos
        });
case DELETE_PRODUCTODESTINOPROMOCION:
    let promocionproductoDestinoPromocionDeleteActivo = clone(state.activo);
    let promocionproductoDestinoPromocionDeleteActivos = clone(state.activos);
    Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionDelete) => {
        if(productoDestinoPromocionDelete && productoDestinoPromocionDelete.idPromocion && promocionproductoDestinoPromocionDeleteActivo[productoDestinoPromocionDelete.idPromocion]){
            if(productoDestinoPromocionDelete.idPromocion.toString().indexOf("-") === -1)
                promocionproductoDestinoPromocionDeleteActivo[productoDestinoPromocionDelete.idPromocion].producto_destino_promocion = difference(promocionproductoDestinoPromocionDeleteActivo.producto_destino_promocion, [productoDestinoPromocionDelete.id]);
        } else if (productoDestinoPromocionDelete) {
            promocionproductoDestinoPromocionDeleteActivo.producto_destino_promocion = difference(promocionproductoDestinoPromocionDeleteActivo.producto_destino_promocion ? promocionproductoDestinoPromocionDeleteActivo.producto_destino_promocion : [], [productoDestinoPromocionDelete.id]);
        }
        if(productoDestinoPromocionDelete && productoDestinoPromocionDelete.idPromocion && productoDestinoPromocionDelete.idPromocion.toString().indexOf("-") === -1)
            promocionproductoDestinoPromocionDeleteActivos = union(promocionproductoDestinoPromocionDeleteActivos, [productoDestinoPromocionDelete.idPromocion]);
    });
    return Object.assign({}, state, {
        activo: promocionproductoDestinoPromocionDeleteActivo,
        activos: promocionproductoDestinoPromocionDeleteActivos
    });

           

        case DELETE_PROMOCION:
            let datoPromocionDelete = action.promocion;
            let idsDelete = [];
           Object.values(action.promocion).map((promocionDelete) => {
               if (promocionDelete && promocionDelete.id)
                   idsDelete.push(promocionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoPromocionDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_PROMOCION:
                       let datoPromocionDeleteUpdate = action.promocion;
                       let idsDeleteUpdate = [];
                      Object.values(action.promocion).map((promocionDelete) => {
                          if (promocionDelete && promocionDelete.id)
                              idsDeleteUpdate.push(promocionDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoPromocionDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_PROMOCION:
                    let datoPromocionDeleted = {};
                    if (Object.values(action.promocion.entities.promociones).length > 0)
                        datoPromocionDeleted = Object.values(action.promocion.entities.promociones)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoPromocionDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PROMOCION:
             let idsCreate = [];
             Object.values(action.promocion).map((promocionCreate) => {
                 if (promocionCreate && promocionCreate.id)
                     idsCreate.push(promocionCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.promocion,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_PROMOCION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PROMOCION:
            let datoPromocionNuevo = {};
            if (Object.values(action.promocion.entities.promociones).length > 0)
                datoPromocionNuevo = Object.values(action.promocion.entities.promociones)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoPromocionNuevo,
                nuevos: []
            });
        case ERROR_CREATE_PROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_PROMOCIONES:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PROMOCIONES:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_PROMOCIONES:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PROMOCION:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             //CREATE PRODUCTOORIGENPROMOCION
case CREATE_PRODUCTOORIGENPROMOCION:
    let promocionproductoOrigenPromocionCreateActivo = clone(state.nuevo);
    Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionCreate) => {
        if(productoOrigenPromocionCreate && productoOrigenPromocionCreate.idPromocion && promocionproductoOrigenPromocionCreateActivo[productoOrigenPromocionCreate.idPromocion]){
            if(productoOrigenPromocionCreate.idPromocion.toString().indexOf("-") !== -1)
                promocionproductoOrigenPromocionCreateActivo[productoOrigenPromocionCreate.idPromocion].producto_origen_promocion = union(promocionproductoOrigenPromocionCreateActivo.producto_origen_promocion, [productoOrigenPromocionCreate.id]);
        } else if (productoOrigenPromocionCreate) {
            promocionproductoOrigenPromocionCreateActivo.producto_origen_promocion = union(promocionproductoOrigenPromocionCreateActivo.producto_origen_promocion ? promocionproductoOrigenPromocionCreateActivo.producto_origen_promocion : [], [productoOrigenPromocionCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: promocionproductoOrigenPromocionCreateActivo,
        //nuevos: productoOrigenPromocionCreate && productoOrigenPromocionCreate.idPromocion ? union(state.nuevos, [productoOrigenPromocionCreate.idPromocion]) : state.nuevos,
    });
case UPDATE_PRODUCTOORIGENPROMOCION:
    let promocionproductoOrigenPromocionUpdateActivo = clone(state.nuevo);
    Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionUpdate) => {
        if(productoOrigenPromocionUpdate && productoOrigenPromocionUpdate.idPromocion && promocionproductoOrigenPromocionUpdateActivo[productoOrigenPromocionUpdate.idPromocion]){
            if(productoOrigenPromocionUpdate.idPromocion.toString().indexOf("-") !== -1)
                promocionproductoOrigenPromocionUpdateActivo[productoOrigenPromocionUpdate.idPromocion].producto_origen_promocion = union(promocionproductoOrigenPromocionUpdateActivo.producto_origen_promocion, [productoOrigenPromocionUpdate.id]);
        } else if(productoOrigenPromocionUpdate){
            promocionproductoOrigenPromocionUpdateActivo.producto_origen_promocion = union(promocionproductoOrigenPromocionUpdateActivo.producto_origen_promocion ? promocionproductoOrigenPromocionUpdateActivo.producto_origen_promocion : [], [productoOrigenPromocionUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: promocionproductoOrigenPromocionUpdateActivo,
        //nuevos: productoOrigenPromocionUpdate && productoOrigenPromocionUpdate.idPromocion ? union(state.nuevos, [productoOrigenPromocionUpdate.idPromocion]) : state.nuevos,
    });
case DELETE_PRODUCTOORIGENPROMOCION:
    let promocionproductoOrigenPromocionDeleteActivo = clone(state.nuevo);
    Object.values(action.productoOrigenPromocion).map((productoOrigenPromocionDelete) => {
        if(productoOrigenPromocionDelete && productoOrigenPromocionDelete.idPromocion && productoOrigenPromocionDelete.idPromocion && promocionproductoOrigenPromocionDeleteActivo[productoOrigenPromocionDelete.idPromocion]){
            if(productoOrigenPromocionDelete.idPromocion.toString().indexOf("-") !== -1)
                promocionproductoOrigenPromocionDeleteActivo[productoOrigenPromocionDelete.idPromocion].producto_origen_promocion = difference(promocionproductoOrigenPromocionDeleteActivo.producto_origen_promocion, [productoOrigenPromocionDelete.id]);
        } else if (productoOrigenPromocionDelete) {
            promocionproductoOrigenPromocionDeleteActivo.producto_origen_promocion = difference(promocionproductoOrigenPromocionDeleteActivo.producto_origen_promocion ? promocionproductoOrigenPromocionDeleteActivo.producto_origen_promocion : [], [productoOrigenPromocionDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: promocionproductoOrigenPromocionDeleteActivo,
        //nuevos: productoOrigenPromocionDelete && productoOrigenPromocionDelete.idPromocion ? union(state.nuevos, [productoOrigenPromocionDelete.idPromocion]) : state.nuevos,
    });//CREATE PRODUCTODESTINOPROMOCION
case CREATE_PRODUCTODESTINOPROMOCION:
    let promocionproductoDestinoPromocionCreateActivo = clone(state.nuevo);
    Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionCreate) => {
        if(productoDestinoPromocionCreate && productoDestinoPromocionCreate.idPromocion && promocionproductoDestinoPromocionCreateActivo[productoDestinoPromocionCreate.idPromocion]){
            if(productoDestinoPromocionCreate.idPromocion.toString().indexOf("-") !== -1)
                promocionproductoDestinoPromocionCreateActivo[productoDestinoPromocionCreate.idPromocion].producto_destino_promocion = union(promocionproductoDestinoPromocionCreateActivo.producto_destino_promocion, [productoDestinoPromocionCreate.id]);
        } else if (productoDestinoPromocionCreate) {
            promocionproductoDestinoPromocionCreateActivo.producto_destino_promocion = union(promocionproductoDestinoPromocionCreateActivo.producto_destino_promocion ? promocionproductoDestinoPromocionCreateActivo.producto_destino_promocion : [], [productoDestinoPromocionCreate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: promocionproductoDestinoPromocionCreateActivo,
        //nuevos: productoDestinoPromocionCreate && productoDestinoPromocionCreate.idPromocion ? union(state.nuevos, [productoDestinoPromocionCreate.idPromocion]) : state.nuevos,
    });
case UPDATE_PRODUCTODESTINOPROMOCION:
    let promocionproductoDestinoPromocionUpdateActivo = clone(state.nuevo);
    Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionUpdate) => {
        if(productoDestinoPromocionUpdate && productoDestinoPromocionUpdate.idPromocion && promocionproductoDestinoPromocionUpdateActivo[productoDestinoPromocionUpdate.idPromocion]){
            if(productoDestinoPromocionUpdate.idPromocion.toString().indexOf("-") !== -1)
                promocionproductoDestinoPromocionUpdateActivo[productoDestinoPromocionUpdate.idPromocion].producto_destino_promocion = union(promocionproductoDestinoPromocionUpdateActivo.producto_destino_promocion, [productoDestinoPromocionUpdate.id]);
        } else if(productoDestinoPromocionUpdate){
            promocionproductoDestinoPromocionUpdateActivo.producto_destino_promocion = union(promocionproductoDestinoPromocionUpdateActivo.producto_destino_promocion ? promocionproductoDestinoPromocionUpdateActivo.producto_destino_promocion : [], [productoDestinoPromocionUpdate.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: promocionproductoDestinoPromocionUpdateActivo,
        //nuevos: productoDestinoPromocionUpdate && productoDestinoPromocionUpdate.idPromocion ? union(state.nuevos, [productoDestinoPromocionUpdate.idPromocion]) : state.nuevos,
    });
case DELETE_PRODUCTODESTINOPROMOCION:
    let promocionproductoDestinoPromocionDeleteActivo = clone(state.nuevo);
    Object.values(action.productoDestinoPromocion).map((productoDestinoPromocionDelete) => {
        if(productoDestinoPromocionDelete && productoDestinoPromocionDelete.idPromocion && productoDestinoPromocionDelete.idPromocion && promocionproductoDestinoPromocionDeleteActivo[productoDestinoPromocionDelete.idPromocion]){
            if(productoDestinoPromocionDelete.idPromocion.toString().indexOf("-") !== -1)
                promocionproductoDestinoPromocionDeleteActivo[productoDestinoPromocionDelete.idPromocion].producto_destino_promocion = difference(promocionproductoDestinoPromocionDeleteActivo.producto_destino_promocion, [productoDestinoPromocionDelete.id]);
        } else if (productoDestinoPromocionDelete) {
            promocionproductoDestinoPromocionDeleteActivo.producto_destino_promocion = difference(promocionproductoDestinoPromocionDeleteActivo.producto_destino_promocion ? promocionproductoDestinoPromocionDeleteActivo.producto_destino_promocion : [], [productoDestinoPromocionDelete.id]);
        }
    });
    return Object.assign({}, state, {
        nuevo: promocionproductoDestinoPromocionDeleteActivo,
        //nuevos: productoDestinoPromocionDelete && productoDestinoPromocionDelete.idPromocion ? union(state.nuevos, [productoDestinoPromocionDelete.idPromocion]) : state.nuevos,
    });

             
        case DELETE_PROMOCION:
           let datoPromocionDelete = action.promocion;
           let idsDelete = [];
           Object.values(action.promocion).map((promocionDelete) => {
               if (promocionDelete && promocionDelete.id)
                   idsDelete.push(promocionDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoPromocionDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_PROMOCION:
                  let datoPromocionDeleteCreate = action.promocion;
                  let idsDeleteCreate = [];
                  Object.values(action.promocion).map((promocionDelete) => {
                      if (promocionDelete && promocionDelete.id)
                          idsDeleteCreate.push(promocionDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoPromocionDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PROMOCION:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.promocion,
                error: null,
            });
        case REQUEST_DELETE_PROMOCION:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PROMOCION:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_PROMOCION:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_PROMOCION:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_PROMOCION:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const promociones = combineReducers({
    byId: promocionesById,
    allIds: allPromociones,
    update: update,
    create: create,
    totalPromociones: totalPromociones,
    delete: deleter,
    print: print
});

export default promociones;