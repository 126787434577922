import {makeStyles} from '@material-ui/core/styles';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    notFound: {
        backgroundColor: "#383838",
        height: "100vh",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& h1": {
            color: "#4c4c4c",
        },
        "& p": {
            color: "white",
        }
    }
}));
export default function NotFound() {
    const classes = useStyles();
    return (
        <div className={classes.notFound}>
            <Typography variant={"h1"} component={"h1"}>😵</Typography>
            <Typography variant={"h1"} component={"h1"}>ERROR 404</Typography>
            <Typography variant={"body1"}>Página no encontrada</Typography>
        </div>
    );
}