import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CarrouselCategorias from "../../customComponents/Carruseles/CarrouselCategorias";
import {Box, Typography} from "@material-ui/core";
import Line from "../../customComponents/Line";
import {fetchMarcasIfNeeded} from "../../actions/MarcaActions";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    bannerRoot: {
        padding: "90px 0 220px 0",
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down(714)]: {
            marginTop: "-300px",
            zIndex: "4",
            paddingTop: "100px",
            paddingBottom: "230px",
        },
        [theme.breakpoints.up(2000)]: {
            paddingTop: "220px",
        },
        "& .item": {
            margin: "0 20px",
            width: "90px",
            "& img": {
                width: "100%",
                height: "100px",
                objectFit: "contain",
            },
        },
    },
    containerTitulo: {
        marginBottom: "60px",
    },
}));

export default function NuestrasMarcas() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const marcas = useSelector(state => state.marcas);

    useEffect(() => {
        dispatch(fetchMarcasIfNeeded({publicar: 1}));
    }, []);

    let Sliders = marcas.allIds.map((idMarca) => {
        let marca = marcas.byId.marcas[idMarca];
        if (marca && marca.urlLogo)
            return (
                <div className="item">
                    <img alt={"slider"}
                         src={marca.urlLogo}/>
                </div>
            )
    })

    return (
        <Box component={"div"} className={classes.bannerRoot}>
            <Box component={"div"} className={classes.containerTitulo}>
                <Typography variant={"h1"} align={"center"} style={{color: "#003462"}}>
                    Nuestras Marcas
                </Typography>
                <Line width={"70px"} height={"15px"} align={"center"} borderBottomColor={"#59c4c6"}
                      borderBottomWidth={"3px"}/>
            </Box>
            {Sliders.length > 0 ?
                <CarrouselCategorias>
                    {Sliders}
                </CarrouselCategorias> : ""
            }
        </Box>
    );
}
