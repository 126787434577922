import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Components
import { IconButton, Toolbar } from '@material-ui/core';

//Icons
import { Menu as MenuIcon, } from '@material-ui/icons';
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    toolBar: {
        minHeight: "50px",
        [theme.breakpoints.down(305)]: {
            minHeight: "30px",
        },
    },
    logo: {
        width: "100%",
        maxWidth: "65px",
        maxHeight: "72px",
        objectFit: "contain",
        margin: "15px",
        [theme.breakpoints.down(305)]: {
            margin: 0,
            maxWidth: "45px",
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.custom.shape50.borderRadius,
        backgroundColor: theme.palette.background.paper,
        marginLeft: theme.spacing(1),
        width: '100%',
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.secondary,
    },
    inputRoot: {
        color: 'inherit',
        width: "100%",
    },
    inputInput: {
        fontSize: "11px",
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        width: '100%',
    },
    subToolBar: {
        minHeight: "21px",
        paddingBottom: "7px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    containerDireccion: {
        color: "#fff",
        display: "flex",
        alignItems: "center",
        "& p": {
            maxWidth: "calc(100vw - 155px)",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
        "& svg": {
            color: "#fff",
            fontSize: "17px",
            "&:last-child": {
                color: "#fafafa70",
            }
        },
    },
    menuIcon: {
        [theme.breakpoints.down(305)]: {
            padding: 0,
        },
    }
}));

export default function ResponsiveToolbar(props) {
    const classes = useStyles();
    return (
        <Toolbar id={"responsive-toolbar"} className={classes.toolBar}>
            <Logo className={classes.logo}/>
            <div className={classes.grow}/>
            <IconButton edge="start" color="inherit" aria-label="menu"
                        onClick={(e) => props.toggleNavLeft(e, true)}>
                <MenuIcon className={classes.menuIcon}/>
            </IconButton>
        </Toolbar>
    );
}
