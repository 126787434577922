import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
import * as PropTypes from "prop-types";
//Usar este en vez de Container con maxwidth porque le mete muchos margenes

const BoxCustom = withStyles((theme) => ({
    root: {
        maxWidth: "1220px",
        margin: "0 auto",
        display: "block",
        width: "100%",
    },
}))(Box);

export default function ContainerLimit(props) {
    return (
        <BoxCustom component={"div"} className={props.className} style={props.style}>
            {props.children}
        </BoxCustom>
    )
}

ContainerLimit.propTypes = {
    style: PropTypes.object,
    className: PropTypes.any,
};
