import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const contacto = new schema.Entity('contactos', {}, {idAttribute: "id"});

    const mySchema = {contactos: [contacto]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const contacto = new schema.Entity('contactos', {}, {idAttribute: "id"});

    const mySchema = contacto;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const contacto = new schema.Entity('contactos', {}, {idAttribute: "id"});

    const mySchema = contacto;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


