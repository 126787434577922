//api
import tiendasApi from "../api/tiendasApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeTiendas";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//TIENDAS
export const REQUEST_TIENDAS = 'REQUEST_TIENDAS';
export const RECEIVE_TIENDAS = 'RECEIVE_TIENDAS';
export const INVALIDATE_TIENDAS = 'INVALIDATE_TIENDAS';
export const ERROR_TIENDAS = "ERROR_TIENDAS";
export const RESET_TIENDAS = "RESET_TIENDAS";


export function invalidateTiendas() {
    return {
        type: INVALIDATE_TIENDAS
    }
}

function requestTiendas() {
    return {
        type: REQUEST_TIENDAS,
    }
}

function receiveTiendas(json) {
    return {
        type: RECEIVE_TIENDAS,
    tiendas: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorTiendas(error) {
    return {
        type: ERROR_TIENDAS,
        error: error,
    }
}

export function resetTiendas() {
    return {
        type: RESET_TIENDAS
    }
}

export function fetchTiendas(filtros) {
    return dispatch => {
        dispatch(requestTiendas());
        return tiendasApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveTiendas(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorTiendas(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorTiendas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchTiendas(state) {
    const tiendas = state.tiendas.byId;
    if (!tiendas) {
        return true
    } else if (tiendas.isFetching) {
        return false
    } else {
        return tiendas.didInvalidate;
    }
}

export function fetchTiendasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchTiendas(getState())) {
            return dispatch(fetchTiendas(filtros))
        }
    }
}


//MODEL
export const REQUEST_TIENDA = 'REQUEST_TIENDA';
export const RECEIVE_TIENDA = 'RECEIVE_TIENDA';
export const INVALIDATE_TIENDA = 'INVALIDATE_TIENDA';
export const ERROR_TIENDA = "ERROR_TIENDA";
export const RESET_TIENDA = "RESET_TIENDA";


export function invalidateTienda() {
    return {
        type: INVALIDATE_TIENDA
    }
}

function requestTienda() {
    return {
        type: REQUEST_TIENDA,
    }
}

export function receiveTienda(json) {
    return {
        type: RECEIVE_TIENDA,
    tienda: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorTienda(error) {
    return {
        type: ERROR_TIENDA,
        error: error,
    }
}

export function fetchTienda(idTienda) {
    return dispatch => {
        dispatch(requestTienda());
        return tiendasApi.getOne(idTienda)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveTienda(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorTienda(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorTienda(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_TIENDA = 'UPDATE_TIENDA';
export const REQUEST_UPDATE_TIENDA = "REQUEST_UPDATE_TIENDA";
export const SUCCESS_UPDATE_TIENDA = "SUCCESS_UPDATE_TIENDA";
export const ERROR_UPDATE_TIENDA = "ERROR_UPDATE_TIENDA";
export const RESET_UPDATE_TIENDA = "RESET_UPDATE_TIENDA";
export const DELETE_UPDATE_TIENDA = "DELETE_UPDATE_TIENDA";

function requestUpdateTienda() {
    return {
        type: REQUEST_UPDATE_TIENDA,
    }
}

function receiveUpdateTienda(tienda) {
    return {
        type: SUCCESS_UPDATE_TIENDA,
        receivedAt: Date.now(),
        tienda: normalizeDato(tienda)
    }
}

function errorUpdateTienda(error) {
    return {
        type: ERROR_UPDATE_TIENDA,
        error: error,
    }
}

export function resetUpdateTienda() {
    return {
        type: RESET_UPDATE_TIENDA,
    }
}

export function updateTienda(tienda) {
    return {
        type: UPDATE_TIENDA,
        tienda
    }
}

export function saveUpdateTienda() {
    return (dispatch, getState) => {
        dispatch(requestUpdateTienda());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let tienda = denormalizeDato(getState().tiendas.update.activo, store);

        return tiendasApi.saveUpdate(tienda)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateTienda(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateTienda(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateTiendas(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateTiendas(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateTienda(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateTienda(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateTienda(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateTienda(tienda) {
    return {
        type: DELETE_UPDATE_TIENDA,
        tienda
    }
}

//UPDATE TIENDAS
export const REQUEST_UPDATE_TIENDAS = "REQUEST_UPDATE_TIENDAS";
export const SUCCESS_UPDATE_TIENDAS = "SUCCESS_UPDATE_TIENDAS";
export const ERROR_UPDATE_TIENDAS = "ERROR_UPDATE_TIENDAS";
export const RESET_UPDATE_TIENDAS = "RESET_UPDATE_TIENDAS";

function requestUpdateTiendas() {
    return {
        type: REQUEST_UPDATE_TIENDAS,
}
}

function receiveUpdateTiendas(tiendas) {
    return {
        type: SUCCESS_UPDATE_TIENDAS,
    receivedAt: Date.now(),
        tiendas: normalizeDatos(tiendas)
}
}

function errorUpdateTiendas(error) {
    return {
        type: ERROR_UPDATE_TIENDAS,
    error: error,
}
}

export function resetUpdateTiendas() {
    return {
        type: RESET_UPDATE_TIENDAS,
}
}

export function saveUpdateTiendas() {
    return (dispatch, getState) => {
        dispatch(requestUpdateTiendas());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let tiendas = getState().tiendas.update.activos.map((idTienda) => {
            return denormalizeDato(getState().tiendas.update.activo[idTienda], store);
        });

        return tiendasApi.saveUpdateTiendas(tiendas)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateTiendas(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateTiendas(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateTiendas(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateTiendas(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateTiendas(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateTiendas(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA TIENDA
export const CREATE_TIENDA = 'CREATE_TIENDA';
export const REQUEST_CREATE_TIENDA = "REQUEST_CREATE_TIENDA";
export const SUCCESS_CREATE_TIENDA = "SUCCESS_CREATE_TIENDA";
export const ERROR_CREATE_TIENDA = "ERROR_CREATE_TIENDA";
export const RESET_CREATE_TIENDA = "RESET_CREATE_TIENDA";
export const DELETE_CREATE_TIENDA = "DELETE_CREATE_TIENDA";


//ALTA TIENDA
function requestCreateTienda() {
    return {
        type: REQUEST_CREATE_TIENDA,
    }
}

function receiveCreateTienda(tienda) {
    return {
        type: SUCCESS_CREATE_TIENDA,
        receivedAt: Date.now(),
        tienda: normalizeDato(tienda)
    }
}

export function errorCreateTienda(error) {
    return {
        type: ERROR_CREATE_TIENDA,
        error: error,
    }
}

export function resetCreateTienda() {
    return {
        type: RESET_CREATE_TIENDA
    }
}

export function createTienda(tienda) {
    return {
        type: CREATE_TIENDA,
        tienda
    }
}

export function deleteCreateTienda(tienda) {
    return {
        type: DELETE_CREATE_TIENDA,
        tienda
    }
}

export function saveCreateTienda() {
    return (dispatch, getState) => {
        dispatch(requestCreateTienda());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let tienda = denormalizeDato(getState().tiendas.create.nuevo, store);

        return tiendasApi.saveCreate(tienda)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateTienda(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateTienda(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateTiendas(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateTiendas(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateTienda(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateTienda(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateTienda(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE TIENDAS
export const REQUEST_CREATE_TIENDAS = "REQUEST_CREATE_TIENDAS";
export const SUCCESS_CREATE_TIENDAS = "SUCCESS_CREATE_TIENDAS";
export const ERROR_CREATE_TIENDAS = "ERROR_CREATE_TIENDAS";
export const RESET_CREATE_TIENDAS = "RESET_CREATE_TIENDAS";

function requestCreateTiendas() {
    return {
        type: REQUEST_CREATE_TIENDAS,
}
}

function receiveCreateTiendas(tiendas) {
    return {
        type: SUCCESS_CREATE_TIENDAS,
    receivedAt: Date.now(),
        tiendas: normalizeDatos(tiendas)
}
}

function errorCreateTiendas(error) {
    return {
        type: ERROR_CREATE_TIENDAS,
    error: error,
}
}

export function resetCreateTiendas() {
    return {
        type: RESET_CREATE_TIENDAS,
}
}

export function saveCreateTiendas() {
    return (dispatch, getState) => {
        dispatch(requestCreateTiendas());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let tiendas = getState().tiendas.create.nuevos.map((idTienda) => {
            return denormalizeDato(getState().tiendas.create.nuevo[idTienda], store);
        });

        return tiendasApi.saveCreateTiendas(tiendas)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateTiendas(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateTiendas(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateTiendas(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateTiendas(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateTiendas(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateTiendas(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE TIENDA
export const DELETE_TIENDA = 'DELETE_TIENDA';
export const REQUEST_DELETE_TIENDA = "REQUEST_DELETE_TIENDA";
export const SUCCESS_DELETE_TIENDA = "SUCCESS_DELETE_TIENDA";
export const ERROR_DELETE_TIENDA = "ERROR_DELETE_TIENDA";
export const RESET_DELETE_TIENDA = "RESET_DELETE_TIENDA";

function requestDeleteTienda() {
    return {
        type: REQUEST_DELETE_TIENDA,
    }
}

function receiveDeleteTienda(tienda) {
    return {
        type: SUCCESS_DELETE_TIENDA,
        receivedAt: Date.now(),
        tienda: normalizeDato(tienda)
    }
}

function errorDeleteTienda(error) {
    return {
        type: ERROR_DELETE_TIENDA,
        error: error,
    }
}

export function resetDeleteTienda(error) {
    return {
        type: RESET_DELETE_TIENDA,
        error: error,
    }
}

export function deleteTienda(tienda) {
    return {
        type: DELETE_TIENDA,
        tienda
    }
}

export function saveDeleteTienda(tienda) {
    return dispatch => {
        dispatch(requestDeleteTienda());
        return tiendasApi.saveDelete(tienda)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteTienda());
                            dispatch(receiveDeleteTienda(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteTienda(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteTienda(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteTienda(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteTienda(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteTienda(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT TIENDA
export const REQUEST_PRINT_TIENDA = "REQUEST_PRINT_TIENDA";
export const SUCCESS_PRINT_TIENDA = "SUCCESS_PRINT_TIENDA";
export const ERROR_PRINT_TIENDA = "ERROR_PRINT_TIENDA";
export const RESET_PRINT_TIENDA = "RESET_PRINT_TIENDA";

function requestPrintTienda() {
    return {
        type: REQUEST_PRINT_TIENDA,
    }
}

function receivePrintTienda(turnos) {
    return {
        type: SUCCESS_PRINT_TIENDA,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintTienda(error) {
    return {
        type: ERROR_PRINT_TIENDA,
        error: error,
    }
}

export function resetPrintTienda() {
    return {
        type: RESET_PRINT_TIENDA,
    }
}

export function printTienda(idTienda) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintTienda());
        return tiendasApi.printTienda(idTienda)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintTienda(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintTienda(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintTienda(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintTienda(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintTienda(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}