//api
import bannersApi from "../api/bannersApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeBanners";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//BANNERS
export const REQUEST_BANNERS = 'REQUEST_BANNERS';
export const RECEIVE_BANNERS = 'RECEIVE_BANNERS';
export const INVALIDATE_BANNERS = 'INVALIDATE_BANNERS';
export const ERROR_BANNERS = "ERROR_BANNERS";
export const RESET_BANNERS = "RESET_BANNERS";


export function invalidateBanners() {
    return {
        type: INVALIDATE_BANNERS
    }
}

function requestBanners() {
    return {
        type: REQUEST_BANNERS,
    }
}

function receiveBanners(json) {
    return {
        type: RECEIVE_BANNERS,
    banners: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorBanners(error) {
    return {
        type: ERROR_BANNERS,
        error: error,
    }
}

export function resetBanners() {
    return {
        type: RESET_BANNERS
    }
}

export function fetchBanners(filtros) {
    return dispatch => {
        dispatch(requestBanners());
        return bannersApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveBanners(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorBanners(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorBanners(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchBanners(state) {
    const banners = state.banners.byId;
    if (!banners) {
        return true
    } else if (banners.isFetching) {
        return false
    } else {
        return banners.didInvalidate;
    }
}

export function fetchBannersIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchBanners(getState())) {
            return dispatch(fetchBanners(filtros))
        }
    }
}


//MODEL
export const REQUEST_BANNER = 'REQUEST_BANNER';
export const RECEIVE_BANNER = 'RECEIVE_BANNER';
export const INVALIDATE_BANNER = 'INVALIDATE_BANNER';
export const ERROR_BANNER = "ERROR_BANNER";
export const RESET_BANNER = "RESET_BANNER";


export function invalidateBanner() {
    return {
        type: INVALIDATE_BANNER
    }
}

function requestBanner() {
    return {
        type: REQUEST_BANNER,
    }
}

export function receiveBanner(json) {
    return {
        type: RECEIVE_BANNER,
    banner: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorBanner(error) {
    return {
        type: ERROR_BANNER,
        error: error,
    }
}

export function fetchBanner(idBanner) {
    return dispatch => {
        dispatch(requestBanner());
        return bannersApi.getOne(idBanner)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveBanner(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorBanner(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorBanner(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const REQUEST_UPDATE_BANNER = "REQUEST_UPDATE_BANNER";
export const SUCCESS_UPDATE_BANNER = "SUCCESS_UPDATE_BANNER";
export const ERROR_UPDATE_BANNER = "ERROR_UPDATE_BANNER";
export const RESET_UPDATE_BANNER = "RESET_UPDATE_BANNER";
export const DELETE_UPDATE_BANNER = "DELETE_UPDATE_BANNER";

function requestUpdateBanner() {
    return {
        type: REQUEST_UPDATE_BANNER,
    }
}

function receiveUpdateBanner(banner) {
    return {
        type: SUCCESS_UPDATE_BANNER,
        receivedAt: Date.now(),
        banner: normalizeDato(banner)
    }
}

function errorUpdateBanner(error) {
    return {
        type: ERROR_UPDATE_BANNER,
        error: error,
    }
}

export function resetUpdateBanner() {
    return {
        type: RESET_UPDATE_BANNER,
    }
}

export function updateBanner(banner) {
    return {
        type: UPDATE_BANNER,
        banner
    }
}

export function saveUpdateBanner() {
    return (dispatch, getState) => {
        dispatch(requestUpdateBanner());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let banner = denormalizeDato(getState().banners.update.activo, store);

        return bannersApi.saveUpdate(banner)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateBanner(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateBanner(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateBanners(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateBanners(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateBanner(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateBanner(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateBanner(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateBanner(banner) {
    return {
        type: DELETE_UPDATE_BANNER,
        banner
    }
}

//UPDATE BANNERS
export const REQUEST_UPDATE_BANNERS = "REQUEST_UPDATE_BANNERS";
export const SUCCESS_UPDATE_BANNERS = "SUCCESS_UPDATE_BANNERS";
export const ERROR_UPDATE_BANNERS = "ERROR_UPDATE_BANNERS";
export const RESET_UPDATE_BANNERS = "RESET_UPDATE_BANNERS";

function requestUpdateBanners() {
    return {
        type: REQUEST_UPDATE_BANNERS,
}
}

function receiveUpdateBanners(banners) {
    return {
        type: SUCCESS_UPDATE_BANNERS,
    receivedAt: Date.now(),
        banners: normalizeDatos(banners)
}
}

function errorUpdateBanners(error) {
    return {
        type: ERROR_UPDATE_BANNERS,
    error: error,
}
}

export function resetUpdateBanners() {
    return {
        type: RESET_UPDATE_BANNERS,
}
}

export function saveUpdateBanners() {
    return (dispatch, getState) => {
        dispatch(requestUpdateBanners());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let banners = getState().banners.update.activos.map((idBanner) => {
            return denormalizeDato(getState().banners.update.activo[idBanner], store);
        });

        return bannersApi.saveUpdateBanners(banners)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateBanners(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateBanners(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateBanners(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateBanners(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateBanners(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateBanners(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA BANNER
export const CREATE_BANNER = 'CREATE_BANNER';
export const REQUEST_CREATE_BANNER = "REQUEST_CREATE_BANNER";
export const SUCCESS_CREATE_BANNER = "SUCCESS_CREATE_BANNER";
export const ERROR_CREATE_BANNER = "ERROR_CREATE_BANNER";
export const RESET_CREATE_BANNER = "RESET_CREATE_BANNER";
export const DELETE_CREATE_BANNER = "DELETE_CREATE_BANNER";


//ALTA BANNER
function requestCreateBanner() {
    return {
        type: REQUEST_CREATE_BANNER,
    }
}

function receiveCreateBanner(banner) {
    return {
        type: SUCCESS_CREATE_BANNER,
        receivedAt: Date.now(),
        banner: normalizeDato(banner)
    }
}

export function errorCreateBanner(error) {
    return {
        type: ERROR_CREATE_BANNER,
        error: error,
    }
}

export function resetCreateBanner() {
    return {
        type: RESET_CREATE_BANNER
    }
}

export function createBanner(banner) {
    return {
        type: CREATE_BANNER,
        banner
    }
}

export function deleteCreateBanner(banner) {
    return {
        type: DELETE_CREATE_BANNER,
        banner
    }
}

export function saveCreateBanner() {
    return (dispatch, getState) => {
        dispatch(requestCreateBanner());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let banner = denormalizeDato(getState().banners.create.nuevo, store);

        return bannersApi.saveCreate(banner)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateBanner(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateBanner(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateBanners(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateBanners(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateBanner(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateBanner(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateBanner(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE BANNERS
export const REQUEST_CREATE_BANNERS = "REQUEST_CREATE_BANNERS";
export const SUCCESS_CREATE_BANNERS = "SUCCESS_CREATE_BANNERS";
export const ERROR_CREATE_BANNERS = "ERROR_CREATE_BANNERS";
export const RESET_CREATE_BANNERS = "RESET_CREATE_BANNERS";

function requestCreateBanners() {
    return {
        type: REQUEST_CREATE_BANNERS,
}
}

function receiveCreateBanners(banners) {
    return {
        type: SUCCESS_CREATE_BANNERS,
    receivedAt: Date.now(),
        banners: normalizeDatos(banners)
}
}

function errorCreateBanners(error) {
    return {
        type: ERROR_CREATE_BANNERS,
    error: error,
}
}

export function resetCreateBanners() {
    return {
        type: RESET_CREATE_BANNERS,
}
}

export function saveCreateBanners() {
    return (dispatch, getState) => {
        dispatch(requestCreateBanners());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let banners = getState().banners.create.nuevos.map((idBanner) => {
            return denormalizeDato(getState().banners.create.nuevo[idBanner], store);
        });

        return bannersApi.saveCreateBanners(banners)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateBanners(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateBanners(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateBanners(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateBanners(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateBanners(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateBanners(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE BANNER
export const DELETE_BANNER = 'DELETE_BANNER';
export const REQUEST_DELETE_BANNER = "REQUEST_DELETE_BANNER";
export const SUCCESS_DELETE_BANNER = "SUCCESS_DELETE_BANNER";
export const ERROR_DELETE_BANNER = "ERROR_DELETE_BANNER";
export const RESET_DELETE_BANNER = "RESET_DELETE_BANNER";

function requestDeleteBanner() {
    return {
        type: REQUEST_DELETE_BANNER,
    }
}

function receiveDeleteBanner(banner) {
    return {
        type: SUCCESS_DELETE_BANNER,
        receivedAt: Date.now(),
        banner: normalizeDato(banner)
    }
}

function errorDeleteBanner(error) {
    return {
        type: ERROR_DELETE_BANNER,
        error: error,
    }
}

export function resetDeleteBanner(error) {
    return {
        type: RESET_DELETE_BANNER,
        error: error,
    }
}

export function deleteBanner(banner) {
    return {
        type: DELETE_BANNER,
        banner
    }
}

export function saveDeleteBanner(banner) {
    return dispatch => {
        dispatch(requestDeleteBanner());
        return bannersApi.saveDelete(banner)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteBanner());
                            dispatch(receiveDeleteBanner(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteBanner(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteBanner(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteBanner(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteBanner(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteBanner(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT BANNER
export const REQUEST_PRINT_BANNER = "REQUEST_PRINT_BANNER";
export const SUCCESS_PRINT_BANNER = "SUCCESS_PRINT_BANNER";
export const ERROR_PRINT_BANNER = "ERROR_PRINT_BANNER";
export const RESET_PRINT_BANNER = "RESET_PRINT_BANNER";

function requestPrintBanner() {
    return {
        type: REQUEST_PRINT_BANNER,
    }
}

function receivePrintBanner(turnos) {
    return {
        type: SUCCESS_PRINT_BANNER,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintBanner(error) {
    return {
        type: ERROR_PRINT_BANNER,
        error: error,
    }
}

export function resetPrintBanner() {
    return {
        type: RESET_PRINT_BANNER,
    }
}

export function printBanner(idBanner) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintBanner());
        return bannersApi.printBanner(idBanner)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintBanner(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintBanner(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintBanner(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintBanner(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintBanner(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}