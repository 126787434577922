import {
    INVALIDATE_BANNERS,
    ERROR_BANNERS,
    RECEIVE_BANNERS,
    REQUEST_BANNERS,
    RESET_BANNERS,
    ERROR_BANNER,
    RECEIVE_BANNER,
    REQUEST_BANNER,
    UPDATE_BANNER,
    REQUEST_UPDATE_BANNER,
    SUCCESS_UPDATE_BANNER,
    ERROR_UPDATE_BANNER,
    RESET_UPDATE_BANNER,
    REQUEST_UPDATE_BANNERS,
    SUCCESS_UPDATE_BANNERS,
    ERROR_UPDATE_BANNERS,
    RESET_UPDATE_BANNERS,
    CREATE_BANNER,
    ERROR_CREATE_BANNER,
    REQUEST_CREATE_BANNER,
    RESET_CREATE_BANNER,
    SUCCESS_CREATE_BANNER,
    REQUEST_CREATE_BANNERS,
    SUCCESS_CREATE_BANNERS,
    ERROR_CREATE_BANNERS,
    RESET_CREATE_BANNERS,
    DELETE_BANNER,
    DELETE_CREATE_BANNER,
    DELETE_UPDATE_BANNER,
    REQUEST_DELETE_BANNER,
    SUCCESS_DELETE_BANNER,
    ERROR_DELETE_BANNER,
    RESET_DELETE_BANNER,
    REQUEST_PRINT_BANNER,
    SUCCESS_PRINT_BANNER,
    ERROR_PRINT_BANNER,
    RESET_PRINT_BANNER
} from '../actions/BannerActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        banners: {},
    }
}

function bannersById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_BANNERS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_BANNERS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_BANNERS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_BANNERS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                banners: {}
            });
        case RECEIVE_BANNERS:
            let dato = action.banners.entities.banners;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                banners: merge({}, state.banners, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_BANNER:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_BANNER:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_BANNER:
            let datoBanner = action.banner.entities.banners;
            return Object.assign({}, state, {
                banners: merge({}, state.banners, datoBanner),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_BANNER:
            let datoBannerEliminado = action.banner.entities.banners;
            return Object.assign({}, state, {
                banners: mergeWith(clone(datoBannerEliminado), state.banners, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_BANNER:
            let datoBannerCreado = action.banner.entities.banners;
            return Object.assign({}, state, {
                banners: mergeWith(clone(datoBannerCreado), state.banners, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_BANNERS:
            let datosBannerCreado = action.banners.entities.banners;
                return Object.assign({}, state, {
                    banners: mergeWith(clone(datosBannerCreado), state.banners, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_BANNER:
            let datoBannerActualizado = action.banner.entities.banners;
            return Object.assign({}, state, {
                banners: mergeWith(clone(datoBannerActualizado), state.banners, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_BANNERS:
            let datosBannerActualizado = action.banners.entities.banners;
                return Object.assign({}, state, {
                    banners: mergeWith(clone(datosBannerActualizado), state.banners, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                banners: {}
            });
        default:
            return state
    }
}


function allBanners(state = [], action) {
    switch (action.type) {
        case RECEIVE_BANNERS:
            return action.banners.result && action.banners.result.banners ? union(action.banners.result.banners, state) : (action.banners.result ? action.banners.result : state) ;
        case RECEIVE_BANNER:
                return action.banner.result ? union([action.banner.result], state) : state;
        
        case SUCCESS_CREATE_BANNER:
                   let datoBannerSCreate = action.banner.entities.banners;
                   let idNuevoSCreate = null;
                   if (Object.values(datoBannerSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoBannerSCreate)[0] && Object.values(datoBannerSCreate)[0].id ? Object.values(datoBannerSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_BANNERS:
                   let bannersCreate = action.banners.entities && action.banners.entities.banners ? action.banners.entities.banners : null;
                   return bannersCreate ?
                       union(state, Object.values(bannersCreate).map((banners) => {
                           return banners.id
                       })) : state;
        case RESET_BANNERS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalBanners(state = null, action) {
    switch (action.type) {
        case RECEIVE_BANNERS:
            return action.banners && action.banners.result.total ? action.banners.result.total : 0;
        case RESET_BANNERS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_BANNER:
            let dato = action.banner.entities.banners;
            let banner = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: banner ? banner : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_BANNER:
            let idsUpdate = [];
            Object.values(action.banner).map((bannerUpdate) => {
                if (bannerUpdate && bannerUpdate.id)
                    idsUpdate.push(bannerUpdate.id);
            });
            return merge({}, state, {
                activo: action.banner,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_BANNER:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_BANNER:
            let datoBannerActualizado = {};
            if (Object.values(action.banner.entities.banners).length > 0)
                datoBannerActualizado = Object.values(action.banner.entities.banners)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoBannerActualizado
            });
        case ERROR_UPDATE_BANNER:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_BANNERS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_BANNERS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_BANNERS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_BANNER:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_BANNER:
            let datoBannerDelete = action.banner;
            let idsDelete = [];
           Object.values(action.banner).map((bannerDelete) => {
               if (bannerDelete && bannerDelete.id)
                   idsDelete.push(bannerDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoBannerDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_BANNER:
                       let datoBannerDeleteUpdate = action.banner;
                       let idsDeleteUpdate = [];
                      Object.values(action.banner).map((bannerDelete) => {
                          if (bannerDelete && bannerDelete.id)
                              idsDeleteUpdate.push(bannerDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoBannerDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_BANNER:
                    let datoBannerDeleted = {};
                    if (Object.values(action.banner.entities.banners).length > 0)
                        datoBannerDeleted = Object.values(action.banner.entities.banners)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoBannerDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_BANNER:
             let idsCreate = [];
             Object.values(action.banner).map((bannerCreate) => {
                 if (bannerCreate && bannerCreate.id)
                     idsCreate.push(bannerCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.banner,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_BANNER:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_BANNER:
            let datoBannerNuevo = {};
            if (Object.values(action.banner.entities.banners).length > 0)
                datoBannerNuevo = Object.values(action.banner.entities.banners)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoBannerNuevo,
                nuevos: []
            });
        case ERROR_CREATE_BANNER:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_BANNERS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_BANNERS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_BANNERS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_BANNER:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_BANNER:
           let datoBannerDelete = action.banner;
           let idsDelete = [];
           Object.values(action.banner).map((bannerDelete) => {
               if (bannerDelete && bannerDelete.id)
                   idsDelete.push(bannerDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoBannerDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_BANNER:
                  let datoBannerDeleteCreate = action.banner;
                  let idsDeleteCreate = [];
                  Object.values(action.banner).map((bannerDelete) => {
                      if (bannerDelete && bannerDelete.id)
                          idsDeleteCreate.push(bannerDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoBannerDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_BANNER:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.banner,
                error: null,
            });
        case REQUEST_DELETE_BANNER:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_BANNER:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_BANNER:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_BANNER:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_BANNER:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_BANNER:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_BANNER:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const banners = combineReducers({
    byId: bannersById,
    allIds: allBanners,
    update: update,
    create: create,
    totalBanners: totalBanners,
    delete: deleter,
    print: print
});

export default banners;