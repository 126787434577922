import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

//Components
import { AppBar, useMediaQuery } from '@material-ui/core';
import DesktopToolbar from "./DesktopToolbar";
import ResponsiveToolbar from "./ResponsiveToolbar";
import ContainerLimit from "../../../customComponents/ContainerLimit";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: "transparent"
    },
    appBarResponsiveOnScroll: {
        backgroundColor: theme.palette.primary["main"]
    },
    appBarDesktopOnScroll: {
        backgroundColor: "#fff"
    },
}));


export default function NavSup(props) {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:975px)');
    const triggerNavOnScroll = useScrollTrigger();
    const triggerScrollColor = useScrollTrigger({disableHysteresis: true, threshold: 100});
    const history = useHistory();

    let pathname = history.location.pathname;
    let navBarOnPromocion = pathname === "/promociones";

    return (
        <div className={classes.grow}>
            <Slide appear={false} direction="down" in={!triggerNavOnScroll}>
                <AppBar elevation={triggerScrollColor ? 4 : 0} className={clsx([classes.appBar, {
                    [classes.appBarResponsiveOnScroll]: triggerScrollColor || (navBarOnPromocion && isMobile),
                    [classes.appBarDesktopOnScroll]: !isMobile && triggerScrollColor,
                }])}>
                    <ContainerLimit>
                        {isMobile ?
                            <ResponsiveToolbar toggleNavLeft={props.toggleNavLeft}/>
                            :
                            <DesktopToolbar navBarOnPromocion={navBarOnPromocion}
                                            triggerScrollColor={triggerScrollColor}/>
                        }
                    </ContainerLimit>
                </AppBar>
            </Slide>
        </div>
    );
}
