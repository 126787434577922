import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

//Components
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, useMediaQuery } from "@material-ui/core";
import NavSup from "./NavSup/NavSup";
import PaginaPrincipal from "../PaginaPrincipal/PaginaPrincipal";
import Carrito from "../Carrito/Carrito";
import NavLeft from "./NavLeft/NavLeft";
import CatalogoPromociones from "../PaginaPrincipal/CatalogoPromociones";
import Footer from "./Footer/Footer";

//Actions
const useStyles = makeStyles(theme => ({
    root: {
        // overflowX: "hidden",
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        backgroundColor: "#fff",
    },
    principal: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: 0,
    },
    contentShift: {
        width: "100%",
        overflowX: "hidden",
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: propsLayout => propsLayout.drawerCarritoWith,
    },
}))

export default function LayoutLanding() {
    const isMobile = useMediaQuery('(max-width:975px)');
    const drawerCarritoWidth = isMobile ? "100%" : 360;
    //Hack para que no de undefined si ponemos string en la variable drawerCarritoWidth
    let propsLayout = {drawerCarritoWith: isMobile ? 0 : drawerCarritoWidth};
    const classes = useStyles(propsLayout);

    const [darked, setDarked] = React.useState(false);
    const [openDrawerCarrito, setOpenDrawerCarrito] = React.useState(false);
    const [openNavLeft, setOpenNavLeft] = React.useState(false);
    const history = useHistory();
    const location = useLocation();

    //Effects
    useEffect(() => {
        // let favicon = document.getElementById("favicon");
        // if (favicon)
        //     favicon.href = "https://kitexperto.com/favicon.png";

        let themeColor = document.getElementById("themeColor");
        if (themeColor)
            themeColor.content = theme.palette.primary[theme.palette.type];

        document.title = "JVA S.R.L | Distribuidora";
    }, []);

    const handleCarritoDrawerClose = () => {
        if (isMobile)
            history.goBack();
        else
            setOpenDrawerCarrito(false);
    };

    const handleDrawerCarritoOpen = () => {
        if (isMobile)
            if (location.pathname !== "/carrito") {
                history.push("/carrito");
            } else {
                history.goBack();
            }
        else
            setOpenDrawerCarrito(!openDrawerCarrito)

    };

    const toggleTypeTheme = () => {
        setDarked(prev => !prev);
    };

    // const toggleNavLeft = (open) => (event) => {
    //     console.log("oglaa");
    //     event && event.preventDefault();
    //     if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //         return;
    //     }
    //
    //     setOpenNavLeft(open);
    // };

    const toggleNavLeft2 = (event, open) => {
        event && event.preventDefault();
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenNavLeft(open);
    };

    const theme = React.useMemo(() => createTheme({
        palette: {
            primary: {
                light: '#59c4c6',
                main: '#59c4c6',
                dark: '#005e5f',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ba68c8',
                main: '#3f2045',
                dark: '#e91e63',
                contrastText: '#fff',
            },
            success: {
                light: "#aed581",
                main: '#4caf50',
                dark: "#80936a"
            },
            error: {
                main: '#f44336',
                light: '#ffcdd2',
                dark: '#8b1a12',
            },
            disabled: {
                light: '#fafafa',
                main: "#f5f5f5"
            },
            default: {
                main: '#2196f3',
                light: "#64b5f6",
                dark: "#1f8de5"
            },
            attention: {
                main: "#FFF42B",
                light: "#fff5a4",
                dark: "#a59c10",
            },
            selected: {
                light: '#ee5b8b',
                dark: 'rgba(255,167,51,0.74)',
            },
            text: {
                light: "black",
                dark: "white"
            },
            type: darked ? "dark" : "light",
        },
        typography: {
            fontSize: 14,
            fontWeightLight: 100,
            fontWeightRegular: 200,
            fontWeightMedium: 300,
            fontWeightBold: 500,
            h1: {
                fontSize: "3rem",
                fontWeight: "300",
                "@media(max-width: 714px)": {
                    "&": {
                        fontSize: "1.94rem"
                    }
                }
            },
            h2: {
                fontSize: "1rem",
                fontWeight: "500",
                paddingTop: "5px",
                paddingLeft: "5px",
                marginTop: "5px",
                marginBottom: "5px"
            },
            body1: {
                fontFamily: "Helvetica LT Std",
                fontSize: "0.9rem",
                lineHeight: "1.5"
            },
            body3: {
                fontFamily: "Helvetica LT Std",
                fontSize: "0.9rem",
                lineHeight: "1",
            },
            body2: {
                fontSize: "0.8rem",
                fontFamily: "Helvetica LT Std",
                fontWeight: 100,
            },
            subtitle1: {
                fontFamily: "Helvetica LT Std",
            },
            h4: {
                fontFamily: "Helvetica LT Std",
                fontWeight: 500,
                fontSize: "1.3rem",
            },
            h5: {
                fontFamily: "Rubik",
                fontWeight: 600,
                fontSize: "1.2rem",
            },
            h6: {
                fontFamily: "Rubik",
                fontWeight: 100,
                fontSize: "1.1rem",
            },
            fontFamily: [
                'Rubik'
            ].join(','),
        },
        iconOutlined: {},
        custom: {
            shape50: {
                borderRadius: "50px",
            },
            shape2: {
                borderRadius: "2px",
            }
        },
    }), [darked]);
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <NavSup toggleTypeTheme={toggleTypeTheme}
                    darked={darked}
                    handleDrawerCarritoOpen={handleDrawerCarritoOpen}
                    toggleNavLeft={toggleNavLeft2}
            />
            <NavLeft toggleTypeTheme={toggleTypeTheme}
                     darked={darked}
                     openNavLeft={openNavLeft}
                     toggleNavLeft={toggleNavLeft2}
            />
            <div className={classes.root}>
                <main className={clsx(classes.principal, {
                    [classes.contentShift]: openDrawerCarrito,
                })}>
                    <Switch>
                        <Route path={"/promociones"} render={() => {
                            return (
                                <>
                                    <CatalogoPromociones/>
                                </>
                            )
                        }}/>
                        <Route path={"/"} render={() => {
                            return (
                                <>
                                    <PaginaPrincipal/>
                                </>
                            )
                        }}/>

                    </Switch>
                    {/*<Footer/>*/}
                </main>
            </div>
            {/*<MensajeCorrecto/>*/}
            {/*<MensajeError/>*/}
        </MuiThemeProvider>
    );
}

