import {
    INVALIDATE_ZONASENTREGA,
    ERROR_ZONASENTREGA,
    RECEIVE_ZONASENTREGA,
    REQUEST_ZONASENTREGA,
    RESET_ZONASENTREGA,
    ERROR_ZONAENTREGA,
    RECEIVE_ZONAENTREGA,
    REQUEST_ZONAENTREGA,
    UPDATE_ZONAENTREGA,
    REQUEST_UPDATE_ZONAENTREGA,
    SUCCESS_UPDATE_ZONAENTREGA,
    ERROR_UPDATE_ZONAENTREGA,
    RESET_UPDATE_ZONAENTREGA,
    REQUEST_UPDATE_ZONASENTREGA,
    SUCCESS_UPDATE_ZONASENTREGA,
    ERROR_UPDATE_ZONASENTREGA,
    RESET_UPDATE_ZONASENTREGA,
    CREATE_ZONAENTREGA,
    ERROR_CREATE_ZONAENTREGA,
    REQUEST_CREATE_ZONAENTREGA,
    RESET_CREATE_ZONAENTREGA,
    SUCCESS_CREATE_ZONAENTREGA,
    REQUEST_CREATE_ZONASENTREGA,
    SUCCESS_CREATE_ZONASENTREGA,
    ERROR_CREATE_ZONASENTREGA,
    RESET_CREATE_ZONASENTREGA,
    DELETE_ZONAENTREGA,
    DELETE_CREATE_ZONAENTREGA,
    DELETE_UPDATE_ZONAENTREGA,
    REQUEST_DELETE_ZONAENTREGA,
    SUCCESS_DELETE_ZONAENTREGA,
    ERROR_DELETE_ZONAENTREGA,
    RESET_DELETE_ZONAENTREGA,
    REQUEST_PRINT_ZONAENTREGA,
    SUCCESS_PRINT_ZONAENTREGA,
    ERROR_PRINT_ZONAENTREGA,
    RESET_PRINT_ZONAENTREGA
} from '../actions/ZonaEntregaActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        zonasEntrega: {},
    }
}

function zonasEntregaById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ZONASENTREGA:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ZONASENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ZONASENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ZONASENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                zonasEntrega: {}
            });
        case RECEIVE_ZONASENTREGA:
            let dato = action.zonasEntrega.entities.zonasEntrega;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                zonasEntrega: merge({}, state.zonasEntrega, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ZONAENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ZONAENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ZONAENTREGA:
            let datoZonaEntrega = action.zonaEntrega.entities.zonasEntrega;
            return Object.assign({}, state, {
                zonasEntrega: merge({}, state.zonasEntrega, datoZonaEntrega),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_ZONAENTREGA:
            let datoZonaEntregaEliminado = action.zonaEntrega.entities.zonasEntrega;
            return Object.assign({}, state, {
                zonasEntrega: mergeWith(clone(datoZonaEntregaEliminado), state.zonasEntrega, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ZONAENTREGA:
            let datoZonaEntregaCreado = action.zonaEntrega.entities.zonasEntrega;
            return Object.assign({}, state, {
                zonasEntrega: mergeWith(clone(datoZonaEntregaCreado), state.zonasEntrega, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ZONASENTREGA:
            let datosZonaEntregaCreado = action.zonasEntrega.entities.zonasEntrega;
                return Object.assign({}, state, {
                    zonasEntrega: mergeWith(clone(datosZonaEntregaCreado), state.zonasEntrega, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ZONAENTREGA:
            let datoZonaEntregaActualizado = action.zonaEntrega.entities.zonasEntrega;
            return Object.assign({}, state, {
                zonasEntrega: mergeWith(clone(datoZonaEntregaActualizado), state.zonasEntrega, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ZONASENTREGA:
            let datosZonaEntregaActualizado = action.zonasEntrega.entities.zonasEntrega;
                return Object.assign({}, state, {
                    zonasEntrega: mergeWith(clone(datosZonaEntregaActualizado), state.zonasEntrega, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                zonasEntrega: {}
            });
        default:
            return state
    }
}


function allZonasEntrega(state = [], action) {
    switch (action.type) {
        case RECEIVE_ZONASENTREGA:
            return action.zonasEntrega.result && action.zonasEntrega.result.zonasEntrega ? union(action.zonasEntrega.result.zonasEntrega, state) : (action.zonasEntrega.result ? action.zonasEntrega.result : state) ;
        case RECEIVE_ZONAENTREGA:
                return action.zonaEntrega.result ? union([action.zonaEntrega.result], state) : state;
        
        case SUCCESS_CREATE_ZONAENTREGA:
                   let datoZonaEntregaSCreate = action.zonaEntrega.entities.zonasEntrega;
                   let idNuevoSCreate = null;
                   if (Object.values(datoZonaEntregaSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoZonaEntregaSCreate)[0] && Object.values(datoZonaEntregaSCreate)[0].id ? Object.values(datoZonaEntregaSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ZONASENTREGA:
                   let zonasEntregaCreate = action.zonasEntrega.entities && action.zonasEntrega.entities.zonasEntrega ? action.zonasEntrega.entities.zonasEntrega : null;
                   return zonasEntregaCreate ?
                       union(state, Object.values(zonasEntregaCreate).map((zonasEntrega) => {
                           return zonasEntrega.id
                       })) : state;
        case RESET_ZONASENTREGA:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalZonasEntrega(state = null, action) {
    switch (action.type) {
        case RECEIVE_ZONASENTREGA:
            return action.zonasEntrega && action.zonasEntrega.result.total ? action.zonasEntrega.result.total : 0;
        case RESET_ZONASENTREGA:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ZONAENTREGA:
            let dato = action.zonaEntrega.entities.zonasEntrega;
            let zonaEntrega = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: zonaEntrega ? zonaEntrega : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ZONAENTREGA:
            let idsUpdate = [];
            Object.values(action.zonaEntrega).map((zonaEntregaUpdate) => {
                if (zonaEntregaUpdate && zonaEntregaUpdate.id)
                    idsUpdate.push(zonaEntregaUpdate.id);
            });
            return merge({}, state, {
                activo: action.zonaEntrega,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ZONAENTREGA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ZONAENTREGA:
            let datoZonaEntregaActualizado = {};
            if (Object.values(action.zonaEntrega.entities.zonasEntrega).length > 0)
                datoZonaEntregaActualizado = Object.values(action.zonaEntrega.entities.zonasEntrega)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoZonaEntregaActualizado
            });
        case ERROR_UPDATE_ZONAENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ZONASENTREGA:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ZONASENTREGA:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ZONASENTREGA:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ZONAENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_ZONAENTREGA:
            let datoZonaEntregaDelete = action.zonaEntrega;
            let idsDelete = [];
           Object.values(action.zonaEntrega).map((zonaEntregaDelete) => {
               if (zonaEntregaDelete && zonaEntregaDelete.id)
                   idsDelete.push(zonaEntregaDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoZonaEntregaDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ZONAENTREGA:
                       let datoZonaEntregaDeleteUpdate = action.zonaEntrega;
                       let idsDeleteUpdate = [];
                      Object.values(action.zonaEntrega).map((zonaEntregaDelete) => {
                          if (zonaEntregaDelete && zonaEntregaDelete.id)
                              idsDeleteUpdate.push(zonaEntregaDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoZonaEntregaDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ZONAENTREGA:
                    let datoZonaEntregaDeleted = {};
                    if (Object.values(action.zonaEntrega.entities.zonasEntrega).length > 0)
                        datoZonaEntregaDeleted = Object.values(action.zonaEntrega.entities.zonasEntrega)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoZonaEntregaDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ZONAENTREGA:
             let idsCreate = [];
             Object.values(action.zonaEntrega).map((zonaEntregaCreate) => {
                 if (zonaEntregaCreate && zonaEntregaCreate.id)
                     idsCreate.push(zonaEntregaCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.zonaEntrega,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ZONAENTREGA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ZONAENTREGA:
            let datoZonaEntregaNuevo = {};
            if (Object.values(action.zonaEntrega.entities.zonasEntrega).length > 0)
                datoZonaEntregaNuevo = Object.values(action.zonaEntrega.entities.zonasEntrega)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoZonaEntregaNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ZONAENTREGA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ZONASENTREGA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ZONASENTREGA:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ZONASENTREGA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ZONAENTREGA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_ZONAENTREGA:
           let datoZonaEntregaDelete = action.zonaEntrega;
           let idsDelete = [];
           Object.values(action.zonaEntrega).map((zonaEntregaDelete) => {
               if (zonaEntregaDelete && zonaEntregaDelete.id)
                   idsDelete.push(zonaEntregaDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoZonaEntregaDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ZONAENTREGA:
                  let datoZonaEntregaDeleteCreate = action.zonaEntrega;
                  let idsDeleteCreate = [];
                  Object.values(action.zonaEntrega).map((zonaEntregaDelete) => {
                      if (zonaEntregaDelete && zonaEntregaDelete.id)
                          idsDeleteCreate.push(zonaEntregaDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoZonaEntregaDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ZONAENTREGA:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.zonaEntrega,
                error: null,
            });
        case REQUEST_DELETE_ZONAENTREGA:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ZONAENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ZONAENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ZONAENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ZONAENTREGA:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ZONAENTREGA:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ZONAENTREGA:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const zonasEntrega = combineReducers({
    byId: zonasEntregaById,
    allIds: allZonasEntrega,
    update: update,
    create: create,
    totalZonasEntrega: totalZonasEntrega,
    delete: deleter,
    print: print
});

export default zonasEntrega;