import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

//Components
import { green } from "@material-ui/core/colors";

//Icons
import ProductoSinPromocion from "./ProductoSinPromocion";
import ProductoPromocionProductoIndividual from "./ProductoPromocionProductoIndividual";
import ProductoPromocionCombo from "./ProductoPromocionCombo";
import ProductoPromocionCompraPorVolumen from "./ProductoPromocionCompraPorVolumen";

const useStyles = makeStyles((theme) => ({
    producto: {
        position: "relative",
        width: "fit-content",
        margin: theme.spacing(2, 1),
        [theme.breakpoints.down(975)]: {
            margin: "16px 4px",
        },
        height: "auto",
    },
    wrapperProducto: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "14px",
        overflow: "hidden",
        boxShadow: theme.shadows[4],
        width: "284px",
        [theme.breakpoints.down(975)]: {
            width: "175px",
        },
        // width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",

    },
    containerImg: {
        margin: "0 auto",
        display: "block",
        textAlign: "center",
        "& img": {
            // maxHeight: "300px",
            maxWidth: "300px",
            height: "250px",
            width: "100%",
            objectFit: "contain",
            [theme.breakpoints.down(975)]: {
                height: "155px",
            }
        }
    },
    bodyProducto: {
        padding: theme.spacing(2),
        [theme.breakpoints.down(975)]: {
            padding: theme.spacing(1),
        },
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        justifyContent: "flex-start"
    },
    nombreProducto: {
        display: "-webkit-box",
        lineClamp: 3,
        boxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tfCantidad: {
        width: "100%",
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            padding: "0 7px",
        },
        "& .MuiInputBase-input": {
            textAlign: "center",
            padding: theme.spacing(1, 0),
            height: "1.25em",
        }
    },
    grow: {
        flexGrow: 1
    },
    btnAddCartContainer: {
        margin: theme.spacing(1, 0, 0, 0),
    },
    btnAddCart: {
        borderRadius: "8px",
        padding: "5px",
    },
    containerPrecio: {
        display: "block",
        flexDirection: "column"
    },
    containerPromocion: {
        margin: theme.spacing(0, 0, 1, 0),
        [theme.breakpoints.down(975)]: {
            margin: "0 0 2px 0",
        },
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        "& p": {
            lineHeight: "initial",
        },
    },
    descripcionPromo: {
        display: "-webkit-box",
        lineClamp: 3,
        boxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    chip: {
        color: green[500],
    },
}));

function areEqual(prevProps, nextProps) {
    /*
    retorna true si al pasar los nextProps a renderizar retorna
    el mismo resultado que al pasar los prevProps a renderizar,
    de otro modo retorna false
    */
    return true;
}

export default React.memo(function Producto(props) {
    const classes = useStyles();
    const [cantProducto, setCantProducto] = useState(0);
    const {producto, promocion, productos} = props;

    const tiendas = useSelector(state => state.tiendas);
    const tienda = tiendas.update.activo && tiendas.update.activo.id ? tiendas.update.activo : null;
    const monedas = useSelector(state => state.monedas);
    const moneda = monedas.update.activo && monedas.update.activo.id ? monedas.update.activo : null;
    const productosOrigenPromocion = useSelector(state => state.productosOrigenPromocion);

    return (
        <>
            {
                !promocion &&
                <ProductoSinPromocion {...props} moneda={moneda}/>
            }
            {
                promocion && promocion.formatoPromocion === "Producto individual" &&
                <ProductoPromocionProductoIndividual {...props} moneda={moneda}/>
            }
            {
                promocion && promocion.formatoPromocion === "Combo" &&
                <ProductoPromocionCombo {...props} moneda={moneda}/>
            }
            {
                promocion && promocion.formatoPromocion === "Compra por volumen" &&
                <ProductoPromocionCompraPorVolumen {...props} moneda={moneda}/>
            }
        </>
    );
}, areEqual);
