import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import { Box } from '@material-ui/core';
import c from "../../constants/Constants";
import slider1 from '../../assets/img/slider/slider1-newcp.png';
import slider2 from '../../assets/img/slider/slider2.png';
import footerSlider from "../../assets/img/slider/footerSlider.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchBannersIfNeeded } from "../../actions/BannerActions";

const useStyles = makeStyles((theme) => ({
    bannerRoot: {
        "& .item": {
            "& img": {
                width: "100%",
            },
        },
        "& .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction": {
            bottom: "120px",
            [theme.breakpoints.down(975)]: {
                bottom: "50px",
            },
            [theme.breakpoints.down(444)]: {
                bottom: "30px",
            },
        },
        "& .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet": {
            backgroundColor: "white",
            border: "1px solid #0000001a",
        }
    },
    footerSlider: {
        backgroundImage: `url(${footerSlider})`,
        backgroundSize: "cover",
        height: "200px",
        width: "100%",
        zIndex: "9",
        position: "relative",
        marginTop: "-200px",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down(975)]: {
            height: "100px",
            marginTop: "-100px",
        },
        [theme.breakpoints.down(444)]: {
            height: "60px",
            marginTop: "-55px",
        },
    }
}));

export default function Banners() {
    const classes = useStyles();
    const dispatch = useDispatch();
    //Stores
    const banners = useSelector(state => state.banners);
    //Effects
    useEffect(() => {
        let filtros = {
            publicar: true
        };
        dispatch(fetchBannersIfNeeded(filtros));
    }, []);
    const params = {
        slidesPerView: "auto",
        centeredSlides: true,
        // spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        // autoplay: {
        //     delay: 5000,
        // },
        loop: true,

    };

    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    let Sliders = [];

    if (banners)
        banners.allIds.map((key) => {
            let banner = banners.byId.banners[key];
            let idAntiCache = makeid(5);

            Sliders.push(
                <Link key={"banner" + banner.id} to={banner.url ? banner.url : ""}>
                    <div className="item">
                        <picture>
                            <source
                                srcSet={c.BASE_URL + '/banners/' + banner.id + "/mobile/" + idAntiCache}
                                media={'(max-width: 800px)'}/>
                            <img
                                src={c.BASE_URL + '/banners/' + banner.id + "/desktop/" + idAntiCache}/>
                        </picture>
                    </div>
                </Link>
            )
        })

    return (
        <>
            <div className={classes.bannerRoot} id={"inicio"}>
                {Sliders.length > 0 ?
                    <Swiper {...params} shouldSwiperUpdate updateOnWindowResize>
                        {Sliders}
                    </Swiper> : ""
                }
            </div>
            <Box component={"div"}
                 className={classes.footerSlider}
            />
        </>
    );
}
