import {
    INVALIDATE_CONTACTOS,
    ERROR_CONTACTOS,
    RECEIVE_CONTACTOS,
    REQUEST_CONTACTOS,
    RESET_CONTACTOS,
    ERROR_CONTACTO,
    RECEIVE_CONTACTO,
    REQUEST_CONTACTO,
    UPDATE_CONTACTO,
    REQUEST_UPDATE_CONTACTO,
    SUCCESS_UPDATE_CONTACTO,
    ERROR_UPDATE_CONTACTO,
    RESET_UPDATE_CONTACTO,
    REQUEST_UPDATE_CONTACTOS,
    SUCCESS_UPDATE_CONTACTOS,
    ERROR_UPDATE_CONTACTOS,
    RESET_UPDATE_CONTACTOS,
    CREATE_CONTACTO,
    ERROR_CREATE_CONTACTO,
    REQUEST_CREATE_CONTACTO,
    RESET_CREATE_CONTACTO,
    SUCCESS_CREATE_CONTACTO,
    REQUEST_CREATE_CONTACTOS,
    SUCCESS_CREATE_CONTACTOS,
    ERROR_CREATE_CONTACTOS,
    RESET_CREATE_CONTACTOS,
    DELETE_CONTACTO,
    DELETE_CREATE_CONTACTO,
    DELETE_UPDATE_CONTACTO,
    REQUEST_DELETE_CONTACTO,
    SUCCESS_DELETE_CONTACTO,
    ERROR_DELETE_CONTACTO,
    RESET_DELETE_CONTACTO,
    REQUEST_PRINT_CONTACTO,
    SUCCESS_PRINT_CONTACTO,
    ERROR_PRINT_CONTACTO,
    RESET_PRINT_CONTACTO
} from '../actions/ContactoActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        contactos: {},
    }
}

function contactosById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_CONTACTOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_CONTACTOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_CONTACTOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_CONTACTOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                contactos: {}
            });
        case RECEIVE_CONTACTOS:
            let dato = action.contactos.entities.contactos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                contactos: merge({}, state.contactos, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_CONTACTO:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_CONTACTO:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_CONTACTO:
            let datoContacto = action.contacto.entities.contactos;
            return Object.assign({}, state, {
                contactos: merge({}, state.contactos, datoContacto),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_CONTACTO:
            let datoContactoEliminado = action.contacto.entities.contactos;
            return Object.assign({}, state, {
                contactos: mergeWith(clone(datoContactoEliminado), state.contactos, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CONTACTO:
            let datoContactoCreado = action.contacto.entities.contactos;
            return Object.assign({}, state, {
                contactos: mergeWith(clone(datoContactoCreado), state.contactos, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CONTACTOS:
            let datosContactoCreado = action.contactos.entities.contactos;
                return Object.assign({}, state, {
                    contactos: mergeWith(clone(datosContactoCreado), state.contactos, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_CONTACTO:
            let datoContactoActualizado = action.contacto.entities.contactos;
            return Object.assign({}, state, {
                contactos: mergeWith(clone(datoContactoActualizado), state.contactos, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_CONTACTOS:
            let datosContactoActualizado = action.contactos.entities.contactos;
                return Object.assign({}, state, {
                    contactos: mergeWith(clone(datosContactoActualizado), state.contactos, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                contactos: {}
            });
        default:
            return state
    }
}


function allContactos(state = [], action) {
    switch (action.type) {
        case RECEIVE_CONTACTOS:
            return action.contactos.result && action.contactos.result.contactos ? union(action.contactos.result.contactos, state) : (action.contactos.result ? action.contactos.result : state) ;
        case RECEIVE_CONTACTO:
                return action.contacto.result ? union([action.contacto.result], state) : state;
        
        case SUCCESS_CREATE_CONTACTO:
                   let datoContactoSCreate = action.contacto.entities.contactos;
                   let idNuevoSCreate = null;
                   if (Object.values(datoContactoSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoContactoSCreate)[0] && Object.values(datoContactoSCreate)[0].id ? Object.values(datoContactoSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_CONTACTOS:
                   let contactosCreate = action.contactos.entities && action.contactos.entities.contactos ? action.contactos.entities.contactos : null;
                   return contactosCreate ?
                       union(state, Object.values(contactosCreate).map((contactos) => {
                           return contactos.id
                       })) : state;
        case RESET_CONTACTOS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalContactos(state = null, action) {
    switch (action.type) {
        case RECEIVE_CONTACTOS:
            return action.contactos && action.contactos.result.total ? action.contactos.result.total : 0;
        case RESET_CONTACTOS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_CONTACTO:
            let dato = action.contacto.entities.contactos;
            let contacto = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: contacto ? contacto : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_CONTACTO:
            let idsUpdate = [];
            Object.values(action.contacto).map((contactoUpdate) => {
                if (contactoUpdate && contactoUpdate.id)
                    idsUpdate.push(contactoUpdate.id);
            });
            return merge({}, state, {
                activo: action.contacto,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_CONTACTO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_CONTACTO:
            let datoContactoActualizado = {};
            if (Object.values(action.contacto.entities.contactos).length > 0)
                datoContactoActualizado = Object.values(action.contacto.entities.contactos)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoContactoActualizado
            });
        case ERROR_UPDATE_CONTACTO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_CONTACTOS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_CONTACTOS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_CONTACTOS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_CONTACTO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_CONTACTO:
            let datoContactoDelete = action.contacto;
            let idsDelete = [];
           Object.values(action.contacto).map((contactoDelete) => {
               if (contactoDelete && contactoDelete.id)
                   idsDelete.push(contactoDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoContactoDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_CONTACTO:
                       let datoContactoDeleteUpdate = action.contacto;
                       let idsDeleteUpdate = [];
                      Object.values(action.contacto).map((contactoDelete) => {
                          if (contactoDelete && contactoDelete.id)
                              idsDeleteUpdate.push(contactoDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoContactoDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_CONTACTO:
                    let datoContactoDeleted = {};
                    if (Object.values(action.contacto.entities.contactos).length > 0)
                        datoContactoDeleted = Object.values(action.contacto.entities.contactos)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoContactoDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_CONTACTO:
             let idsCreate = [];
             Object.values(action.contacto).map((contactoCreate) => {
                 if (contactoCreate && contactoCreate.id)
                     idsCreate.push(contactoCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.contacto,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_CONTACTO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_CONTACTO:
            let datoContactoNuevo = {};
            if (Object.values(action.contacto.entities.contactos).length > 0)
                datoContactoNuevo = Object.values(action.contacto.entities.contactos)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoContactoNuevo,
                nuevos: []
            });
        case ERROR_CREATE_CONTACTO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_CONTACTOS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_CONTACTOS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_CONTACTOS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_CONTACTO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_CONTACTO:
           let datoContactoDelete = action.contacto;
           let idsDelete = [];
           Object.values(action.contacto).map((contactoDelete) => {
               if (contactoDelete && contactoDelete.id)
                   idsDelete.push(contactoDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoContactoDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_CONTACTO:
                  let datoContactoDeleteCreate = action.contacto;
                  let idsDeleteCreate = [];
                  Object.values(action.contacto).map((contactoDelete) => {
                      if (contactoDelete && contactoDelete.id)
                          idsDeleteCreate.push(contactoDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoContactoDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_CONTACTO:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.contacto,
                error: null,
            });
        case REQUEST_DELETE_CONTACTO:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_CONTACTO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_CONTACTO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_CONTACTO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_CONTACTO:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_CONTACTO:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_CONTACTO:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const contactos = combineReducers({
    byId: contactosById,
    allIds: allContactos,
    update: update,
    create: create,
    totalContactos: totalContactos,
    delete: deleter,
    print: print
});

export default contactos;