import React, { useRef } from 'react'
import { GoogleMap, Marker, Polygon, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { useSelector } from "react-redux";
import { Typography, Box } from "@material-ui/core";
import ContainerDoble from "../../../customComponents/ContainerDoble";
import CampoTexto from "../../../customComponents/CampoTexto";
import { makeStyles } from "@material-ui/core/styles";
import Line from "../../../customComponents/Line";

const useStyles = makeStyles((theme) => ({
    mensaje: {
        display: "none"
    },
    containerMapa: {
        width: "50%",
        paddingRight: "30px",
        height: "400px",
        position: "relative",
        top: "-70px",
        "&::after": {
            content: '""',
            display: 'block',
            [theme.breakpoints.down(714)]: {
                display: 'none',
            },
            backgroundColor: "#003462",
            position: "absolute",
            height: "25px",
            width: "90%",
            transform: "skewX(-5deg) rotate(-5deg)",
            bottom: "-175px",
            left: "-6px",
            zIndex: 1,
        },
        "& .parent": {
            zIndex: 9,
            boxShadow: "8px -5px 30px #0000001c",
            position: "relative",
            transform: "skew(-5deg) rotate(-5deg)",
            overflow: "hidden",
            height: "140%",
            [theme.breakpoints.down(714)]: {
                // transform: "none",
                height: "100%",
                // borderRadius: "15px",
            },
        },
        "& .mapa": {
            top: "50%",
            width: "100%",
            height: "220%",
            position: "absolute",
            transform: "skew(5deg) rotate(5deg) translateY(-50%)",
        },
        [theme.breakpoints.down(714)]: {
            width: "100%",
            top: 0,
            padding: 0,
        },
    },
    containerComprobacionZona: {
        padding: "20px"
    },
    titulo: {
        marginBottom: "40px"
    },
    containerZonaEntrega: {
        [theme.breakpoints.down(714)]: {
            flexWrap: "wrap",
            flexDirection: "column-reverse"
        },
    }
}));

const containerStyle = {
    width: '100%',
    height: '100%',
};

const center = {
    lat: -33.002891, lng: -60.683821
};

const position = {
    lat: -33.002891, lng: -60.683821
}


function MapWithASearchBox(props) {
    const classes = useStyles();
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyANSH-395EpmTpki-222stF4V76Rp8KcZY",
        libraries: ["drawing", "places", "geometry"]
    })
    const msjHabilitado = useRef();
    const msjInhabilitado = useRef();
    const [map, setMap] = React.useState(null)
    const [searchBox, setSearchBox] = React.useState(null)
    const [direccion, setDireccion] = React.useState(null)
    const [markers, setMarkers] = React.useState([])

    const zonasEntrega = useSelector(state => state.zonasEntrega);

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onLoadSB = ref => setSearchBox(ref);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        const bounds = new window.google.maps.LatLngBounds();

        places.forEach(place => {
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport)
            } else {
                bounds.extend(place.geometry.location)
            }
        });
        const nextMarkers = places.map(place => ({
            position: place.geometry.location,
        }));

        let nextCenter = places[0].geometry.location;
        setMarkers(nextMarkers);
        setDireccion(places[0].formatted_address);


        let encontrado = false;

        let datos = [];
        if (places[0])
            datos = places[0].address_components;
        let codPostal = null;
        for (var i = 0; i < datos.length; i++) {
            for (var j = 0; j < datos[i].types.length; j++) {
                if (datos[i].types[j] === "postal_code") {
                    codPostal = datos[i].long_name.replace(/^\D+/g, '');
                    break;
                }
            }
            if (codPostal)
                break;
        }


        zonasEntrega.allIds.some((idZonaEntrega, i) => {
            let zonaEntrega = zonasEntrega.byId.zonasEntrega[idZonaEntrega];

            if (zonaEntrega.limitarPor === "region") {
                let arrayZona = [];
                zonaEntrega.cordenadas.split("\n").forEach((coordenadas) => {
                    let coord = coordenadas.split(",");
                    let punto = {};
                    punto.lat = parseFloat(coord[0]);
                    punto.lng = parseFloat(coord[1]);
                    arrayZona.push(punto);
                });
                if (zonaEntrega.cordenadas && zonaEntrega.estado === 1) {
                    var bermudaTriangle = new window.google.maps.Polygon({paths: arrayZona});
                    let r = window.google.maps.geometry.poly.containsLocation(nextCenter, bermudaTriangle);
                    if (r) {
                        msjHabilitado.current.style.display = "inline";
                        msjInhabilitado.current.style.display = "none";
                        if (places[0]) {
                            // this.props.saveCreateAnalitica(places[0].formatted_address, TipoAnalitica.VALIDARDIRECCIONENTREGA_ID);
                        }
                        encontrado = true;
                        return true;
                    }
                    return false;
                }
            } else if (zonaEntrega.limitarPor === "codPostal") {
                let esZona = zonaEntrega.codigosPostales.split(" ").some((codPostalZona) => {
                    return codPostalZona === codPostal;
                })

                if (esZona) {
                    encontrado = true;
                    return true;
                }
                return false;
            }
        });


        if (encontrado === false) {
            msjHabilitado.current.style.display = "none";
            msjInhabilitado.current.style.display = "inline";
            if (places[0]) {
                // this.props.saveCreateAnalitica(places[0].formatted_address, TipoAnalitica.NOVALIDARDIRECCIONENTREGA_ID);
            }
        } else {
            msjHabilitado.current.style.display = "inline";
            msjInhabilitado.current.style.display = "none";
        }


        map.fitBounds(bounds);
    };

    return isLoaded ? (
        <ContainerDoble className={classes.containerZonaEntrega}>
            <div className={classes.containerMapa}>
                <div className="parent">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={12}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        className={"mapa"}
                    >

                        {/*Marcador de JVA*/}
                        <Marker
                            position={position}
                            // icon={"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"}
                        />

                        {/*Poligonos de zonas de entrega*/}
                        {
                            zonasEntrega.allIds.map((idZonaEntrega, i) => {
                                let zonaEntrega = zonasEntrega.byId.zonasEntrega[idZonaEntrega];
                                if (zonaEntrega.limitarPor === "region" && zonaEntrega.estado === 1) {
                                    let arrayZona = [];
                                    zonaEntrega.cordenadas.split("\n").forEach((coordenadas) => {
                                        let coord = coordenadas.split(",");
                                        let punto = {};
                                        punto.lat = parseFloat(coord[0]);
                                        punto.lng = parseFloat(coord[1]);
                                        arrayZona.push(punto);
                                    });
                                    return (
                                        <Polygon key={i} path={arrayZona}
                                                 options={{
                                                     fillColor: 'rgb(102, 183, 29, 0.4)',
                                                     strokeColor: '#00b722',
                                                     strokeWeight: 1
                                                 }}/>
                                    )
                                }
                            })
                        }
                        {/*Marcadores de busqueda*/}
                        {
                            markers.map((marker) => {
                                return (
                                    <Marker
                                        position={marker.position}
                                    />
                                )
                            })
                        }
                    </GoogleMap>
                </div>
            </div>
            <div className={classes.containerComprobacionZona}>
                <Box component={"div"} className={classes.titulo}>
                    <Typography variant={"h1"} align={"left"} style={{color: "#003462"}}>
                        Zonas de entrega
                    </Typography>
                    <Line width={"70px"} height={"16px"} align={"left"} borderBottomColor={"#59c4c6"}
                          borderBottomWidth={"3px"}/>
                </Box>
                <Typography variant={"body1"} style={{color: "#003462"}}>
                    Ingresá tu dirección para verificar si estas dentro de nuestra zona de entrega.
                </Typography>
                <StandaloneSearchBox
                    onLoad={onLoadSB}
                    onPlacesChanged={
                        onPlacesChanged
                    }
                >
                    <CampoTexto id={"direccion"} nombre="Dirección (Calle Num. Ciudad País)"
                                type="text" color="#003462" borderColorFocus="#59c4c6"
                                name="direccion"
                                value={direccion}
                                handleChange={(e) => setDireccion(e.target.value)}
                    />
                </StandaloneSearchBox>
                <Typography variant={"body1"} ref={msjHabilitado} className={classes.mensaje}
                            style={{color: "green"}}>
                    ¡Llegamos a tu dirección!
                </Typography>
                <Typography variant={"body1"} ref={msjInhabilitado} className={classes.mensaje}
                            style={{color: "orangered", textDecoration: "none"}}
                            component={"a"} href={"https://wa.me/543412117923"}
                            target={"_blank"}>
                    Todavía no llegamos a tu zona. Consultanos por WhatsApp
                </Typography>
                <Typography variant={"body1"} style={{color: "#003462", marginTop: "36px"}}>
                    <b>Horarios de atención al público</b>
                </Typography>
                <Typography variant={"body1"} style={{color: "#003462", marginTop: "8px"}}>
                    Lunes a Viernes de 8:00 a 16:00 hs. <br/>
                    Sábados de 9 a 14 hs.
                </Typography>
            </div>
        </ContainerDoble>
    ) : <></>
}

export default React.memo(MapWithASearchBox)