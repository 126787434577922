import React from 'react';
import * as PropTypes from "prop-types";
//Usar este en vez de Container con maxwidth porque le mete muchos margenes


export default function Line(props) {
    return (
        <div style={{
            borderBottomColor: props.borderBottomColor,
            borderBottomWidth: props.borderBottomWidth,
            borderBottomStyle: "solid",
            width: props.width,
            height: props.height ? props.height : "10px",
            margin: props.align === "center" ? "auto" : props.align === "right" ? "0 0 0 auto" : "inherit",
        }}>

        </div>
    )
}

Line.propTypes = {
    borderBottomColor: PropTypes.string,
    borderBottomWidth: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    align: PropTypes.string,
};
