import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import { makeStyles } from "@material-ui/core/styles";

//Components
import ContainerLimit from "../ContainerLimit";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    carrouselCategoria: {
        width: "100vw",
        maskImage: "linear-gradient(to right, transparent, white 55px, white 93%, transparent)",
        "& .swiper-container.swiper-container-initialized.swiper-container-horizontal.swiper-container-free-mode": {
            maskImage: "linear-gradient(to right, transparent, white 10px, white 98%, transparent)",
            alignSelf: "center",
            height: "100%",
            margin: "0",
            width: "100%",
            "& .swiper-wrapper": {
                justifyContent: "space-between",
                alignItems: "center",
            }
        },

        "& .carrousel-buttons": {
            maxWidth: "1290px",
            position: "relative",
            margin: "0 auto",
            top: "-55px",
            display: "flex !important",
            [theme.breakpoints.down(1276)]: {
                display: "none !important",
            },
        },

        "& .swiper-button-prev.swiper-button-disabled, & .swiper-button-next.swiper-button-disabled": {
            opacity: "0 !important",
        },

        "& .carrousel-buttons .swiper-button-prev, & .swiper-container-rtl .swiper-button-next, & .carrousel-buttons .swiper-button-next, & .carrousel-buttons .swiper-container-rtl .swiper-button-prev": {
            border: "none",
            backgroundColor: "transparent",
            boxShadow: "none",
            width: "calc(44px / 44 * 20)",
        },

        "& .carrousel-buttons .swiper-button-next:after, & .carrousel-buttons .swiper-container-rtl .swiper-button-prev:after": {
            fontSize: "21px",
            color: theme.palette.primary["main"],
        },

        "& .carrousel-buttons .swiper-button-prev:after, & .carrousel-buttons .swiper-container-rtl .swiper-button-next:after": {
            fontSize: "21px",
            color: theme.palette.primary["main"],
        },

    }

}));
export default function CarrouselCategorias(props) {
    const [swiper, updateSwiper] = useState(null);
    const [isBegginig, updateIsBeggining] = useState(null);
    const [isEnd, updateIsEnd] = useState(null);
    const classes = useStyles();

    // Swiper instance
    useEffect(
        () => {
            if (swiper !== null) {
                updateIsBeggining(swiper.isBeginning);
                updateIsEnd(swiper.isEnd);
                swiper.on("slideChange", () => {
                    updateIsBeggining(swiper.isBeginning);
                    updateIsEnd(swiper.isEnd);
                });
            }
        },
        [swiper && (swiper.isEnd || swiper.isBeggining)]
    );

    const params = {
        mousewheel: {
            invert: false,
            forceToAxis: true
        },
        speed: 200,
        freeMode: true,
        autoWidth: true,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        normalizeSlideIndex: false,
        getSwiper: updateSwiper, // Get swiper instance callback
    };

    // Manipulate swiper from outside
    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <Box component={"div"} className={classes.carrouselCategoria}>
            <ContainerLimit style={{display: "flex", justifyContent: "center"}}>
                <Swiper {...params} shouldSwiperUpdate updateOnWindowResize>
                    {props.children}
                </Swiper>
            </ContainerLimit>
            <div className="carrousel-buttons">
                <div className={`swiper-button-prev ${isBegginig && "swiper-button-disabled"}`} onClick={goPrev}/>
                <div className={`swiper-button-next ${isEnd && "swiper-button-disabled"}`} onClick={goNext}/>
            </div>
        </Box>
    );
}