import React from "react";
import {Box, Chip, Tooltip, Typography} from "@material-ui/core";
import NumberFormated from "../../../customComponents/NumberFormated";
import * as PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import Ribbon from "../../../customComponents/Ribbon";
import RedeemIcon from "@material-ui/icons/Redeem";

function areEqual(prevProps, nextProps) {
    /*
    retorna true si al pasar los nextProps a renderizar retorna
    el mismo resultado que al pasar los prevProps a renderizar,
    de otro modo retorna false
    */
    return true;
}

const useStyles = makeStyles((theme) => ({
    nombreProducto: {
        display: "-webkit-box",
        lineClamp: 3,
        boxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: ".9rem",
        marginTop: "5px",
    },
    grow: {
        flexGrow: 1
    },
    containerPrecio: {
        display: "block",
        flexDirection: "column"
    },
    containerPromocion: {
        margin: theme.spacing(0, 0, 1, 0),
        [theme.breakpoints.down(975)]: {
            margin: "0 0 2px 0",
        },
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        "& p": {
            lineHeight: "initial",
        },
    },
    descripcionPromo: {
        display: "-webkit-box",
        lineClamp: 3,
        boxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "initial"
    },
    chip: {
        color: green[500],
    },
    leyendaPrecioAnterior: {
        marginLeft: "6px",
        color: theme.palette.text.secondary
    },
}));

export default React.memo(function InformacionProducto(props) {
    const classes = useStyles();
    const {
        precioAnterior,
        leyendaPrecioAnterior,
        precioActual,
        chip,
        nomProducto,
        presentacion,
        ribbon,
        desPromocion,
        simbolo
    } = props;

    return <Box component={"div"}>
        {
            ribbon && ribbon === "Regalo" &&
            <Ribbon><RedeemIcon/>&nbsp;Regalo</Ribbon>
        }
        {
            ribbon && ribbon !== "Regalo" &&
            <Ribbon>{ribbon ? ribbon : ""}</Ribbon>
        }
        <Typography variant={"body2"} component={"p"} className={classes.nombreProducto}>
            <b>{nomProducto ? nomProducto : ""}</b>
        </Typography>
        <Typography variant={"caption"} component={"p"} color={"textSecondary"}
                    style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
            {presentacion ? presentacion : ""}
        </Typography>
        <Box component={"div"} className={classes.containerPrecio}>
            <Box component={"div"} display={"flex"} alignItems={"left"}>
                <NumberFormated enteroSize={".75rem"} decimalSize={".45rem"} simbolo={simbolo ? simbolo : "$"}
                                color={"textSecondary"} tachado
                                responsive={{
                                    breakpoints: "975px",
                                    enteroSize: ".65rem",
                                    decimalSize: "0.3rem",
                                }}>
                    {precioAnterior}
                </NumberFormated>
                <Typography variant={"body2"} component={"p"} className={classes.leyendaPrecioAnterior}>
                    {leyendaPrecioAnterior && precioAnterior ? leyendaPrecioAnterior : ""}
                </Typography>
            </Box>
            <Box component={"div"} display={"flex"} alignItems={"center"}>
                <NumberFormated enteroSize={"1.5rem"} decimalSize={"1.1rem"} simbolo={simbolo ? simbolo : "$"}
                                marginRight={precioActual === 0 ? 0 : "8px"}
                                responsive={{
                                    breakpoints: "975px",
                                    enteroSize: "1.2rem",
                                    decimalSize: "0.7rem",
                                }}>
                    {precioActual}
                </NumberFormated>
                {
                    chip &&
                    <Chip
                        icon={chip === "Regalo" ? <RedeemIcon/> : ""}
                        label={chip}
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}
                        style={{margin: "4px 4px 4px 0"}}/>
                }
            </Box>
        </Box>
        <Box component={"div"} className={classes.containerPromocion}>
            <Tooltip title={desPromocion ? desPromocion : ""}>
                <Typography variant={"caption"} color={"textSecondary"}
                            className={classes.descripcionPromo}>
                    {desPromocion ? desPromocion : ""}
                </Typography>
            </Tooltip>
        </Box>
        <div className={classes.grow}/>
    </Box>;
}, areEqual);

// InformacionProducto.propTypes = {
//     classes: PropTypes.any,
//     precioAnterior: PropTypes.any,
//     leyendaPrecioAnterior: PropTypes.any,
//     precioActual: PropTypes.any,
//     chip: PropTypes.number,
//     producto: PropTypes.any
// };