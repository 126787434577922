//api
import productosOrigenPromocionApi from "../api/productosOrigenPromocionApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProductosOrigenPromocion";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../utils/auth";

//PRODUCTOSORIGENPROMOCION
export const REQUEST_PRODUCTOSORIGENPROMOCION = 'REQUEST_PRODUCTOSORIGENPROMOCION';
export const RECEIVE_PRODUCTOSORIGENPROMOCION = 'RECEIVE_PRODUCTOSORIGENPROMOCION';
export const INVALIDATE_PRODUCTOSORIGENPROMOCION = 'INVALIDATE_PRODUCTOSORIGENPROMOCION';
export const ERROR_PRODUCTOSORIGENPROMOCION = "ERROR_PRODUCTOSORIGENPROMOCION";
export const RESET_PRODUCTOSORIGENPROMOCION = "RESET_PRODUCTOSORIGENPROMOCION";


export function invalidateProductosOrigenPromocion() {
    return {
        type: INVALIDATE_PRODUCTOSORIGENPROMOCION
    }
}

function requestProductosOrigenPromocion() {
    return {
        type: REQUEST_PRODUCTOSORIGENPROMOCION,
    }
}

function receiveProductosOrigenPromocion(json) {
    return {
        type: RECEIVE_PRODUCTOSORIGENPROMOCION,
    productosOrigenPromocion: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProductosOrigenPromocion(error) {
    return {
        type: ERROR_PRODUCTOSORIGENPROMOCION,
        error: error,
    }
}

export function resetProductosOrigenPromocion() {
    return {
        type: RESET_PRODUCTOSORIGENPROMOCION
    }
}

export function fetchProductosOrigenPromocion(filtros) {
    return dispatch => {
        dispatch(requestProductosOrigenPromocion());
        return productosOrigenPromocionApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductosOrigenPromocion(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProductosOrigenPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductosOrigenPromocion(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProductosOrigenPromocion(state) {
    const productosOrigenPromocion = state.productosOrigenPromocion.byId;
    if (!productosOrigenPromocion) {
        return true
    } else if (productosOrigenPromocion.isFetching) {
        return false
    } else {
        return productosOrigenPromocion.didInvalidate;
    }
}

export function fetchProductosOrigenPromocionIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProductosOrigenPromocion(getState())) {
            return dispatch(fetchProductosOrigenPromocion(filtros))
        }
    }
}


//MODEL
export const REQUEST_PRODUCTOORIGENPROMOCION = 'REQUEST_PRODUCTOORIGENPROMOCION';
export const RECEIVE_PRODUCTOORIGENPROMOCION = 'RECEIVE_PRODUCTOORIGENPROMOCION';
export const INVALIDATE_PRODUCTOORIGENPROMOCION = 'INVALIDATE_PRODUCTOORIGENPROMOCION';
export const ERROR_PRODUCTOORIGENPROMOCION = "ERROR_PRODUCTOORIGENPROMOCION";
export const RESET_PRODUCTOORIGENPROMOCION = "RESET_PRODUCTOORIGENPROMOCION";


export function invalidateProductoOrigenPromocion() {
    return {
        type: INVALIDATE_PRODUCTOORIGENPROMOCION
    }
}

function requestProductoOrigenPromocion() {
    return {
        type: REQUEST_PRODUCTOORIGENPROMOCION,
    }
}

export function receiveProductoOrigenPromocion(json) {
    return {
        type: RECEIVE_PRODUCTOORIGENPROMOCION,
    productoOrigenPromocion: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProductoOrigenPromocion(error) {
    return {
        type: ERROR_PRODUCTOORIGENPROMOCION,
        error: error,
    }
}

export function fetchProductoOrigenPromocion(idProductoOrigenPromocion) {
    return dispatch => {
        dispatch(requestProductoOrigenPromocion());
        return productosOrigenPromocionApi.getOne(idProductoOrigenPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductoOrigenPromocion(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProductoOrigenPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductoOrigenPromocion(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_PRODUCTOORIGENPROMOCION = 'UPDATE_PRODUCTOORIGENPROMOCION';
export const REQUEST_UPDATE_PRODUCTOORIGENPROMOCION = "REQUEST_UPDATE_PRODUCTOORIGENPROMOCION";
export const SUCCESS_UPDATE_PRODUCTOORIGENPROMOCION = "SUCCESS_UPDATE_PRODUCTOORIGENPROMOCION";
export const ERROR_UPDATE_PRODUCTOORIGENPROMOCION = "ERROR_UPDATE_PRODUCTOORIGENPROMOCION";
export const RESET_UPDATE_PRODUCTOORIGENPROMOCION = "RESET_UPDATE_PRODUCTOORIGENPROMOCION";
export const DELETE_UPDATE_PRODUCTOORIGENPROMOCION = "DELETE_UPDATE_PRODUCTOORIGENPROMOCION";

function requestUpdateProductoOrigenPromocion() {
    return {
        type: REQUEST_UPDATE_PRODUCTOORIGENPROMOCION,
    }
}

function receiveUpdateProductoOrigenPromocion(productoOrigenPromocion) {
    return {
        type: SUCCESS_UPDATE_PRODUCTOORIGENPROMOCION,
        receivedAt: Date.now(),
        productoOrigenPromocion: normalizeDato(productoOrigenPromocion)
    }
}

function errorUpdateProductoOrigenPromocion(error) {
    return {
        type: ERROR_UPDATE_PRODUCTOORIGENPROMOCION,
        error: error,
    }
}

export function resetUpdateProductoOrigenPromocion() {
    return {
        type: RESET_UPDATE_PRODUCTOORIGENPROMOCION,
    }
}

export function updateProductoOrigenPromocion(productoOrigenPromocion) {
    return {
        type: UPDATE_PRODUCTOORIGENPROMOCION,
        productoOrigenPromocion
    }
}

export function saveUpdateProductoOrigenPromocion() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductoOrigenPromocion());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productoOrigenPromocion = denormalizeDato(getState().productosOrigenPromocion.update.activo, store);

        return productosOrigenPromocionApi.saveUpdate(productoOrigenPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductoOrigenPromocion(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProductoOrigenPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateProductosOrigenPromocion(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateProductosOrigenPromocion(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProductoOrigenPromocion(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateProductoOrigenPromocion(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateProductoOrigenPromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateProductoOrigenPromocion(productoOrigenPromocion) {
    return {
        type: DELETE_UPDATE_PRODUCTOORIGENPROMOCION,
        productoOrigenPromocion
    }
}

//UPDATE PRODUCTOSORIGENPROMOCION
export const REQUEST_UPDATE_PRODUCTOSORIGENPROMOCION = "REQUEST_UPDATE_PRODUCTOSORIGENPROMOCION";
export const SUCCESS_UPDATE_PRODUCTOSORIGENPROMOCION = "SUCCESS_UPDATE_PRODUCTOSORIGENPROMOCION";
export const ERROR_UPDATE_PRODUCTOSORIGENPROMOCION = "ERROR_UPDATE_PRODUCTOSORIGENPROMOCION";
export const RESET_UPDATE_PRODUCTOSORIGENPROMOCION = "RESET_UPDATE_PRODUCTOSORIGENPROMOCION";

function requestUpdateProductosOrigenPromocion() {
    return {
        type: REQUEST_UPDATE_PRODUCTOSORIGENPROMOCION,
}
}

function receiveUpdateProductosOrigenPromocion(productosOrigenPromocion) {
    return {
        type: SUCCESS_UPDATE_PRODUCTOSORIGENPROMOCION,
    receivedAt: Date.now(),
        productosOrigenPromocion: normalizeDatos(productosOrigenPromocion)
}
}

function errorUpdateProductosOrigenPromocion(error) {
    return {
        type: ERROR_UPDATE_PRODUCTOSORIGENPROMOCION,
    error: error,
}
}

export function resetUpdateProductosOrigenPromocion() {
    return {
        type: RESET_UPDATE_PRODUCTOSORIGENPROMOCION,
}
}

export function saveUpdateProductosOrigenPromocion() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProductosOrigenPromocion());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productosOrigenPromocion = getState().productosOrigenPromocion.update.activos.map((idProductoOrigenPromocion) => {
            return denormalizeDato(getState().productosOrigenPromocion.update.activo[idProductoOrigenPromocion], store);
        });

        return productosOrigenPromocionApi.saveUpdateProductosOrigenPromocion(productosOrigenPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProductosOrigenPromocion(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProductosOrigenPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateProductosOrigenPromocion(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateProductosOrigenPromocion(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateProductosOrigenPromocion(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateProductosOrigenPromocion(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA PRODUCTOORIGENPROMOCION
export const CREATE_PRODUCTOORIGENPROMOCION = 'CREATE_PRODUCTOORIGENPROMOCION';
export const REQUEST_CREATE_PRODUCTOORIGENPROMOCION = "REQUEST_CREATE_PRODUCTOORIGENPROMOCION";
export const SUCCESS_CREATE_PRODUCTOORIGENPROMOCION = "SUCCESS_CREATE_PRODUCTOORIGENPROMOCION";
export const ERROR_CREATE_PRODUCTOORIGENPROMOCION = "ERROR_CREATE_PRODUCTOORIGENPROMOCION";
export const RESET_CREATE_PRODUCTOORIGENPROMOCION = "RESET_CREATE_PRODUCTOORIGENPROMOCION";
export const DELETE_CREATE_PRODUCTOORIGENPROMOCION = "DELETE_CREATE_PRODUCTOORIGENPROMOCION";


//ALTA PRODUCTOORIGENPROMOCION
function requestCreateProductoOrigenPromocion() {
    return {
        type: REQUEST_CREATE_PRODUCTOORIGENPROMOCION,
    }
}

function receiveCreateProductoOrigenPromocion(productoOrigenPromocion) {
    return {
        type: SUCCESS_CREATE_PRODUCTOORIGENPROMOCION,
        receivedAt: Date.now(),
        productoOrigenPromocion: normalizeDato(productoOrigenPromocion)
    }
}

export function errorCreateProductoOrigenPromocion(error) {
    return {
        type: ERROR_CREATE_PRODUCTOORIGENPROMOCION,
        error: error,
    }
}

export function resetCreateProductoOrigenPromocion() {
    return {
        type: RESET_CREATE_PRODUCTOORIGENPROMOCION
    }
}

export function createProductoOrigenPromocion(productoOrigenPromocion) {
    return {
        type: CREATE_PRODUCTOORIGENPROMOCION,
        productoOrigenPromocion
    }
}

export function deleteCreateProductoOrigenPromocion(productoOrigenPromocion) {
    return {
        type: DELETE_CREATE_PRODUCTOORIGENPROMOCION,
        productoOrigenPromocion
    }
}

export function saveCreateProductoOrigenPromocion() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductoOrigenPromocion());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productoOrigenPromocion = denormalizeDato(getState().productosOrigenPromocion.create.nuevo, store);

        return productosOrigenPromocionApi.saveCreate(productoOrigenPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductoOrigenPromocion(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProductoOrigenPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateProductosOrigenPromocion(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateProductosOrigenPromocion(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateProductoOrigenPromocion(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateProductoOrigenPromocion(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateProductoOrigenPromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE PRODUCTOSORIGENPROMOCION
export const REQUEST_CREATE_PRODUCTOSORIGENPROMOCION = "REQUEST_CREATE_PRODUCTOSORIGENPROMOCION";
export const SUCCESS_CREATE_PRODUCTOSORIGENPROMOCION = "SUCCESS_CREATE_PRODUCTOSORIGENPROMOCION";
export const ERROR_CREATE_PRODUCTOSORIGENPROMOCION = "ERROR_CREATE_PRODUCTOSORIGENPROMOCION";
export const RESET_CREATE_PRODUCTOSORIGENPROMOCION = "RESET_CREATE_PRODUCTOSORIGENPROMOCION";

function requestCreateProductosOrigenPromocion() {
    return {
        type: REQUEST_CREATE_PRODUCTOSORIGENPROMOCION,
}
}

function receiveCreateProductosOrigenPromocion(productosOrigenPromocion) {
    return {
        type: SUCCESS_CREATE_PRODUCTOSORIGENPROMOCION,
    receivedAt: Date.now(),
        productosOrigenPromocion: normalizeDatos(productosOrigenPromocion)
}
}

function errorCreateProductosOrigenPromocion(error) {
    return {
        type: ERROR_CREATE_PRODUCTOSORIGENPROMOCION,
    error: error,
}
}

export function resetCreateProductosOrigenPromocion() {
    return {
        type: RESET_CREATE_PRODUCTOSORIGENPROMOCION,
}
}

export function saveCreateProductosOrigenPromocion() {
    return (dispatch, getState) => {
        dispatch(requestCreateProductosOrigenPromocion());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let productosOrigenPromocion = getState().productosOrigenPromocion.create.nuevos.map((idProductoOrigenPromocion) => {
            return denormalizeDato(getState().productosOrigenPromocion.create.nuevo[idProductoOrigenPromocion], store);
        });

        return productosOrigenPromocionApi.saveCreateProductosOrigenPromocion(productosOrigenPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProductosOrigenPromocion(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateProductosOrigenPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateProductosOrigenPromocion(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateProductosOrigenPromocion(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateProductosOrigenPromocion(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateProductosOrigenPromocion(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE PRODUCTOORIGENPROMOCION
export const DELETE_PRODUCTOORIGENPROMOCION = 'DELETE_PRODUCTOORIGENPROMOCION';
export const REQUEST_DELETE_PRODUCTOORIGENPROMOCION = "REQUEST_DELETE_PRODUCTOORIGENPROMOCION";
export const SUCCESS_DELETE_PRODUCTOORIGENPROMOCION = "SUCCESS_DELETE_PRODUCTOORIGENPROMOCION";
export const ERROR_DELETE_PRODUCTOORIGENPROMOCION = "ERROR_DELETE_PRODUCTOORIGENPROMOCION";
export const RESET_DELETE_PRODUCTOORIGENPROMOCION = "RESET_DELETE_PRODUCTOORIGENPROMOCION";

function requestDeleteProductoOrigenPromocion() {
    return {
        type: REQUEST_DELETE_PRODUCTOORIGENPROMOCION,
    }
}

function receiveDeleteProductoOrigenPromocion(productoOrigenPromocion) {
    return {
        type: SUCCESS_DELETE_PRODUCTOORIGENPROMOCION,
        receivedAt: Date.now(),
        productoOrigenPromocion: normalizeDato(productoOrigenPromocion)
    }
}

function errorDeleteProductoOrigenPromocion(error) {
    return {
        type: ERROR_DELETE_PRODUCTOORIGENPROMOCION,
        error: error,
    }
}

export function resetDeleteProductoOrigenPromocion(error) {
    return {
        type: RESET_DELETE_PRODUCTOORIGENPROMOCION,
        error: error,
    }
}

export function deleteProductoOrigenPromocion(productoOrigenPromocion) {
    return {
        type: DELETE_PRODUCTOORIGENPROMOCION,
        productoOrigenPromocion
    }
}

export function saveDeleteProductoOrigenPromocion(productoOrigenPromocion) {
    return dispatch => {
        dispatch(requestDeleteProductoOrigenPromocion());
        return productosOrigenPromocionApi.saveDelete(productoOrigenPromocion)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProductoOrigenPromocion());
                            dispatch(receiveDeleteProductoOrigenPromocion(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProductoOrigenPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteProductoOrigenPromocion(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteProductoOrigenPromocion(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProductoOrigenPromocion(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteProductoOrigenPromocion(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT PRODUCTOORIGENPROMOCION
export const REQUEST_PRINT_PRODUCTOORIGENPROMOCION = "REQUEST_PRINT_PRODUCTOORIGENPROMOCION";
export const SUCCESS_PRINT_PRODUCTOORIGENPROMOCION = "SUCCESS_PRINT_PRODUCTOORIGENPROMOCION";
export const ERROR_PRINT_PRODUCTOORIGENPROMOCION = "ERROR_PRINT_PRODUCTOORIGENPROMOCION";
export const RESET_PRINT_PRODUCTOORIGENPROMOCION = "RESET_PRINT_PRODUCTOORIGENPROMOCION";

function requestPrintProductoOrigenPromocion() {
    return {
        type: REQUEST_PRINT_PRODUCTOORIGENPROMOCION,
    }
}

function receivePrintProductoOrigenPromocion(turnos) {
    return {
        type: SUCCESS_PRINT_PRODUCTOORIGENPROMOCION,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintProductoOrigenPromocion(error) {
    return {
        type: ERROR_PRINT_PRODUCTOORIGENPROMOCION,
        error: error,
    }
}

export function resetPrintProductoOrigenPromocion() {
    return {
        type: RESET_PRINT_PRODUCTOORIGENPROMOCION,
    }
}

export function printProductoOrigenPromocion(idProductoOrigenPromocion) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintProductoOrigenPromocion());
        return productosOrigenPromocionApi.printProductoOrigenPromocion(idProductoOrigenPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintProductoOrigenPromocion(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintProductoOrigenPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintProductoOrigenPromocion(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintProductoOrigenPromocion(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintProductoOrigenPromocion(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}