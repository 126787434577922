import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Actions

//Components
import { Box, Typography } from "@material-ui/core";

//Icons
import bgQuienesSomos from "../../assets/img/quinesSomos/bgQuienesSomos.png";
import ContainerLimit from "../../customComponents/ContainerLimit";

//Img
import footerQuienesSomos from "../../assets/img/quinesSomos/footerQuienesSomos.png";
import Line from "../../customComponents/Line";


const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `url(${bgQuienesSomos})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "100%",
        backgroundSize: "contain",
        paddingBottom: "240px",
        [theme.breakpoints.down(714)]: {
            paddingBottom: "150px"
        }
    },
    bodyColumn: {
        maxWidth: "440px",
        margin: theme.spacing(0, 2),
        lineHeight: "1.9"
    },
    containerTexto: {
        display: "flex",
        justifyContent: "center",
        color: "#003462",
        [theme.breakpoints.down(714)]: {
            flexWrap: "wrap"
        }
    },
    containerTitulo: {
        paddingTop: "30px",
        marginBottom: "80px",
        [theme.breakpoints.down(714)]: {
            paddingTop: "50px",
            marginBottom: "40px",
        }
    },
    footerQuienesSomos: {
        backgroundImage: `url(${footerQuienesSomos})`,
        backgroundSize: "cover",
        height: "130px",
        [theme.breakpoints.down(714)]: {
            height: "100px",
        },
        width: "100%",
        zIndex: "9",
        position: "relative",
        marginTop: "-100px",
        backgroundRepeat: "no-repeat",
    }
}));


export default function QuienesSomos() {
    const classes = useStyles();

    return (
        <>
            <Box component={"div"} className={classes.container} id={"quienesSomos"}>
                <ContainerLimit>
                    <Box component={"div"} className={classes.containerTitulo}>
                        <Typography variant={"h1"} align={"center"} style={{color: "#003462"}}>
                            ¿Quiénes somos?
                        </Typography>
                        <Line width={"70px"} height={"20px"} align={"center"} borderBottomColor={"#59c4c6"}
                              borderBottomWidth={"3px"}/>
                    </Box>
                    <Box component={"div"} className={classes.containerTexto}>
                        <Typography variant={"body1"} className={classes.bodyColumn}>
                            Somos una empresa familiar con más de 40 años de experiencia en
                            distribución mayorista y minorista de alimentos y bebidas.
                            JVA fue fundada por Julio A. Coloschi el 05/09/1978. Julio comenzó con
                            un solo camión, repartiendo el mismo la mercadería a sus clientes.
                            Con perseverancia y trabajo duro, con gran ayuda de su mujer, fue incorporando capital
                            humano a las labores diarias pudiendo así incrementar las ventas,
                            siempre inculcando los valores de la empresa, pilares fundamentales:
                            responsabilidad y seriedad
                        </Typography>
                        <Typography variant={"body1"} className={classes.bodyColumn}>
                            Cuando sus hijos crecieron, se incorporaron a esta empresa familiar
                            para continuar con la impronta de Julio y seguir trabajando sobre las
                            bases y con la misma misión y valores:&nbsp;<b>atender a los clientes con un
                            servicio de alta calidad proveyendo mercadería a los mejores precios</b>.
                            Con una fuerte visión de crecimiento,&nbsp;<b>JVA</b>&nbsp;fue creciendo
                            paulatinamente hasta convertirse en lo que se conoce actualmente.
                        </Typography>
                    </Box>
                </ContainerLimit>
            </Box>
            <Box component={"div"} className={classes.footerQuienesSomos}/>
        </>
    );
}
