import jwt_decode from 'jwt-decode';
import {isNil} from "lodash";

var calculoPreciosProducto = {
    calcularPreciosProducto(producto, moneda, productoOrigenPromocion) {
        let precioActual = null;
        let precioAnterior = null;
        let leyendaPrecioAnterior = null;
        let porcentaje = null;
        let precioProductoMoneda = {valor: producto ? producto.precio : null};
        if (moneda && moneda.id)
            precioProductoMoneda = producto.precios_monedas && producto.precios_monedas.find((precio) => {
                return precio.idMoneda === moneda.id
            })


        if (!isNil(productoOrigenPromocion) && !isNil(productoOrigenPromocion.precioPromocional)) {
            let precioPromocionalMonedaProducto = {valor: productoOrigenPromocion.precioPromocional};
            if (moneda && moneda.id)
                precioPromocionalMonedaProducto = productoOrigenPromocion.preciosMonedasPromocional.find((precio) => {
                    return precio.idMoneda === moneda.id
                })
            precioActual = precioPromocionalMonedaProducto ? precioPromocionalMonedaProducto.valor : null;
            precioAnterior = precioProductoMoneda ? precioProductoMoneda.valor : null;
            leyendaPrecioAnterior = "Por unidad";
        } else {
            precioActual = precioActual = precioProductoMoneda ? precioProductoMoneda.valor : null;
            if (producto && producto.precioMaximo) {
                precioAnterior = producto.precioMaximo;
                leyendaPrecioAnterior = "Sugerido";
            }
            if (producto && producto.precioAnterior) {
                precioAnterior = producto.precioAnterior;
                leyendaPrecioAnterior = "En el super";
            }
        }
        porcentaje = precioAnterior && precioAnterior !== 0 ? Math.round((1 - precioActual / precioAnterior) * 100) : null;
        return {precioActual, precioAnterior, leyendaPrecioAnterior, porcentaje,};
    },
    calcularPreciosCombo(productos, moneda, productosOrigenPromocion, promocion) {
        let precioActual = null;
        let precioAnterior = null;
        let leyendaPrecioAnterior = null;
        let porcentaje = null;

        let total = productos.reduce((total, producto) => {
            let precioProductoMoneda = {valor: producto ? producto.precio : null};
            if (moneda && moneda.id)
                precioProductoMoneda = producto.precios_monedas && producto.precios_monedas.find((precio) => {
                    return precio.idMoneda === moneda.id
                })
            let cantidad = 0;
            productosOrigenPromocion.some((productoOrigenPromocion) => {
                if (producto.id === productoOrigenPromocion.idProducto) {
                    cantidad = productoOrigenPromocion.cantidad;
                    return true;
                } else
                    return false;
            });
            return total + precioProductoMoneda.valor * cantidad;
        }, 0);
        precioAnterior = total && total !== 0 ? total.toFixed(2) : null;
        leyendaPrecioAnterior = "Antes";

        let precioPromocionalMonedaCombo = {valor: promocion.precioPromocional};
        if (moneda && moneda.id && promocion.preciosMonedasPromocional)
            precioPromocionalMonedaCombo = promocion.preciosMonedasPromocional.find((precio) => {
                return precio.idMoneda === moneda.id
            })
        precioActual = precioPromocionalMonedaCombo ? precioPromocionalMonedaCombo.valor : null;
        porcentaje = precioAnterior && precioAnterior !== 0 ? Math.round((1 - precioActual / precioAnterior) * 100) : null;

        return {precioActual, precioAnterior, leyendaPrecioAnterior, porcentaje,};
    }
}

export default calculoPreciosProducto;