import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import clsx from "clsx";

//Components
import {Box, Button, Divider, Drawer, IconButton, Typography,} from '@material-ui/core';
import {blue, green} from "@material-ui/core/colors";
import MiniMonedaTotal from "../LayoutLanding/NavSup/Auxiliares/MiniMonedaTotal";
import ItemCarrito from "./ItemCarrito";

//Icons
import {ChevronRight as ChevronRightIcon, DoneOutlined, KeyboardArrowDown as ArrowDown} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
    },
    appBarShift: {
        width: `calc(100% - ${props => props.drawerWidth})`,
        marginRight: props => props.drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: props => props.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        zIndex: theme.zIndex.speedDial,
        width: props => props.drawerWidth,
        paddingTop: theme.spacing(12),
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(10),
        },
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginRight: props => -props.drawerWidth,
    },
    contentShift: {
        marginRight: 0,
    },
    grow: {
        flexGrow: 1,
    },
    containerFooter: {
        position: "absolute",
        width: "100%",
        bottom: 0,
        minHeight: "169px"
    },
    wrapperDetalleTotal: {
        padding: theme.spacing(2, 4),
    },
    containerTotal: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: theme.spacing(1, 0),
    },
    btnContinuarCompra: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    btnLocalidad: {
        "& svg": {
            opacity: "0.3",
            fontSize: "20px",
        },
        "& .txtLocalidad": {
            maxWidth: "180px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textTransform: "none",
        },
    },
    containerItemsCarrito: {
        height: "calc(100% - 244px)",
        overflowY: "auto",
        paddingRight:theme.spacing(1),
        paddingLeft:theme.spacing(1),
        backgroundColor: theme.palette.background.default,
    }
}));

export default function Carrito(props) {
    const classes = useStyles(props);

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={props.openDrawerCarrito}
            classes={{
                paper: clsx(classes.drawerPaper, "MuiPaper-elevation6"),
            }}
        >
            <Box component={"div"} className={classes.drawerHeader}>
                <IconButton onClick={props.handleCarritoDrawerClose} size={"small"}>
                    <ChevronRightIcon/>
                </IconButton>
                &nbsp;
                <Typography variant={"button"} component={"p"}>MI CARRITO</Typography>
            </Box>
            <Divider/>
            <Box component={"div"} className={classes.containerItemsCarrito}>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
                <ItemCarrito idDetallePedido={1} />
                <ItemCarrito idDetallePedido={1}/>
            </Box>
            <Box component={"div"} className={clsx(classes.containerFooter, "MuiPaper-elevation8")} elevation={10}>
                <Divider/>
                <Box component={"div"} className={classes.wrapperDetalleTotal}>
                    <Box component={"div"} className={classes.containerTotal}>
                        <Button component={"a"}
                                className={classes.btnLocalidad}
                                color={"inherit"}
                                endIcon={<ArrowDown/>}
                                size={"small"}
                                autoCapitalize={"false"}
                        >
                            <Typography variant={"body2"} component={"p"} className={"txtLocalidad"}
                                        style={{color: blue[500]}}>
                                Envío a Sarmiento 2610, Rosario
                            </Typography>
                        </Button>
                        <Typography variant={"body1"} component={"p"} style={{color: green[400]}}>
                            Gratis
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box component={"div"} className={classes.containerTotal}>
                        <Typography variant={"h6"} component={"p"}>
                            Total
                        </Typography>
                        <MiniMonedaTotal moneda={"u$d"} variant={"h6"} fontSizeDecimal={"14px"}>
                            762342.34
                        </MiniMonedaTotal>
                    </Box>
                    <Button className={classes.btnContinuarCompra} variant="contained" color="primary" disableElevation
                            startIcon={<DoneOutlined/>}>
                        CONTINUAR COMPRA
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
}
