import {normalize, schema, denormalize} from 'normalizr';
import producto from "./normalizeProductos";

const productoOrigenPromocion = new schema.Entity('productosOrigenPromocion', {producto: producto}, {idAttribute: "id"});
export function normalizeDatos(myData) {

    const mySchema = {productosOrigenPromocion: [productoOrigenPromocion]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const mySchema = productoOrigenPromocion;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const mySchema = productoOrigenPromocion;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}
export default productoOrigenPromocion;

