/**
 * Created by joelg on 6/9/17.
 */

//testing agregar en package.json la REACT_APP_ENVI

function BASE_URL() {
    if (process.env.REACT_APP_ENVI === 'develop')
        return "http://localhost:8888/api";

    //Produccion
    if (process.env.NODE_ENV === 'production' && !process.env.REACT_APP_ENVI)
        return "https://jvasrl.com/jvaBE/api";

    //localhost
    if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_ENVI)
        return "http://localhost:8888/api";


}

export default {BASE_URL: BASE_URL()};
