import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { NavHashLink as Link } from 'react-router-hash-link'
//Components
import {
    Avatar,
    Box,
    Collapse,
    Divider, IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SwipeableDrawer, Typography,
    useMediaQuery
} from '@material-ui/core';
//Icons
import StoreIcon from '@material-ui/icons/Store';
import PhoneIcon from '@material-ui/icons/Phone';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MapIcon from '@material-ui/icons/Map';
import HomeIcon from '@material-ui/icons/Home';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import face from "../../../assets/img/informacionContacto/icons/face.png";
import ig from "../../../assets/img/informacionContacto/icons/ig.png";
import { ReactComponent as InstaIcon } from "../../../assets/img/instagram.svg";
import { ReactComponent as FaceIcon } from "../../../assets/img/facebook.svg";

const useStyles = makeStyles((theme) => ({
    navLeft: {
        "& .MuiDrawer-paperAnchorRight": {
            backgroundColor: theme.palette.primary[theme.palette.type],
            color: "white"
        }
    },
    headerCardUsuarioMenu: {
        cursor: "default",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2, 2),
        "& p": {
            lineHeight: "normal",
        },
    },
    list: {
        width: 270,
        "& .menuResponsive a": {
            all: "unset",
            display: "flex",
            alignItems: "center",
        },
        "& .MuiListItemText-root": {
            paddingTop: "4px"
        }
    },
    nested: {
        paddingLeft: theme.spacing(7),
    },
}));

export default function NavLeft(props) {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:975px)');
    const categorias = useSelector(state => state.categorias);
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const scrollWithOffset = (el, yOffset) => {
        props.toggleNavLeft(null, false);
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }
    return (
        <SwipeableDrawer
            open={props.openNavLeft}
            onClose={(e) => props.toggleNavLeft(e, false)}
            onOpen={(e) => props.toggleNavLeft(e, true)}
            swipeAreaWidth={10}
            anchor={"right"}
            className={classes.navLeft}
        >
            <Box component={"div"}
                 className={classes.list}
            >
                <List>
                    {
                        isMobile &&
                        <>
                            <Box component={"div"} className={classes.headerCardUsuarioMenu}>
                                <IconButton
                                    style={{padding: "3px 4px 3px 4px", marginLeft: "-3px", color: "#fff"}}
                                    onClick={(e) => props.toggleNavLeft(e, false)}
                                >
                                    <CloseIcon/>
                                </IconButton>
                                <Box component={"div"} style={{paddingLeft: "8px"}}>
                                    <Typography variant={"h2"} component={"p"} style={{margin: 0, padding: 0}}>
                                        Menú
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider/>
                            <Box component={"div"} className={"menuResponsive"}>
                                <ListItem button>
                                    <Link to={"/#inicio"} smooth
                                          scroll={el => {
                                              scrollWithOffset(el, 0);
                                          }}>
                                        <ListItemIcon>
                                            <HomeIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Inicio"/>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <Link to={"/#quienesSomos"}
                                          smooth
                                          scroll={el => {
                                              scrollWithOffset(el, 0);
                                          }}
                                    >
                                        <ListItemIcon>
                                            <AccountCircleIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="¿Quienes somos?"/>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <Link to={"/promociones"} onClick={() => props.toggleNavLeft(null, false)}>
                                        <ListItemIcon>
                                            <LoyaltyIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Promociones"/>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <Link to={"/#comoComprar"}
                                          smooth
                                          scroll={el => {
                                              scrollWithOffset(el, 0);
                                          }}
                                    >
                                        <ListItemIcon>
                                            <StoreIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="¿Cómo comprar?"/>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <Link to={"/#zonasEntrega"}
                                          smooth
                                          scroll={el => {
                                              scrollWithOffset(el, 0);
                                          }}
                                    >
                                        <ListItemIcon>
                                            <MapIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Zonas de entrega"/>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <Link to={"/#contacto"}
                                          smooth
                                          scroll={el => {
                                              scrollWithOffset(el, 0);
                                          }}
                                    >
                                        <ListItemIcon>
                                            <PhoneIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Contacto"/>
                                    </Link>
                                </ListItem>
                            </Box>
                            <Divider/>
                            <Box component={"div"} alignItems={"center"} justifyContent={"center"} display={"flex"}
                                 padding={"10px 0px"}>
                                <IconButton component={"a"} href="https://www.facebook.com/jvasrl" target={"_blank"}>
                                    <FaceIcon fill={"#fff"}/>
                                </IconButton>
                                <IconButton component={"a"} href="https://www.instagram.com/jvasrl" target={"_blank"}>
                                    <InstaIcon fill={"#fff"}/>
                                </IconButton>
                            </Box>
                        </>
                    }
                </List>
            </Box>
        </SwipeableDrawer>
    );
}
