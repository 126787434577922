import {Box, Button, IconButton, TextField} from "@material-ui/core";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";

function areEqual(prevProps, nextProps) {
    /*
    retorna true si al pasar los nextProps a renderizar retorna
    el mismo resultado que al pasar los prevProps a renderizar,
    de otro modo retorna false
    */
    return true;
}

const useStyles = makeStyles((theme) => ({
    btnAddCartContainer: {
        margin: theme.spacing(1, 0, 0, 0),
    },
    btnAddCart: {
        borderRadius: "8px",
        padding: "5px",
    },
    tfCantidad: {
        width: "100%",
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            padding: "0 7px",
        },
        "& .MuiInputBase-input": {
            textAlign: "center",
            padding: theme.spacing(1, 0),
            height: "1.25em",
        }
    },
}));

export default React.memo(function AddCartProducto(props) {
    const classes = useStyles();
    const {} = props;

    const [cantProducto, setCantProducto] = useState(0);


    return <Box component={"div"} className={classes.btnAddCartContainer}>
        {
            cantProducto < 1 ?
                <Button className={classes.btnAddCart} variant={"outlined"} color={"primary"}
                        fullWidth
                        autoCapitalize={"false"} onClick={() => {
                    setCantProducto(2)
                }}>
                    Agregar al carrito
                </Button> :
                <TextField
                    variant={"outlined"}
                    margin={"none"}
                    size={"small"}
                    className={classes.tfCantidad}
                    value={"123"}
                    InputProps={{
                        startAdornment:
                            <IconButton size={"small"} color={"primary"}>
                                <RemoveCircleRoundedIcon style={{width: "25px", height: "25px"}}/>
                            </IconButton>,
                        endAdornment:
                            <IconButton size={"small"} color={"primary"}>
                                <AddCircleRoundedIcon style={{width: "25px", height: "25px"}}/>
                            </IconButton>
                    }}
                />
        }
    </Box>;
}, areEqual);