import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});

    const mySchema = {marcas: [marca]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});

    const mySchema = marca;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});

    const mySchema = marca;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


